import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Icon from '../../components/Icon';
import Video from '../../components/Video';
import CourseHeader from '../../components/CourseHeader';
import Loading from '../../components/Loading';
import useCourseState from "../../hooks/useCourseState";
import useTopicState from "../../hooks/useTopicState";
import { calendarService } from '../../services/calendarEntryService';

function VideosIndex({loadUser, getResponseData}) {
  const user = loadUser()
  const { courseId, id } = useParams();
  const [course] = useCourseState(user, courseId, getResponseData);
  const [topic] = useTopicState(user, courseId, id, getResponseData);
  const [entries, setEntries] = useState(null)
  const [openEntryId, setOpenEntryId] = useState(null)
  const navigate = useNavigate()
  const loadRecordedClasses = async () => {
    const entriesRes = await calendarService.common.getCalendarEntries(
      user,
      courseId,
      id,
      { maxDate: new Date() }
    )

    const data = getResponseData(
      entriesRes,
      "Erro ao pegar aulas gravadas"
    )

    if (data)
      setEntries(data);
  }

  useEffect(() => { loadRecordedClasses() }, [courseId])

  return (
    <>
      <Header key='student-home-header-key' user={ user } course={ course } subject={topic}/>
      <CourseHeader title="Aulas Gravadas" user={user} course={course} subject={topic}/>,
      <div className='app-body'>
        <h2>
          Aulas Gravadas
          { entries && (topic ? ` da disciplina` : ' do curso') }
        </h2>

        {
          topic && (
            <h3>
              {topic.name}
            </h3>
          )
        }

        {
          entries ? (
            entries.map(entry => (
              <div
                key={ `video-entry-${entry.id}` }
                style={{
                  margin: '1rem 0'
                }}
              >
                <div
                  onClick={() => {
                    if (openEntryId === entry.id)
                      setOpenEntryId(null)
                    else
                      setOpenEntryId(entry.id)
                  }}
                  className='apply-hover apply-box-shadow'
                  style={{
                    padding: '1rem 0.5rem',
                    border: 'solid 1px #CCC',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
                    <div className='light-text small-text'>
                      {
                        entry.startDate ? (
                          new Date(entry.startDate).toLocaleString(
                            "pt-BR",
                            {
                              day: '2-digit',
                              month: '2-digit',
                              year: '2-digit',
                              hour: '2-digit',
                              minute:'2-digit'
                            }
                          )
                        ) : (
                          '--/--/----'
                        )
                      }
                    </div>
                    <b>{entry.name}</b>
                  </div>
                  <div>
                    <Icon style={{fontSize: '2rem'}}
                      name={openEntryId === entry.id ? 'expand_less' : 'expand_more'}
                    />
                  </div>
                </div>
                {
                  openEntryId === entry.id && (
                    <div style={{margin: '1rem 0rem 1rem 0rem'}}>
                      {
                        false ? (
                          <Video title={entry.name} file={entry.file}/>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "lightgray",
                              padding: "0.5rem"
                            }}
                          >
                            <p style={{ cursor: 'pointer' }} className='link' target='_blank' onClick={() => {
                              topic?.id ? navigate(`/cursos/${course.nameId}/topicos/${topic?.id}/aulas-gravadas/${entry.id}`) : navigate(`/cursos/${course.nameId}/aulas-gravadas/${entry.id}`)}}>
                              Clique para acessar o vídeo da aula
                            </p>
                          </div>

                        )
                      }
                    </div>
                  )
                }
              </div>
            ))
          ) : (
            <Loading message='carregando aulas...'/>
          )
        }

        {
          entries && entries.length === 0 && (
            <div className="small-text light-text">
              Nenhuma aula encontrada
            </div>
          )
        }
      </div>
    </>
  );
}

export default VideosIndex;
