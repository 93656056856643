import { useState, useEffect } from "react";
import cookie from 'react-cookies'
import { useNavigate } from "react-router-dom";
import {userService} from "../services/userService";

const tokenKey = 'tsatk'

const useUserState = () => {
  const loadedUser = cookie.load(tokenKey)
  const [user, setUser] = useState(loadedUser || null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate()

  const _saveUser = async (userObj) => {
    await cookie.save(tokenKey, userObj, { path: '/' })
    setUser(userObj)
  }

  const authorize = async () => {
    if (user) {
      const authorizeRes = await userService.common.authorize(user)
      if (authorizeRes.status === 200) {
        if (authorizeRes.data)
          _saveUser(authorizeRes.data)
        else
          alert('empty authorize')

        return
      }
    }

    goToLogin()
  }

  const logout = () => {
    userService.common
      .logout(user)
      .catch(async e => {
        setError(e)
        goToLogin()
      })

    goToLogin()
  }

  const login = async (email, password) => {
    setError(null)
    await cookie.remove(tokenKey, { path: '/' })

    return userService.common
      .login(email, password)
      .then(async (res) => {
        if (res.status === 200) {
          _saveUser(res.data.user)
        } else {
          setUser(null)
          setError(res.status)
        }
      })
      .catch(e => {
        setError(e)
        setUser(null)
      })
  }

  const forgotPassword = async (email) => {
    setError(null)
    setSuccess(null)

    try {
      const res = await userService.common.forgotPassword(email);
      setSuccess(res.status);
      return res;
    } catch (e) {
      setError(e.response.status);
      return e;
    }
  }

  const goToLogin = async () => {
    await cookie.remove(tokenKey, { path: '/' })
    setUser(null)
    navigate('/')
  }

  const updateActiveRole = async (newRole, callback) => {
    try {
      const updatedUser = await userService.admin.updateActiveRole(user, newRole)
      _saveUser(updatedUser)
      if (callback)
        callback(updatedUser)
    } catch (e) {
      setUser(null)
      alert('erro ao atualizar perfil', e)
    }
  }

  return [
    user,
    error,
    success,
    login,
    logout,
    authorize,
    goToLogin,
    forgotPassword,
    updateActiveRole
  ];
};

export default useUserState;
