import { useState, useEffect } from 'react';
import TableForObjects from '../../../components/TableForObjects'
import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
import { configService } from '../../../services/configService'

const AdminConfigsIndex = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const [configs, setConfigs] = useState(null)
  const [savingConfigId, setSavingConfigId] = useState(null)

  useEffect(() => {
    const load = async () => {
      if (user) {
        const configsRes = await configService.admin.index(user)
        const data = getResponseData(configsRes)
        if (data)
          setConfigs(data)
      }
    }

    load()
  }, [])

  const toggle = async (config) => {
    setSavingConfigId(config.id)
    const updateRes = await configService.admin.update(
      user,
      config.id,
      { active: !config.active }
    )

    const data = getResponseData(updateRes)
    if (data) {
      setConfigs(configs.map(cfg => cfg.id === config.id ? data : cfg))
    }

    setSavingConfigId(null)
  }


  return [
    <Header
      key='admin-configs-index-header-key'
      admin="Configurações"
      user={user}
    />,
    user && configs ? (
      <div
        className='app-body'
        key='admin-configs-index-body-key'
      >
        <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem', justifyContent: 'space-between' }}>
          Configurações
        </h2>

        <Card>
          <TableForObjects
            objects={ configs }
            loading={ !(user && configs) }
            columns={ [
              { label: 'Nome', key: 'name' },
              { label: 'Descrição', key: 'description' },
            ] }
            extraColumns={ [
              {
                label: 'Ligar / Desligar',
                style: { borderColor: 'transparent' },
                build: (config) => savingConfigId === config.id ? (
                  <Loading/>
                ) : (
                  <div
                    onClick={() => toggle(config)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      width: '8rem'
                    }}
                  >
                    <Icon
                      style={{ color: config.active ? 'green !important' : 'darkRed !important' }}
                      name={ config.active ? 'done' : 'close' }
                    />
                    <div>
                      { config.active ? 'Ligada' : 'Desligada' }
                    </div>
                  </div>
                )
              },
            ] }
          />
        </Card>
      </div>
    ) : <Loading key="loading-admin-configs" type='elements-with-cards'/>
  ]
}

export default AdminConfigsIndex
