import { useEffect, useState } from "react";
import Card from "./Card";
import Input from "./Input";
import Button from "./Button";
import SelectOptions from "./SelectOptions";
import InputDate from "./InputDate";
import { useNavigate } from "react-router-dom";
import Folders from "./Folders";
import { taskService } from "../services/taskService";
import { topicService } from "../services/topicService";

const CreateTaskCard = ({ loadUser, getResponseData, task, courses }) => {
  const user = loadUser();
  const [topicsByCourse, setTopicsByCourse] = useState(null);
  const navigate = useNavigate();

  const [fileId, setFileId] = useState(null);
  const [calendarName, setCalendarName] = useState(task?.name || null);
  const [calendarDescription, setCalendarDescription] = useState(
    task?.description || null
  );
  const [selectedCourse, setSelectedCourse] = useState(task?.courseId || null);
  const [selectedTopic, setSelectedTopic] = useState(task?.topicId || null);
  const [startDate, setStartDate] = useState(task?.startDate || null);
  const [endDate, setEndDate] = useState(task?.endDate || null);

  const updateTaskCalendar = async () => {
    await taskService.admin.update(
      user,
      calendarName,
      calendarDescription,
      fileId,
      selectedCourse,
      selectedTopic,
      startDate,
      endDate,
      task?.id
    );

    navigate(`/admin/tarefas`);
  };

  const createTaskCalendar = async () => {
    if (
      endDate === null ||
      startDate === null ||
      calendarName === null ||
      calendarDescription === null ||
      selectedTopic === null
    ) {
      alert("preencha todos os campos corretamente");
      return;
    }

    await taskService.admin.create(
      user,
      calendarName,
      calendarDescription,
      fileId,
      selectedCourse,
      selectedTopic,
      startDate,
      endDate
    );

    window.location.reload(false);
  };

  const getTopicsByCourse = async (courseId) => {
    if (courseId) {
      const topicRes = await topicService.admin.getSubjects(
        user,
        courseId
      );
      const topicData = getResponseData(topicRes);

      if (topicData) {
        setTopicsByCourse(topicData);
      }
    }
  };

  useEffect(() => {
    const getTopicsWhenRender = async () => {
      const topicRes = await topicService.admin.getSubjects(
        user,
        selectedCourse
      );
      const topicData = getResponseData(topicRes);

      if (topicData) {
        setTopicsByCourse(topicData);
      }
    };

    if (selectedCourse) {
      getTopicsWhenRender(selectedCourse);
    }
  }, [selectedCourse]);

  return (
    courses &&
    user && (
      <div>
        <Card title={task ? "Editar tarefa" : "Criar Tarefa"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "min-content",
            }}
          >
            <Input
              label="Nome da tarefa"
              onFocus={(event) => event.target.select()}
              autoFocus
              value={calendarName || ""}
              onChange={(event) => setCalendarName(event.target.value)}
            />
            <Input
              label="Descrição da tarefa"
              textarea="Descrição"
              onFocus={(event) => event.target.select()}
              autoFocus
              value={calendarDescription || ""}
              onChange={(event) => setCalendarDescription(event.target.value)}
              style={{
                width: "100%",
                height: "5rem",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <SelectOptions
                label="Selecione o Curso"
                options={courses.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
                selected={selectedCourse}
                onChange={(e) => {
                  setSelectedCourse(e.target.value);
                  getTopicsByCourse(e.target.value);
                }}
                includeBlank="-"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
              <SelectOptions
                label="Selecione a Disciplina"
                options={topicsByCourse?.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
                selected={selectedTopic}
                onChange={(e) => {
                  setSelectedTopic(e.target.value);
                }}
                includeBlank="-"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                disabled={!selectedCourse}
              />
              <InputDate
                selected={startDate ? new Date(startDate) : null}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "start",
                  margin: "0.5rem",
                }}
                label="Data inicial"
                onChange={(startDate) => setStartDate(startDate)}
              />
              <InputDate
                selected={endDate ? new Date(endDate) : null}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "start",
                  margin: "0.5rem",
                }}
                label="Data final"
                onChange={(endDate) => setEndDate(endDate)}
              />
            </div>
            <Folders
              user={user}
              selectOneFile
              selectedOneFile={task?.file || null}
              onSelectFile={(file) => setFileId(file.id)}
              style={{
                margin: "0.5rem",
              }}
            />
            <Button
              title="Criar"
              onClick={() => {
                if (task) {
                  updateTaskCalendar();
                } else {
                  createTaskCalendar();
                }
              }}
              leftIcon="dns"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignitems: "center",
                width: "15rem",
                height: "1rem",
              }}
            >
              {task ? "Editar tarefa" : "Criar tarefa"}
            </Button>
          </div>
        </Card>
      </div>
    )
  );
};

export default CreateTaskCard;
