import { useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Icon from './Icon';
import HeaderMenu from './HeaderMenu';
import AdminHeader from './AdminHeader'
import Loading from './Loading';
import SelectOptions from './SelectOptions';
import HeaderBreadcrumb from './HeaderBreadcrumb';
import LogoSrc from '../assets/logo-tech-school-h.png'
import helpers from '../AppHelpers'
import useUserState from "../hooks/useUserState";

const Header = ({user, course, topic, admin, subject}) => {
  const navigate = useNavigate()
  const techSchoolMenuRef = useRef(null)
  const userMenuRef = useRef(null)

  const [currentUser, setCurrentUser] = useState(user);
  const [updatingUserRole, setUpdatingUserRole] = useState(false);
  const [, , , , logout, , , , updateActiveRole] = useUserState();
  const userInitials = currentUser?.name?.split(' ').slice(0,2).map(part => part[0]).join('')
  return (
    <header>
      <main>
        <div className='group'>
          <div
            className='group'
            style={{ cursor: 'pointer', gap: '0.8rem' }}
            onClick={(e) => {
              if (currentUser && techSchoolMenuRef?.current?.changeMenuVisibility)
                techSchoolMenuRef.current.changeMenuVisibility(true, e)
            }}
          >
            { false && currentUser && (
                <HeaderMenu
                  ref={techSchoolMenuRef}
                  navigate={ navigate }
                  icon='menu'
                  title='Tech School'
                  options={[
                    { path: '/cursos', name: 'Cursos', icon: 'school' },
                  ]}
                />
              )
            }
            <div
              className='apply-flex-column-on-small-devices'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link to='/cursos' style={{}}>
                  <img alt='Logomarca do IDP' src={LogoSrc} style={{ width: '10rem' }}/>
              </Link>

              <div style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.25rem 0.5rem',
                gap: '0.25rem'
              }}>
                <Icon name='chevron-right'/>
                <Link to={helpers.path.student.courses}>
                    <div style={{fontWeight: 'bold'}}>
                      {admin ? "Admin" : "Cursos"}
                    </div>
                </Link>
              </div>
              {
                course && <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.25rem 0.5rem',
                  gap: '0.25rem'
                }}>
                  <Icon name='chevron-right'/>
                  <Link to={helpers.path.student.course(course.nameId)}>
                      <div style={{fontWeight: 'bold'}}>
                        {course.name}
                      </div>
                  </Link>
                </div>
              }

              {
                subject && <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.25rem 0.5rem',
                  gap: '0.25rem'
                }}>
                  <Icon name='chevron-right'/>
                  <Link to={helpers.path.student.topic(course?.nameId, subject.id)}>
                      <div style={{fontWeight: 'bold'}}>
                        {subject.name}
                      </div>
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>

        { currentUser && (
          <div
            className='group'
            style={{ cursor: 'pointer', alignItems: 'center', justifyContent: 'flex-end', gap: '0.8rem' }}
            onClick={(e) => {
              if (currentUser && userMenuRef?.current?.changeMenuVisibility)
                userMenuRef.current.changeMenuVisibility(true, e)
            }}
          >
            <HeaderMenu
              ref={ userMenuRef }
              navigate={ navigate }
              position='right'
              user={ currentUser }
              customIcon={
                <div style={{
                  border: '2px solid rgb(68, 87, 0)',
                  backgroundColor: '#444',
                  width: '2.1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '2.1rem',
                  fontSize: '0.7rem',
                  fontWeight: 'bold',
                  letterSpacing: '0.1rem',
                  borderRadius: '50%',
                  margin: '0.75rem 0',
                  overflow: 'hidden'
                }}>
                  {
                    currentUser?.avatarUrl ? (
                      <img
                        src={ currentUser?.avatarUrl }
                        style={{
                          width: '100%'
                        }}
                      />
                    ) : (
                      userInitials || 'SN'
                    )
                  }
                </div>
              }
              title={
                <div style={{
                  padding: '0.5rem 0',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div>{ currentUser.name }</div>
                  <div style={{
                    fontSize: '0.9em',
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    marginTop: '0.5rem'
                  }}>
                    { currentUser.email }
                  </div>
                  {
                    currentUser.activeRole !== currentUser.grantedRoles && (
                      <div style={{
                        borderTop: 'solid 1px #222',
                        marginTop: '0.5rem',
                      }}>
                        {
                          updatingUserRole ? (
                            <Loading/>
                          ) : (
                            <SelectOptions
                              selected={ currentUser.activeRole }
                              style={{ marginTop: '0.5rem', width: '100%' }}
                              options={ currentUser.grantedRoles.split(',').map(role => ({
                                label: { admin: 'Admin', student: 'Aluno' }[role] || role,
                                value: role
                              }))}
                              onChange={async (event) => {
                                try {
                                  setUpdatingUserRole(true)
                                  updateActiveRole(
                                    event.target.value,
                                    (u) => {
                                      setUpdatingUserRole(false)
                                      setCurrentUser(u)
                                    }
                                  )
                                } catch (err) {
                                  console.log('erro ao atualizar role', err)
                                }
                              }}
                            />
                          )
                        }
                      </div>
                    )
                  }
                </div>
              }
              options={[
                // { path: '/notificacoes', name: 'Notificações', icon: 'notifications' },
                // { path: '/gestao-faltas', name: 'Gestão de faltas', icon: 'event-busy' },
                {
                  path: '/sua-conta',
                  name: 'Sua conta',
                  icon: 'account-circle'
                },
                {
                  path: '/',
                  name: 'Sair',
                  icon: 'logout',
                  action: async () => logout()
                }
              ]}
            />
          </div>
        )}
      </main>

      { admin && <AdminHeader title={admin} user={currentUser}/> }

      {
        course && topic && <HeaderBreadcrumb
          user={currentUser}
          course={course}
          topic={topic}
        />
      }
    </header>
  )
}

export default Header
