import React from 'react';
import { useState } from 'react';
import { useParams } from "react-router-dom";

import Header from '../../components/Header';
import CalendarCard from '../../components/CalendarCard';
import CourseHeader from '../../components/CourseHeader';
import Button from '../../components/Button';
import useCourseState from "../../hooks/useCourseState";
import useTopicState from "../../hooks/useTopicState";

const CourseCalendar = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const {courseId, id} = useParams();
  const [course] = useCourseState(user, courseId, getResponseData);
  const [topic] = useTopicState(user, courseId, id, getResponseData);

  const computeDateCriteria = (date) => ({
    minDate: new Date(date.getFullYear(), date.getMonth(), 1, 0,  0,  0,  0),
    maxDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  })

  const [dateCriteria, setDateCriteria] = useState(computeDateCriteria(new Date()))
  const date = dateCriteria.minDate

  return (
    <>
      <Header key='student-home-header-key' user={user} course={course} />
      <CourseHeader title="Calendário" user={user} course={course}/>
      <div className='app-body'>
        <h2>
          Calendário
          { id ? ` da disciplina` : ' do curso' }
        </h2>

        {
          topic && (
            <h3>
              {topic.name}
            </h3>
          )
        }

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem',
          alignItems: 'center'
        }}>
          <Button
            onClick={ async () => {
              date.setMonth(date.getMonth() - 1)
              setDateCriteria(computeDateCriteria(date))
            }}
            leftIcon='double-arrow-left'
          />
          <h3 className='first-letter-up-case'>
            {
              date.toLocaleString(
                "pt-BR",
                {
                  month: 'long',
                  year: 'numeric',
                }
              )
            }
          </h3>
          <Button
            onClick={ async () => {
              date.setMonth(date.getMonth() + 1)
              setDateCriteria(computeDateCriteria(date))
            }}
            leftIcon='double-arrow-right'
          />
        </div>

        <CalendarCard
          user={user}
          topic={topic}
          course={course}
          getResponseData={getResponseData}
          minDate={dateCriteria.minDate}
          maxDate={dateCriteria.maxDate}
        />
      </div>
    </>
  )
}

export default CourseCalendar;