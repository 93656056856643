import { useState } from 'react';

const Input = ({
  label,
  value,
  onClick,
  onChange,
  onBlur,
  type,
  autoFocus,
  onFocus,
  onEnter,
  onEsc,
  textarea,
  style,
  containerStyle,
  explain
}) => {
  const inputStyle = {
    commomStyle: {
      backgroundColor: 'transparent',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'solid 1px #888',
      padding: '0.5rem 0',
      fontSize: '1.0rem',
      width: '100%'
     },

    stateStyle: {
      idle: {
        color: '#333 !important',
        borderBottomColor: '#888',
      },
      hovered: {
        borderBottomColor: '#000',
        color: '#000 !important',
      },
      focused: {
        borderBottomColor: '#000',
        color: '#000 !important',
      },
      withError: {
        borderBottomColor: 'red',
        color: '#000 !important',
      }
    }
  }

  const [fieldState, setFieldState] = useState('idle')
  const isFocused = fieldState === 'focused'
  const isHovered = fieldState === 'hovered'

  const labelStyle = {
    fontSize: '0.8rem',
    color: (isFocused || isHovered) ? '#000 !important' : '#777 !important',
  }

  return (
    <div
      onClick={ onClick }
      className={ `idp-input ${ fieldState }` }
      style={ containerStyle || {} }
    >
      <div style={ labelStyle}>
        { label }
      </div>
      {
        textarea ? (
          <textarea
            type={ type || 'text' }
            value={ value }
            autoFocus={ autoFocus }
            onChange={ onChange }
            onKeyUp={ (e) => {
              if(e.keyCode === 13 && onEnter && e.ctrlKey) {
                onEnter(e)
              }
              if(e.keyCode === 27 && onEsc)
                onEsc(e)
            }}
            onMouseEnter={ (e) => !isFocused && setFieldState('hovered') }
            onMouseOut={ (e) => isHovered && setFieldState('idle') }
            onFocus={ (e) => {
              setFieldState('focused')
              if (onFocus)
                onFocus(e)
            }}
            onBlur={ (e) => {
              setFieldState('idle');
              if (onBlur)
                onBlur()
            }}
            style={ Object.assign(
              {},
              inputStyle.commomStyle,
              inputStyle.stateStyle[fieldState] || {},
              style || {}
            ) }
          />
        ) : (
          <div>
            <input
              type={ type || 'text' }
              value={ value }
              autoFocus={ autoFocus }
              onChange={ onChange }
              onKeyUp={ (e) => {
                if(e.keyCode === 13 && onEnter)
                  onEnter(e)
                if(e.keyCode === 27 && onEsc)
                  onEsc(e)
              }}
              onMouseEnter={ (e) => !isFocused && setFieldState('hovered') }
              onMouseOut={ (e) => isHovered && setFieldState('idle') }
              onFocus={ (e) => {
                setFieldState('focused')
                if (onFocus)
                  onFocus(e)
              }}
              onBlur={ (e) => {
                setFieldState('idle');
                if (onBlur)
                  onBlur()
              }}
              style={ Object.assign(
                {},
                inputStyle.commomStyle,
                inputStyle.stateStyle[fieldState] || {},
                style || {}
              ) }
            />
          </div>
        )
      }

      <div style={{
        borderTop: 'solid 1px #1857ec',
        width: isFocused ? '100%' : '0',
        transition: 'width 0.5s'
      }}>
      </div>

      {
        explain && (
          <div className='small-text light-text' style={{
            fontWeight: 'normal',
            margin: '0.2rem',
            fontStyle: 'italic'
          }}>
            { explain }
          </div>
        )
      }
    </div>
  )
}

export default Input
