import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TableForObjects from '../../../components/TableForObjects'
import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import { userService } from '../../../services/userService';
import { courseService } from '../../../services/courseService';

const AdminUsersIndex = ({loadUser, getResponseData, goToLogin}) => {
  const currentUser = loadUser()
  const navigate = useNavigate()
  const [courses, setCourses] = useState(null)
  const [savingUserUid, setSavingUserUid] = useState(null)
  const [users, setUsers] = useState(null)

  useEffect(() => {
    const load = async () => {
      const usersRes = await userService.admin.index(currentUser)
      const dataUsers = getResponseData(usersRes)
      if (dataUsers)
        setUsers(dataUsers)

      const coursesRes = await courseService.common.getCourses(currentUser)
      const dataCourses = getResponseData(coursesRes)
      if (dataCourses)
        setCourses(dataCourses)
    }

    if (currentUser)
      load()
    else
      goToLogin()
  }, [])

  const updateUserEnrollment = async (user, courseId, isEnrolled) => {
    const userRes = await userService.admin.updateEnrollment(
      currentUser,
      { userUid: user.firebaseUid, courseId, isEnrolled }
    )
    const data = getResponseData(userRes)
    if (data)
      setUsers(users.map(u => (
        u.firebaseUid === user.firebaseUid
          ? Object.assign({}, user, { enrollments: data })
          : u
      )))
  }


  return [
    <Header
      key='admin-courses-index-header-key'
      admin="Usuários"
      user={currentUser}
    />,
    currentUser && courses ? (
      <div
        className='app-body'
        key='admin-users-index-body-key'
      >
        <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem', justifyContent: 'space-between' }}>
          Usuários
          <Button
            onClick={() => navigate('/admin/cursos/novo')}
            leftIcon='add'
            style={{ display: 'none' }}
          />
        </h2>
        <Card>
          <TableForObjects
            objects={ users }
            loading={ !(currentUser && users) }
            columns={ [] }
            extraColumns={ [
              {
                label: 'Usuário',
                build: (user) => (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <div>
                      { user.name || '-' }
                    </div>
                    <div className='small-text'>
                      { user.email || '-' }
                    </div>
                  </div>
                )
              },
              {
                label: 'Perfis de acesso',
                build: (user) => (
                  <table className='small-table no-border'>
                    <tbody>
                      <tr>
                        <th>ativo</th>
                        <td>{ user.activeRole }</td>
                      </tr>
                      <tr>
                        <th>possíveis</th>
                        <td>{ user.grantedRoles }</td>
                      </tr>
                    </tbody>
                  </table>
                )
              },
              {
                label: 'Matrículas',
                build: (u) => {
                  const enrolledCourseIds = u.enrollments?.map(enrollment => enrollment.courseId) || []

                  return savingUserUid === u.firebaseUid ? (
                    <Loading/>
                  ) : (
                    courses.map(course => {
                      const isEnrolled = (enrolledCourseIds.indexOf(course.id) >= 0)
                      return (
                        <label
                          key={`${course.id}-${u.uid}-enrollment`}
                          style={{ display: 'flex' }}
                        >
                          <input
                            type='checkbox'
                            name='courseIds[]'
                            value={ course.nameId }
                            checked={ isEnrolled }
                            onChange={async (event) => {
                              setSavingUserUid(u.firebaseUid)
                              await updateUserEnrollment(u, course.id, isEnrolled)
                              setSavingUserUid(null)
                            }}
                          />
                          &nbsp;
                          { course.name }
                        </label>
                      )
                    })
                  )
                }
              },
              {
                label: '',
                style: { borderColor: 'transparent' },
                build: (user) => (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={() => navigate(`/admin/usuarios/${ user.firebaseUid }/editar`)}
                      leftIcon='chevron-right'
                    />
                  </div>
                )
              },
            ] }
          />
        </Card>
      </div>
    ) : <Loading key="loading-admin-users" type='elements-with-cards'/>
  ]
}

export default AdminUsersIndex
