import { useState, useEffect, useRef } from 'react';
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import SelectOptions from '../../../components/SelectOptions'

const Questions = ({ questions, assessment, onChange, actions, disabled }) => {
  const letterForIndex = (index) => (
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'][index]
  )

  const totalPoints = (questions?.map(q => q.points)?.reduce((sum, pts) => sum + pts, 0) || 0) / 10;

  return (
    <div>
      <div className='light-text' style={{ margin: '1.5rem 0' }}>
        { questions?.length || 0 }
        &nbsp;
        quest{ questions?.length === 1 ? 'ão' : 'ões' }
        &nbsp;
        encontrada{ questions?.length === 1 ? '' : 's' }
      </div>

      {
        assessment && (
          <div className='light-text' style={{ margin: '1.5rem 0' }}>
            Esta prova vale { totalPoints } ponto{ totalPoints != 1 && 's' }
          </div>
        )
      }

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem'
        }}
      >
        {
          questions?.map((question, i) => (
            <div
              key={`questao-${ question.id }`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <div className='small-text' style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <b>{ question.suggestedTopicName }</b>
                <b>{ question.origin } { question.year }</b>
              </div>
              <div
                key={`questao-${ question.id }`}
                style={{
                  display: 'flex',
                  gap: '1rem',
                  border: 'solid 1px #dadada',
                  padding: '1.5rem 1rem',
                  borderRadius: '0.1rem',
                  backgroundColor: assessment ? '#e4e4fb' : '#f7f7f7'
                }}
              >
                <div>
                  { i + 1 }
                  <span className='light-text small-text'>.</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <div
                    className='topic-content'
                    dangerouslySetInnerHTML={{ __html: question.statement }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1.5rem',
                      marginTop: '1rem'
                    }}
                  >
                    {
                      question.items?.map((item, i) => (
                        <div
                          key={`questao-${ question.id }-item-${ item.id }`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            borderRadius: '0.1rem',
                            position: 'relative'
                          }}
                        >
                          {
                            item.id === question.correctItemId && (
                              <Icon name='done'
                                style={{
                                  color: 'green !important',
                                  position: 'absolute',
                                  left: '-2rem',
                                  fontSize: '1.5rem'
                                }}
                              />
                            )
                          }
                          <div>
                            { letterForIndex(i) }
                            <span className='light-text small-text'>.</span>
                          </div>
                          <div
                            className='topic-content'
                            dangerouslySetInnerHTML={{ __html: item.statement }}
                          />
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  {
                    assessment && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <SelectOptions
                          label='Pontuação'
                          selected={ question.points }
                          options={[
                            { label: '0,5', value: 5 },
                            { label: '1', value: 10 },
                            { label: '1,5', value: 15 },
                            { label: '2', value: 20 },
                            { label: '2,5', value: 25 },
                            { label: '3', value: 30 },
                            { label: '3,5', value: 35 },
                            { label: '4', value: 40 },
                            { label: '4,5', value: 45 },
                            { label: '5', value: 50 },
                          ]}
                          onChange={ (event) => {
                            const updatedQuestion = Object.assign(
                              {},
                              question,
                              { points: parseInt(event.target.value) }
                            );

                            if (onChange)
                              onChange(questions.map(q => (
                                q.id === question.id ? updatedQuestion : q
                              )))
                          }}
                          style={{
                            width: '100%'
                          }}
                        />

                        <Button
                          disabled={ questions.map(q => q.id).indexOf(question.id) <= 0 }
                          leftIcon='expand_less'
                          onClick={ () => {
                            const index = questions.map(q => q.id).indexOf(question.id)

                            const tempQ = questions[index - 1]
                            questions[index - 1] = questions[index]
                            questions[index] = tempQ

                            onChange && onChange(questions)
                          }}
                        />

                        <Button
                          disabled={ questions.map(q => q.id).indexOf(question.id) >= questions.length - 1 }
                          leftIcon='expand_more'
                          onClick={ () => {
                            const index = questions.map(q => q.id).indexOf(question.id)

                            const tempQ = questions[index + 1]
                            questions[index + 1] = questions[index]
                            questions[index] = tempQ

                            onChange && onChange(questions)
                          }}
                        />
                      </div>
                    )
                  }
                  {
                    actions?.map(action => (
                      <Button
                        key={ `question-${ question.id }-action-${action.icon}` }
                        disabled={ action.disabled }
                        leftIcon={ action.icon || 'add' }
                        onClick={() => action.onClick(question) }
                      />
                    ))
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )

}

export default Questions