import { useState, useEffect } from "react";
import Course from "../models/course";
import { courseService } from "../services/courseService";

const useCourseState = (user, courseId, getResponseData) => {
  const [course, setCourse] = useState(null);
  useEffect(() => {
    const loadCourse = async () => {
      const courseRes = await courseService.common.getCourse(user, courseId)
      const data = getResponseData(courseRes)
      if (data)
        setCourse(new Course(courseRes.data))
    }

    if (user && courseId)
      loadCourse()
  }, [courseId])

  return [course]
};

export default useCourseState;
