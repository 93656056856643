import Icon from './Icon';
import React, {useEffect, useCallback } from 'react';

const TaskModal = ({ task, onClose, user }) => {

  const closeOnEscapeKeyDown = useCallback((e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <div
      style={styles.overlay}
      onClick={onClose}
    >
      <div
        style={styles.modal}
        onClick={e => e.stopPropagation()}
        className="scroll_width scroll_track scroll_handle scroll_hover"
      >
        <div
          style={styles.modalHeader}
        >
          <h1
            style={{
              fontWeight: "bolder",
              textDecoration: "underline",
              color: "#707070 !important"
            }}
          >
            {task?.name}
          </h1>
          <Icon
            name="cancel"
            onClick={onClose}
            style={{
              cursor: 'pointer'
            }}
          />
        </div>
        <div
          style={styles.modalContent}
        >
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              backgroundColor: "lightgrey",
              width: "100%",
              height: "100%",
              padding: "1rem",
              overflowY: "scroll"
            }}
          >
            {task?.description}
          </div>
        </div>
        {task.file && <div
          style={styles.fileField}
        >
          <p
            style={{
              color: "black !important",
              margin: "0px"
            }}
          >
            Arquivo:
          </p>
          <Icon name='pdf' style={{ fontSize: '1rem', color: 'red !important' }} />
          <a HREF={task.file.link}>
            {task.file.name}
          </a>
        </div>}
        <div
          style={styles.infoField}
        >
          {/* 1 */}
          <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch', gap: '0.5rem' }}>
            <div
              style={styles.infoBox}
            >
              Status de envio:
            </div>
            <div
              style={styles.descriptionBox}
            >
              {task?.fileId ? (
                <p
                  style={{ color: "green !important" }}
                >
                  Enviado
                </p>
              ) : (
                <p>
                  sem tentativas
                </p>
              )}
            </div>
          </div>

          <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch', gap: '0.5rem' }}>
            <div
              style={styles.infoBox}
            >
              Arquivo enviado:
            </div>
            <div
              style={styles.descriptionBox}
            >
              enviar arquivo
            </div>
          </div>

          {/* 3 */}
          <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch', gap: '0.5rem' }}>
            <div
              style={styles.infoBox}
            >
              Tempo restante:
            </div>
            <div
              style={styles.descriptionBox}
            >
              aqui jaz uma descrição
            </div>
          </div>
        </div>

      </div>

    </div>
  )

}

const styles = {
  overlay: {
    position: 'fixed',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modal: {
    width: '750px',
    height: '450px',
    marginTop: '100px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.15) 2px 2px 3px',
    borderRadius: '5px',
    overflowY: 'auto',
    padding: '0.5rem',
    gap: '0.5rem'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    boxSizing: 'border-box'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 60%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
    height: '60%',
    width: '100%',
    boxSizing: 'border-box',
  },
  fileField: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem',
    gap: '0.5rem',
    height: 'min-content',
    boxSizing: 'border-box',
    width: '100%',
    color: 'blue !important',
    backgroundColor: 'lightgray',
  },
  infoField: { // display none
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
    height: 'min-content',
    width: '100%',
    boxSizing: 'border-box',
    gap: '0.5rem'
  },
  infoBox: {
    display: 'flex',
    flex: '0 0 25%',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '1rem',
    alignItems: 'flex-start',
    padding: '0.5rem',
    background: '#D9D9D9',
    color: '#707070 !important'
  },
  descriptionBox: {
    display: 'flex',
    flex: '1 1 70%',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '1rem',
    alignItems: 'flex-start',
    padding: '0.5rem',
    background: '#D9D9D9',
    color: '#707070 !important'
  },
}

export default TaskModal