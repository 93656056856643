const SelectOptions = ({ label, options, style, selected, onChange, includeBlank, onClick, disabled }) => {
  return (
    <div
      style={{
        margin: '0.25rem'
      }}
    >
      <div className='small-text light-text' style={ label ? { marginBottom: '0.75rem' } : {} }>
        { label }
      </div>
      <select
        className='apply-white-box-shadow' style={ Object.assign({
          borderRadius: '0.1rem',
          border: 'none',
          padding: '0.2rem'
        }, style)}
        value={selected || ''}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      >
        {
          includeBlank && (
            <option value=''>
              { includeBlank }
            </option>
          )
        }
        {
          options?.map(option => (
            <option
              key={ option.value || option }
              value={ option.value || option }
            >
              { option.label || option }
            </option>
          ))
        }
      </select>
    </div>
  )
}

export default SelectOptions
