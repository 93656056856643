import Icon from '../../components/Icon'
import Header from '../../components/Header'

const Error403 = ({ user }) => {
  return [
    <Header
      key='error-403-header-key'
      user={ user }
    />,

    <div
      key='error-403-key'
      className='http-error-wrapper'
    >
      <div className='http-error-description'>
        <div>
          <p>
            Acesso negado.
          </p>
        </div>
      </div>

      <div className='http-error'>
        <Icon
          name={ 'warning' }
          style={{ fontSize: '3rem', color: '#f16600 !important' }}
        />
        403
      </div>
    </div>
  ]
}

export default Error403
