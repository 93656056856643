import { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import useCourseState from "../../hooks/useCourseState";
import useTopicState from "../../hooks/useTopicState";
import CourseHeader from "../../components/CourseHeader";
import gradesService from "../../services/gradesService";
import Table from "react-bootstrap/Table";

const UserScores = ({ loadUser, getResponseData }) => {
  const user = loadUser();

  const { courseId, topicId } = useParams();

  const [course] = useCourseState(user, courseId, getResponseData);
  const [topic] = useTopicState(user, courseId, topicId, getResponseData);

  const [assessment, setAssessment] = useState([]);

  const userUid = user.firebaseUid;

  useEffect(() => {
    const load = async () => {
      if (user) {
        const assessmentRes =
          await gradesService.common.getAllGradesByTopicAndUser(
            user,
            userUid,
            topicId
          );

        const data = getResponseData(assessmentRes);

        if (data) {
          setAssessment(data);
        } else {
          setAssessment([]);
        }
      }
    };

    load();
  }, []);

  const calculateFinalGrade = () => {
    let finalGrade = 0;

    if (assessment) {
      assessment.forEach((assessment) => {
        const {
          grade,
          assessment: { weight },
        } = assessment;
        const partialGrade = grade * weight;
        finalGrade += partialGrade;
      });
    }

    return finalGrade >= 10 ? 10 : finalGrade.toFixed(2);
  };

  return [
    <Header
      key="scores-index-header-key"
      user={user}
      course={course}
      subject={topic}
    />,

    <CourseHeader
      key="scores-index-course-header-key"
      title="Notas"
      user={user}
      course={course}
      subject={topic}
    />,

    user && (
      <div key="scores-index-page-key" className="app-body">
        <div
          className="apply-box-shadow"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "3rem",
            margin: "3rem 0",
            textAlign: "center",
          }}
        >
          {assessment != [] ? (
            assessment.length !== 0 ? (
              <h1 className="mt-0 fs-4">
                Notas das avaliações de {topic ? topic.name : ""}
              </h1>
            ) : (
              <h1 className="p-0 m-0 fs-4">
                Não há nenhuma avaliação cadastrada até o momento
              </h1>
            )
          ) : (
            ""
          )}

          <Table className="table table-bordered hover">
            {assessment
              ? assessment.length > 0 && (
                  <>
                    <thead style={{ backgroundColor: "#333333" }}>
                      <tr>
                        <th className="border-0" style={{ width: "8rem" }}></th>
                        {assessment.map((assessment, index) => (
                          <th
                            className="text-center fs-6 border-0 text-light"
                            key={index}
                          >
                            {assessment.assessment.name}
                          </th>
                        ))}
                        <th
                          scope="col"
                          className="text-center fs-6 border-0 text-light"
                          style={{ width: "12rem" }}
                        >
                          Nota final
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ borderRight: "none" }}>Peso</td>
                        {assessment.map((assessment, index) => (
                          <td className="border-0" key={index}>
                            {assessment.assessment.weight * 100 !==
                            Math.round(assessment.assessment.weight * 100)
                              ? (assessment.assessment.weight * 100).toFixed(
                                  2
                                ) + "%"
                              : (assessment.assessment.weight * 100).toFixed(
                                  0
                                ) + "%"}
                          </td>
                        ))}
                        <td rowSpan={2} className="fs-5 align-middle">
                          {calculateFinalGrade() !== 0
                            ? calculateFinalGrade()
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: "none" }}>Nota</td>
                        {assessment.map((assessment, index) => (
                          <td className="border-0" key={index}>
                            {assessment.grade !== null ? assessment.grade : "-"}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </>
                )
              : ""}
          </Table>
        </div>
      </div>
    ),
  ];
};

export default UserScores;
