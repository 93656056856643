import { useState, useEffect, useRef } from 'react';
import Input from '../../../components/Input';
import InputDate from '../../../components/InputDate';
import SelectOptions from '../../../components/SelectOptions';
import TableForObjects from '../../../components/TableForObjects';
import Button from '../../../components/Button'
import { useNavigate } from "react-router-dom";
import Card from '../../../components/Card'
import Questions from './Questions'
import { questionService } from '../../../services/questionService';
import Loading from '../../../components/Loading'
import Icon from '../../../components/Icon'
import { userService } from '../../../services/userService';

const CreateOrUpdate = ({ user, assessment, courses, getResponseData, onFinish, loading }) => {
  const navigate = useNavigate()

  const [questions, setQuestions] = useState(null)
  const [questionsSearch, setQuestionsSearch] = useState('')

  const [students, setStudents] = useState(null)
  const [studentsSearch, setStudentsSearch] = useState('')

  const [assessmentAttrs, setAssessmentAttrs] = useState(assessment || {})

  useEffect(() => {
    if (courses) {
      const load = async () => user && fetchQuestions()
      load()
    }
  }, [courses])

  useEffect(() => {
    if (assessment) {
      setAssessmentAttrs(assessment)
    }
  }, [assessment])

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchQuestions(), 1000);
    return () => clearTimeout(timeOutId);
  }, [questionsSearch])

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchStudents(), 1000);
    return () => clearTimeout(timeOutId);
  }, [studentsSearch])

  const isUpdate = !!assessmentAttrs?.id
  const isCreate = !isUpdate

  const fetchQuestions = async () => {
    const questionsRes = await questionService.admin.questions.index(
      user,
      { search: questionsSearch || null }
    )
    const data = getResponseData(questionsRes)
    if (data)
      setQuestions(data)
  }

  const fetchStudents = async () => {
    const studentsRes = await userService.admin.index(
      user,
      {
        role: 'student',
        search: studentsSearch || null
      }
    )
    const data = getResponseData(studentsRes)
    if (data)
      setStudents(data)
  }

  const renderProperInput = (column) => {
    if (column.type === 'text')
      return (
        <Input
          label={ column.label }
          onEnter={ (event) => {
            setAssessmentAttrs(
              (prevValue) => {
                prevValue[column.key] = event.target.value
                return Object.assign({}, prevValue)
              }
            )
          }}
          onFocus={ (event) => event.target.select() }
          autoFocus
          value={ assessmentAttrs[column.key] || '' }
          onChange={ (event) => {
            setAssessmentAttrs(
              (prevValue) => {
                prevValue[column.key] = event.target.value
                return Object.assign({}, prevValue)
              }
            )
          }}
          containerStyle={{
            width: '13rem'
          }}
        />
      )

    if (column.type === 'date')
      return (
        <InputDate
          label={ column.label }
          selected={
            assessmentAttrs[column.key] && new Date(assessmentAttrs[column.key]) || null
          }
          onChange={ (date) => {
            setAssessmentAttrs(
              (prevValue) => {
                prevValue[column.key] = date
                return Object.assign({}, prevValue)
              }
            )
          }}
          style={{
            width: '12rem',
            marginRight: '1rem'
          }}
        />
      )

    if (column.type === 'select')
      return (
        <SelectOptions
          label={ column.label }
          selected={
            assessmentAttrs[column.key] || null
          }
          includeBlank={ column.includeBlank || false }
          options={ column.options }
          onChange={ (event) => {
            setAssessmentAttrs(
              (prevValue) => {
                prevValue[column.key] = event.target.value
                return Object.assign({}, prevValue)
              }
            )
          }}
          style={{
            width: '13rem'
          }}
        />
      )
  }

  const selectedCourse = courses?.find(c => (
    assessmentAttrs.courseId && c.id === parseInt(assessmentAttrs.courseId)
  )) || null

  const includedQuestionIds = assessmentAttrs?.questions?.map(q => q.id) || []

  const options = {
    courses: courses?.map(c => (
      { value: c.id, label: c.name }
    )) || [],
    subjects: selectedCourse?.subjects?.map(sub => (
      { value: sub.id, label: sub.name }
    )) || []
  }

  const columns = [
    [
      {
        label: 'Nome da Avaliação',
        key: 'name',
        type: 'text'
      }
    ],
    [
      {
        label: 'Curso',
        key: 'courseId',
        type: 'select',
        includeBlank: 'Selecione...',
        options: options.courses
      },{
        label: 'Disciplina',
        key: 'topicId',
        type: 'select',
        includeBlank: 'Selecione...',
        options: options.subjects
      }
    ],
    [
      {
        label: 'Tempo de resolução',
        key: 'durationInMinutes',
        type: 'select',
        includeBlank: 'Selecione...',
        options: [
          { label: '15min', value: 15 },
          { label: '30min', value: 30 },
          { label: '45min', value: 45 },
          { label: '1h',    value: 60 },
          { label: '1h30',  value: 90 },
          { label: '2h',    value: 120 },
          { label: '2h30',  value: 150 },
          { label: '3h',    value: 180 },
          { label: '3h30',  value: 210 },
          { label: '4h',    value: 240 },
          { label: '4h30',  value: 270 },
          { label: '5h',    value: 300 },        ]
      },{
        label: 'Tentativas permitidas',
        key: 'allowedTries',
        type: 'select',
        includeBlank: 'Selecione...',
        options: [1, 2, 3, 4, 5]
      }
    ],
    [
      {
        label: 'Início',
        key: 'startDate',
        type: 'date'
      },{
        label: 'Fim',
        key: 'endDate',
        type: 'date'
      },{
        label: 'Data de publicação',
        key: 'publishedAt',
        type: 'date'
      }
    ],
  ]

  assessmentAttrs.questions = assessmentAttrs.questions?.map(question => Object.assign(
    { points: 10 },
    question,
  )) || []

  return (
    <div>
      <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
        <Button
          leftIcon={ 'chevron-left' }
          onClick={() => navigate(-1) }
        />
        { isUpdate ? 'Atualizar ' : 'Criar nova ' }
        avaliação
      </h2>

      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
            Dados básicos
          </div>
        }
      >
        <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}>
          {
            columns.map((columnOrColumns, i) => (
              <div key={ `column-or-columns-${ i }` } style={{ display: 'flex', gap: '1rem' }}>
                {
                  Array.isArray(columnOrColumns) ? (
                    columnOrColumns.map(column => (
                      <div
                        key={ `${ column.label }-${ column.key }` }
                        style={ {} }
                      >
                        { renderProperInput(column) }
                      </div>
                    ))
                  ) : (
                    <div
                      key={ `${ columnOrColumns.label }-${ columnOrColumns.key }` }
                      style={ {} }
                    >
                      { renderProperInput(columnOrColumns) }
                    </div>
                  )
                }
              </div>
            ))
          }
        </div>
      </Card>

      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
            Questões da prova
          </div>
        }
      >
        <Questions
          questions={ assessmentAttrs.questions }
          assessment
          onChange={ questions__ => {
            setAssessmentAttrs(prevValue => Object.assign(
              {},
              prevValue,
              { questions: questions__ }
            ))
          }}
          actions={[
            {
              icon: 'remove',
              onClick: (question) => {
                setAssessmentAttrs(prevValue => {
                  return Object.assign(
                    {},
                    prevValue,
                    { questions: prevValue.questions.filter(q => q.id != question.id) }
                  )
                })
              }
            },
          ]}
        />
      </Card>

      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
            Incluir novas questões na prova
          </div>
        }
      >
        <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column', alignItems: 'stretch' }}>
          <Input
            label='Pesquise por enunciado e/ou items das questões'
            onEnter={ (event) => setQuestionsSearch(event.target.value) }
            onFocus={ (event) => event.target.select() }
            value={ questionsSearch }
            onChange={ (event) => setQuestionsSearch(event.target.value) }
          />
        </div>

        <Questions
          questions={ questions?.filter(q => includedQuestionIds.indexOf(q.id) < 0) }
          actions={[
            {
              icon: 'add',
              onClick: (question) => {
                if (includedQuestionIds.indexOf(question.id) >= 0)
                  return

                setAssessmentAttrs((prevValue) => {
                  const questionsMap = Object.fromEntries(
                    (prevValue.questions || []).concat([question]).map(q => [q.id, q])
                  )
                  return Object.assign(
                    {},
                    prevValue,
                    { questions: Object.values(questionsMap) }
                  )
                })
              }
            }
          ]}
        />
      </Card>

      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
            Alunos que farão a prova
          </div>
        }
      >
        <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column', alignItems: 'stretch' }}>
          <Input
            label='Pesquise por nome, email ou matrícula do aluno'
            onEnter={ (event) => setStudentsSearch(event.target.value) }
            onFocus={ (event) => event.target.select() }
            value={ studentsSearch }
            onChange={ (event) => setStudentsSearch(event.target.value) }
          />
        </div>

        <TableForObjects
          objects={ students }
          loading={ !(user && students) }
          columns={ [
            { key: 'ra', label: 'RA' },
            { key: 'name', label: 'Nome' },
            { key: 'email', label: 'Email' },
          ] }
          extraColumns={ [
          ] }
        />
      </Card>

      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          marginTop: '1.5rem',
          justifyContent: 'space-between'
        }}
      >
        <div
          onClick={(e) => {
            onFinish(
              Object.assign(
                {},
                assessmentAttrs,
                {
                  questions: null,
                  questionIds: assessmentAttrs?.questions?.map(q => q.id),
                  questionPointsMap: Object.fromEntries(
                    assessmentAttrs?.questions?.map(q => [q.id, q.points]) || []
                  )
                },
              )
            )
          }}
          className='apply-box-shadow'
          style={{
            padding: '0.5rem',
            cursor: 'pointer',
            color: 'white ',
            backgroundColor: 'darkGreen',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div className='small-text'>
            { isUpdate ? 'Atualizar' : 'Criar nova' } avaliação
          </div>
          {
            loading ? <Loading/> : <Icon name='done'/>
          }
        </div>

        <div
          onClick={(e) => {
            navigate('/admin/avaliacoes')
          }}
          className='apply-box-shadow'
          style={{
            padding: '0.5rem',
            cursor: 'pointer',
            color: 'white ',
            backgroundColor: 'darkRed',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div className='small-text'>
            cancelar
          </div>
          <Icon name='close'/>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate;
