import { HOST, baseApiService, pathWithQueryString } from "./baseApiService"

const topicService = {
  host: HOST,
  paths: {
    topicsPath: (courseId) => (
      `/courses/${courseId}/topics`
    ),
    topicPath: (courseId, topicId) => {
      return `/courses/${courseId}/topics/${topicId}`
    },
    topicProgressPath: (courseId, topicId) => (
      `/courses/${courseId}/topics/${topicId}/progress`
    ),
    toggleTopicProgressPath: (courseId, topicId) => (
      `/courses/${courseId}/topics/${topicId}/progress/toggle`
    ),
    topicSiblingsPath: (courseId, topicId) => (
      `/courses/${courseId}/topics/${topicId}/siblings`
    ),
    topicAncestorsPath: (courseId, topicId) => (
      `/courses/${courseId}/topics/${topicId}/ancestors`
    ),
    topicChildrenPath: (courseId, topicId) => (
      `/courses/${courseId}/topics/${topicId}/children`
    ),
    admin: {
      topicsPath: (params) => (
        pathWithQueryString('/admin/topics', params)
      ),
      topicPath: (topicId) => (
        `/admin/topics/${topicId}`
      ),
      updateTopicPath: (topicId) => (
        `/admin/topics/${topicId}/update`
      ),
      updateTopicSubjectStatusPath: (topicId) => (
        `/admin/topics/${topicId}/update-subject-status`
      ),
      createTopicPath: (topicId) => (
        `/admin/topics${topicId ? `/${ topicId }` : ''}/create`
      ),
      subjectPath: (courseId) => (
        `/admin/courses/${courseId}/subjects`
      ),
    }
  },

  common: {
    getTopics: async (user, courseId) => {
      const path = topicService.paths.topicsPath(courseId)
      return await baseApiService.get(user, path)
    },
  
    getTopic: async (user, courseId, topicId) => {
      const path = topicService.paths.topicPath(courseId, topicId)
      return await baseApiService.get(user, path)
    },
  
    getTopicProgress: async (user, courseId, topicId) => {
      const path = topicService.paths.topicProgressPath(courseId, topicId)
      return await baseApiService.get(user, path)
    },
  
    toggleTopicProgress: async (user, courseId, topicId) => {
      const path = topicService.paths.toggleTopicProgressPath(courseId, topicId)
      return await baseApiService.patch(user, path)
    },
  
    getTopicSiblings: async (user, courseId, topicId) => {
      const path = topicService.paths.topicSiblingsPath(courseId, topicId)
      return await baseApiService.get(user, path)
    },
  
    getTopicAncestors: async (user, courseId, topicId) => {
      const path = topicService.paths.topicAncestorsPath(courseId, topicId)
      return await baseApiService.get(user, path)
    },
  
    getTopicChildren: async (user, courseId, topicId) => {
      const path = topicService.paths.topicChildrenPath(courseId, topicId)
      return await baseApiService.get(user, path)
    },
  },

  admin: {
    index: async (user, { parentId, courseId }) => {
      return await baseApiService.get(
        user,
        topicService.paths.admin.topicsPath(),
        { parentId, courseId }
      )
    },
    show: async (user, { topicId, courseId }) => {
      return await baseApiService.get(
        user,
        topicService.paths.admin.topicPath(topicId),
      )
    },
    update: async (user, topicId, data) => {
      return await baseApiService.patch(
        user,
        topicService.paths.admin.updateTopicPath(topicId),
        data
      )
    },
    updateSubjectStatus: async (user, { topicId, isSubject }) => {
      return await baseApiService.patch(
        user,
        topicService.paths.admin.updateTopicSubjectStatusPath(topicId),
        { isSubject }
      )
    },
    create: async (user, topicId, data) => {
      return await baseApiService.post(
        user,
        topicService.paths.admin.createTopicPath(topicId),
        data
      )
    },
    delete: async (user, topicId) => {
      return await baseApiService.delete(
        user,
        topicService.paths.admin.topicPath(topicId),
      )
    },
    getSubjects: async (user, courseId) => {
      const path = topicService.paths.admin.subjectPath(courseId)
      return await baseApiService.get(user, path)
    },
  },
}
export {
  topicService
}