import helpers from '../AppHelpers';

class Course {
  constructor ({ id, name, number, nameId, type, modules, description }) {
    this.dataValues = {
      id,
      name,
      number,
      nameId,
      type,
      modules,
      description
    }

    Object
      .keys(this.dataValues)
      .forEach(key => this[key] = this.dataValues[key])
  }

  label () {
    switch(this.type) {
      case 'semesters': return 'Módulo'
      case 'modules': return 'Módulo'
      default: return 'Módulo'
    }
  }

  secondLevelTopicLabel () {
    switch(this.type) {
      case 'with-no-progress': return 'Disciplina'
      case 'default': return 'Tópico'
      default: return 'Tópico'
    }
  }

  path () {
    return helpers.path.student.course(this.nameId)
  }

  config () {
    return {
      useProgress: false // this.type !== 'with-no-progress'
    }
  }

  isModulesType () { return this.type === 'modules' }
  isSemestersType () { return this.type === 'semesters' }
}

export default Course
