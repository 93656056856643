import { useState } from 'react';
import Icon from './Icon';
import Input from './Input';
import SelectLinks from './SelectLinks';
import { fileService } from '../services/fileService'

const Folder = ({
  folder,
  onClick,
  user,
  startEditingName,
  onDelete,
  onNameChange,
  selectOneFile
}) => {
  const [editingName, setEditingName] = useState(!!startEditingName || false);
  const [setDeletingFolder] = useState(false);
  const [newName, setNewName] = useState(folder.name);

  const updateFolder = async () => {
    try {
      await fileService.renameFolder(user, folder.id, newName)
      onNameChange(newName)
    } catch (e) {
      alert('Erro ao atualizar pasta', e.message)
    }
  }

  const deleteFolder = async () => {
    try {
      await fileService.deleteFolder(user, folder.id)
    } catch (e) {
      alert('Erro ao deletar pasta', e.message)
    }
  }

  const renderRightOptions = () => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className=''
        style={{position: 'relative', display: 'flex', gap: '0.5rem'}}
      >
        {
          editingName && [
            <div
              key={`save-folder-${folder.id}`}
              onClick={(e) => {
                e.stopPropagation()
                setEditingName(false)
                updateFolder()
              }}
              className='apply-box-shadow'
              style={{
                padding: '0.25rem 0.5rem',
                cursor: 'pointer',
                color: 'white',
                backgroundColor: 'darkGreen',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Icon name='done'/>
            </div>,

            <div
              key={`cancel-folder-saving-${folder.id}`}
              onClick={(e) => {
                e.stopPropagation()
                setEditingName(false)
              }}
              className='apply-box-shadow'
              style={{
                padding: '0.25rem 0.5rem',
                cursor: 'pointer',
                color: 'white ',
                backgroundColor: 'darkRed',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Icon name='close'/>
            </div>
          ]
        }

        {
          !selectOneFile && (
            <SelectLinks
              conditionToOpen={ () => !editingName }
              links={[
                {
                  name: 'editar',
                  icon: { name: 'edit' },
                  onClick: (e) => { setEditingName(true) }
                },
                {
                  name: 'apagar',
                  icon: { name: 'close', color: 'darkRed' },
                  onClick: async (e) => {
                    setDeletingFolder(true)
                    await deleteFolder()
                    onDelete()
                    setDeletingFolder(false)
                  }
                }
              ]}
            />
          )
        }
      </div>
    )
  }

  return (
    <div
      onClick={ () => {
if (onClick && !editingName)
          onClick()
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.5rem',
        padding: '0.75rem',
        border: 'solid 1px #EEE',
        backgroundColor: '#f9f9f9'
      }}
      className='apply-hover'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Icon name='folder' style={{ color: '#AAA' }}/>
        {
          editingName ? (
            <Input
              onEnter={() => {
                setEditingName(false)
                updateFolder()
              }}
              onEsc={() => setEditingName(false)}
              onFocus={(event) => event.target.select()}
              autoFocus
              value={ newName }
              onChange={ event => setNewName(event.target.value) }
            />
          ) : (
            newName
          )
        }
      </div>

      { renderRightOptions() }
    </div>
  )
}

export default Folder
