import { useEffect, useState, React } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from '../../components/Header'
import CourseHeader from '../../components/CourseHeader'
import Icon from '../../components/Icon'
import Loading from '../../components/Loading'
import Progress from '../../components/Progress'
import useCourseState from "../../hooks/useCourseState";
import helpers from '../../AppHelpers';
import { topicService } from '../../services/topicService';
import CourseCard from "../../components/CourseCard";
import { Carousel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';


const reduceRecipes = (acc, cur, index) => {
  var altura = window.innerHeight;
  var largura = window.innerWidth;
  if (altura <= 444 || largura <= 851) {
    const groupIndex = Math.floor(index / 1);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(cur);
    return acc;

  } else {
    const groupIndex = Math.floor(index / 3);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(cur);
    return acc;
  }
};

const CoursesShow = ({ loadUser, getResponseData, goToLogin }) => {
  const user = loadUser()

  const { courseId } = useParams()
  const navigate = useNavigate()
  const [course] = useCourseState(user, courseId, getResponseData)
  const [rootTopics, setRootTopics] = useState(null)


  useEffect(() => {
    const load = async () => {
      const rootTopicsRes = await topicService.common.getTopics(user, courseId)
      const data = getResponseData(rootTopicsRes)
      if (data)
        setRootTopics(data.sort((m1, m2) => m1.number > m2.number))
    }
    if (user)
      load()
    else
      goToLogin()


    function handleResize() {
        document.location.reload(true)

    }

    window.addEventListener('resize', handleResize)
  }, [])

  const renderLinkCard = ({ icon, title, path, lead, extraContent }) => {
    return (
      <div class='row' style={{
        display: 'wrap',
        justifyContent: 'center',

      }}>
        <Link
          to={path}
          style={{
            flex: 1,
            flexBasis: '22%',
          }}
          key={`course#${path}`}
        >
          <CourseCard
            className='apply-hover'
            noBorder
            style={{
              flex: 1,
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <Icon name={icon || 'school'} style={{ fontSize: '8rem', marginBottom: '2rem' }} />
              <div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {title}
                  </div>
                </div>

                <div style={{ marginTop: '2.5rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <div className='small-text italic-text '>
                    {lead}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    {extraContent || null}
                  </div>
                </div>
              </div>
            </div>

          </CourseCard>
        </Link>

      </div>
    )
  }

  return [
    <Header key='student-home-header-key' user={user} course={course} />,
    <CourseHeader title="Conteúdo" user={user} course={course} />,
    course ? <div key='student-home-page-key' className='app-body'>
      <article style={{}}>
        <div>
          {course.description}
        </div>

        <div className="bio" style={{ display: 'none' }}>
          <div className="line-left"></div>
          <p className='cord-name' style={{
            fontSize: '1.5rem',
            fontWeight: '500',
            margin: '10px 8px'
          }}
          >
            Coordernador(a)
          </p>
          <div className="line-right"></div>
        </div>

        <h2>Conteúdo</h2>
        <div className="carousel-container  justify-content-center" id='myCarousel'>
          <Carousel interval={null} >

            {
              !rootTopics ? <Loading message="carregando Módulos e Disciplinas..." /> : (
                rootTopics.sort((m1, m2) => m1.number > m2.number)
                  .reduce(reduceRecipes, [])
                  .map((rootTopic, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <div className=' d-flex justify-content-center card-course-row'>
                          {rootTopic.map((rootTopic, index) => {
                            return (
                              <CourseCard
                                key={`rootTopic#${rootTopic.id}`}
                                className='apply-hover '
                                bodyClassName='apply-flex-column-on-small-devices'
                                noBorder
                                style={{
                                }}
                                bodyStyle={{
                                  padding: '2rem',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  gap: '1rem',
                                  overflow: 'hidden',
                                  height: '450px',
                                  alignContent: 'center',
                                }}
                              >
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignContent: 'center',
                                  paddingLeft: '10px',

                                }}>
                                  <Link
                                    to={helpers.path.student.topic(course.nameId, rootTopic.id)}
                                  >

                                    <div style={{ fontSize: '1rem' }}>
                                      {course.label()} {rootTopic.number}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        marginTop: '0.25rem',
                                        paddingLeft: '5px',
                                      }}
                                    >
                                      {rootTopic.name}
                                    </div>
                                  </Link>
                                </div>

                                <div className='card-course-buttons' style={{
                                     flexWrap: 'wrap',
                                     gap: '0.5rem',
   
                                     flex: 1
                                }}>
                                  {
                                    rootTopic.children.map(child => (
                                      <div className="card-course-buttons-show">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.5rem",
                                            paddingLeft: '10px',
                                          }}
                                        >
                                          <button type="button" class="custom-btn btn-5"
                                          >
                                            <Link
                                              to={helpers.path.student.topic(course.nameId, child.id)}
                                            >
                                              {child.name}
                                            </Link>
                                          </button>
                                        </div>

                                      </div>
                                    ))
                                  }
                                </div>


                                {
                                  course.config().useProgress && (
                                    <div style={{
                                      margin: '1rem 0',
                                      padding: '2rem 0 1rem 0'
                                    }}>
                                      <Progress
                                        type='circle'
                                        todo={rootTopic?.progress?.todo}
                                        done={rootTopic?.progress?.done}
                                      />
                                    </div>
                                  )
                                }

                              </CourseCard>

                            )
                          })}
                        </div>

                      </Carousel.Item>



                    )

                  })
              )
            }
          </Carousel>
        </div>
      </article>

      {
        course && course.type === 'with-no-progress' && (
          <div>
            <article style={{ padding: '1rem 1rem' }}>
              <h2>Material do curso</h2>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
                {
                  [
                    {
                      title: 'Aulas Gravadas',
                      path: helpers.path.student.recordedClasses(course.nameId),
                      icon: 'recording',
                      lead: 'Todas as aulas anteriores'
                    },
                    {
                      title: 'Arquivos de aula',
                      path: helpers.path.student.supportMaterial(course.nameId),
                      icon: 'folder',
                      lead: 'Todos os arquivos do curso'
                    },
                    {
                      title: 'Calendário',
                      path: helpers.path.student.calendar(course.nameId),
                      icon: 'calendar',
                      lead: 'Calendário oficial do curso'
                    },
                  ].map((options => renderLinkCard(options)))
                }
              </div>
            </article>

            <article style={{ padding: '1rem 1rem' }}>
              <h2>Seu espaço</h2>
              <div className='course-additional-cards' style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                width: 'auto',
                marginBottom: '3rem' 
              }}>
                {
                  [
                    {
                      title: 'Notas',
                      path: helpers.path.student.scores(course.nameId),
                      icon: 'timeline',
                      lead: 'Veja suas notas neste curso'
                    },
                    {
                      title: 'Avaliações',
                      path: helpers.path.student.assessments(course.nameId),
                      icon: 'school',
                      lead: 'Veja suas avaliãções neste curso'
                    },
                  ].map((options => renderLinkCard(options)))
                }
              </div>
            </article>
          </div>
        )
      }
    </div> : <Loading key='loading-course' type='elements-with-cards' />
  ]
}

export default CoursesShow;
