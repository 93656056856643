import { useState, useEffect } from 'react';

const Progress = ({ todo, done }) => {
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    setPercent(todo ? parseInt(100 * done / todo) : 0)
  }, [todo, done])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.2rem',
        width: '100%'
      }}
    >
      <div style={{
        backgroundColor: '#CCC',
        padding: '2px',
        borderRadius: '8px',
        width: '4.5rem',
      }}>
        <div style={{
          height: '0.6rem',
          borderRadius: '6px',
          backgroundColor: '#1976d2',
          width: `${ percent }%`,
          transition: 'width 0.5s',
        }}>
        </div>
      </div>

      <div
        style={{
          fontSize: '0.7rem',
          fontStyle: 'italic',
          color: '#777 !important'
        }}
      >
        { percent }%
        &nbsp;&nbsp;&nbsp;
        { done } de { todo }
      </div>
    </div>
  )
}

export default Progress
