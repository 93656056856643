import { useState, useEffect } from "react";
import TableForObjects from "../../../components/TableForObjects";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Input";
import { courseCategoryService } from "../../../services/courseCategoryService";

const AdminCourseCategory = ({ loadUser, getResponseData }) => {
  const user = loadUser();
  const [categories, setCategories] = useState(null);
  const navigate = useNavigate();

  const [categoryName, setCategoryName] = useState(null);
  const [categoryDescription, setCategoryDescription] = useState(null);
  const [categoryDisplayPositon, setCategoryDisplayPosition] = useState(null);

  useEffect(() => {
    const load = async () => {
      if (user) {
        const categoriesRes = await courseCategoryService.admin.index(
          user
        );
        const categoriesDatavalues = getResponseData(categoriesRes);
        if (categoriesDatavalues) {
          setCategories(categoriesDatavalues);
        }
      }
    };

    load();
  }, []);

  const createCourseCategory = async () => {
    if (
      categoryName === null ||
      categoryDisplayPositon === null
    ) {
      alert("preencha todos os campos corretamente");
      return;
    }

    await courseCategoryService.admin.create(
      user,
      categoryName,
      categoryDescription,
      categoryDisplayPositon
    );

    window.location.reload(false);
  }

  const deleteCategory = async (courseCategoryId) => {

    await courseCategoryService.admin.delete(
      user,
      courseCategoryId
    )

    window.location.reload(false);
  }

  return [
    <Header
      key="admin-course-category-index-header-key"
      admin="Categorias de Curso"
      user={user}
    />,
    user ? (
      <div
        key="admin-tasks-index-body-key"
        style={{
          width: "100%",
          maxWidth: 1224,
          margin: "1rem 0.5rem 0.5rem 0.5rem",
        }}
      >
        <Card title="Categorias">
          <TableForObjects
            objects={categories}
            columns={[{ key: "name", label: "Nome" }]}
            extraColumns={[
              {
                label: "Descrição",
                build: (category) => {
                  return category?.description;
                },
              },
              {
                label: "posição de exibição",
                build: (category) => {
                  return category?.displayPosition;
                },
              },
              {
                label: "ações",
                build: (category) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        title="Editar"
                        onClick={() => {
                          navigate(`/admin/categorias-de-curso/editar/${category.id}`, {state: category});
                        }}
                        leftIcon="edit"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignitems: "center",
                          width: "5rem",
                          height: "1rem",
                          margin: "0.25rem",
                        }}
                      >
                        Editar
                      </Button>
                      <Button
                        title="Excluir"
                        onClick={() => {
                          deleteCategory(category.id)
                        }}
                        leftIcon="delete"
                        color={{ color: "red" }}
                        style={{
                          color: "red !important" ,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignitems: "center",
                          width: "5rem",
                          height: "1rem",
                          margin: "0.25rem",
                        }}
                      >
                        Excluir
                      </Button>
                    </div>
                  );
                },
              },
            ]}
          />
        </Card>
        <Card title={"Criar categoria de curso"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "min-content",
            }}
          >
            <div
               style={{
                display: "flex",
                flexDirection: "row",
                gap: "2rem"
              }}
            >
              <Input
                label="Nome da categoria"
                onFocus={(event) => event.target.select()}
                value={categoryName || ""}
                onChange={(event) => setCategoryName(event.target.value)}
                containerStyle={{
                  width: "50%"
                }}
              />
              <Input
                type="number"
                label="posição de exibição"
                onFocus={(event) => event.target.select()}
                onChange={(event) => setCategoryDisplayPosition(event.target.value)}
                value={categoryDisplayPositon || ""}
              >
              </Input>
            </div>
            <Input
              label="Descrição da categoria"
              textarea="Descrição"
              onFocus={(event) => event.target.select()}
              value={categoryDescription || ""}
              onChange={(event) => setCategoryDescription(event.target.value)}
              style={{
                width: "100%",
                height: "5rem",
                backgroundColor: "#e4e4e4"
              }}
            />
            <Button
              title="Criar"
              onClick={() => {
                createCourseCategory()
              }}
              leftIcon="dns"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignitems: "center",
                width: "15rem",
                height: "1rem",
                marginTop: "0.5rem"
              }}
            >
              Criar Categoria de curso
            </Button>
          </div>
        </Card>
      </div>
    ) : (
      <Loading
        key="loading-admin-course-categories"
        type="elements-with-cards"
      />
    ),
  ];
};

export default AdminCourseCategory;
