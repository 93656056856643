import { useState, useEffect } from 'react';
import Input from './Input';

const EditableText = ({label, value, onChange, textarea}) => {
  const [currentValue, setCurrentValue] = useState(value || null)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])


  return editing ? (
    <div>
      <Input
        label={label}
        textarea={textarea}
        onEnter={() => {
          setEditing(false)
          onChange(currentValue)
        }}
        onEsc={() => {
          setCurrentValue(value)
          setEditing(false)
        }}
        onFocus={(event) => event.target.select()}
        autoFocus
        value={currentValue || ''}
        onChange={ event => setCurrentValue(event.target.value) }
      />
    </div>
  ) : (
    <div>
      <div className='small-text' style={{padding: '0.5rem 0'}}>
        {label}
      </div>
      <div
        className='apply-box-shadow apply-hover'
        style={{padding: '0.5rem'}}
        onDoubleClick={() => setEditing(true)}
      >
        {
          !currentValue ? '-' : currentValue.trim().split("\n").map((part, i) => (
            part === "\n" ? <br key={`chave-${label}-${i}`}/> : <div>{part}</div> 
          )) || '-'
        }
      </div>
    </div>
  )
}

export default EditableText
