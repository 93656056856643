import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../../components/Header'
import CreateOrUpdate from './CreateOrUpdate'
import { userService } from '../../../services/userService';
import { courseService } from '../../../services/courseService';

const AdminUsersEdit = ({ loadUser, goToLogin, getResponseData }) => {
  const currentUser = loadUser()
  const navigate = useNavigate()
  const { userUid } = useParams()
  const [user, setUser] = useState(null)
  const [courses, setCourses] = useState(null)

  useEffect(() => {
    const load = async () => {
      const userRes = await userService.admin.show(currentUser, userUid)
      const dataUser = getResponseData(userRes)
      if (dataUser)
        setUser(dataUser)

      const coursesRes = await courseService.common.getCourses(currentUser)
      const dataCourses = getResponseData(coursesRes)
      if (dataCourses)
        setCourses(dataCourses)
    }
    if (currentUser)
      load()

  }, [])

  const updateUser = async (userAttrs) => {
    // setSavingUser(true)
    const createRes = await userService.admin.update(currentUser, userUid, userAttrs)
    const data = getResponseData(createRes)
    // setSavingUser(false)
  }

  return [
    <Header
      key='admin-users-index-header-key'
      admin="Usuários"
      user={currentUser}
    />,
    currentUser && (
      <div
        className='app-body'
        key='admin-users-index-body-key'
      >
        <CreateOrUpdate
          currentUser={ currentUser }
          user={ user }
          courses={ courses }
          onFinish={ userAttrs => {
            updateUser(userAttrs)
            navigate('/admin/usuarios')
          }}
          getResponseData={ getResponseData }
        />
      </div>
    )
  ]
}

export default AdminUsersEdit
