import { useState } from 'react';
import { Link } from "react-router-dom";
import Icon from './Icon'
import helpers from '../AppHelpers'

const CourseHeader = ({ user, course, subject, title }) => {
  const [showMenu, setShowMenu] = useState(false)
  
  const toggleMenu = async (e, value) => {
    e.stopPropagation()
    setShowMenu(value)
  }

  if (!course)
    return null

  const options = [
    {
      name: "Conteúdo",
      path: subject ? (
        helpers.path.student.topic(course.nameId, subject.id)
      ) : (
        helpers.path.student.course(course.nameId)
      )
    }
  ]

  if (course.type === 'with-no-progress') {
    options.push({
      name: "Aulas Gravadas",
      path: helpers.path.student.recordedClasses(course.nameId, subject?.id)
    })

    options.push({
      name: "Material de Apoio",
      path: helpers.path.student.supportMaterial(course.nameId, subject?.id)
    })

    options.push({
      name: "Avaliações",
      path: helpers.path.student.assessments(course.nameId)
    })

    if (!subject)
      options.push({
        name: "Calendário",
        path: helpers.path.student.calendar(course.nameId)
      })

    options.push({
      name: "Notas",
      path: helpers.path.student.scores(course.nameId, subject?.id)
    })

    // if (subject?.name === 'Metodologia e Fluxo de Trabalho com Dados')
    //   options.push({
    //     name: "Avaliações",
    //     path: helpers.path.student.assessments(course.nameId, subject.id)
    //   })
  }

  return (
    <header style={{
      display: 'flex',
      flex: 1,
      backgroundColor: '#333',
      position: 'sticky',
      zIndex: 99,
      minHeight: '3.5rem'
    }}>
      <div 
        onClick={ (e) => toggleMenu(e, !showMenu) }
        onMouseOver={ (e) => toggleMenu(e, true) }
        onMouseOut={ (e) => toggleMenu(e, false) }
        style={{
          backgroundColor: '#333',
          color: 'white !important',
          display: 'flex',
          fontSize: '0.85rem',
          alignItems: 'center',
          minHeight: '3.25rem',
          cursor: 'pointer',
          position: 'relative'
        }}
      >
        <div
          key='mobile-icon-children'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            padding: `0.15rem ${ showMenu ? '8rem' : '0.5rem' } 0.15rem 0.5rem`,
          }}
        >
          <Icon
            name={ showMenu ? 'menu-open' : 'menu-open-right' }
          />
          <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
            <span style={{color: '#bdf100 !important'}}>{title}</span>
          </div>
        </div>
        {
          showMenu && (
            <div
              style={{
                backgroundColor: '#333',
                color: 'white !important',
                position: 'absolute',
                top: '3rem',
                left: '0rem',
                zIndex: 99,
                minWidth: '20rem',
                width: '20rem',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem 0.5rem',
                overflowY: 'auto',
                maxHeight: '78vh'
              }}
            >
              {
                options.map(option => (
                  <Link
                    key={`menu-option-${option.name}`}
                    to={ option.path }
                    className='link-breadcrumb-item'
                    style={{
                      display: 'flex',
                      gap: '2rem',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0.5rem',
                      margin: '0.5rem 0'
                    }}
                  >
                    <div style={option.name !== title ? {} : {
                      color: '#bdf100 !important'
                    }}>
                      {option.name}
                    </div>
                    <Icon name="arrow-forward" style={{color: '#999 !important'}}/>
                  </Link>
                ))
              }
            </div>
          )
        }
      </div>
    </header>
  )

}

export default CourseHeader
