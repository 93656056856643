import Loading from './Loading'
import { parseISO, format } from 'date-fns';

const TableForObjects = ({ objects, columns, extraColumns, style, loading }) => {
  let objNumber = 1

  let extraColumnNumber = 1

  const extractValue = (obj, key) => {
    const result = obj[key]
    if (result) {
      let pattern = /^\d{4}\/\d{2}\/\d{2}$/;
      let pattern2 = /^\d{4}-\d{2}-\d{2}$/;
      let pattern3 = /^\d{4} \d{2} \d{2}$/;
      if(pattern.test(result) || pattern2.test(result)|| pattern3.test(result)){
        const timestamp = Date.parse(result)
        if (!!timestamp)
          return new Date(timestamp).toLocaleString(
            "pt-BR",
            {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            }
          )
      }
      else{
        return result
      }
    } 
  }

  return (
    <table style={Object.assign(
      { maxWidth: '100%' },
      style || {}
    )}>
      <thead>
        <tr>
          <th>#</th>
          {
            columns?.map(column => (
              <th key={column.key}>{column.label}</th>
            ))
          }
          {
            extraColumns?.map(extraColumn => (
              <th
                key={`th-extra-column-${extraColumnNumber++}`}
                style={extraColumn.style || {}}
              >
                {extraColumn.label}
              </th>
            ))
          }
        </tr>
      </thead>

      <tbody>
        {
          loading ? (
            <tr>
              <th colSpan={1 + (columns?.length || 0) + (extraColumns?.length || 0)}>
                <Loading
                  message='carregando...'
                  style={{ textAlign: 'center' }}
                />
              </th>
            </tr>
          ) : (
            objects?.length === 0 ? (
              <tr>
                <th colSpan={1 + (columns?.length || 0) + (extraColumns?.length || 0)}>
                  0 registros encontrados.
                </th>
              </tr>
            ) : (
              objects?.map(obj => {
                const objId = obj.id || obj.uid || obj.firebaseUid
                return (
                  <tr
                    key={`${new Date().toISOString()}${objId}-table-row`}
                    className='apply-hover'
                  >
                    <th>{objNumber++}</th>
                    {
                      columns?.map(column => (
                        <td key={`${objId}-${column.key}`}>{
                          extractValue(obj, column.key) || '-'
                        }</td>
                      ))
                    }
                    {
                      extraColumns?.map(extraColumn => (
                        <td
                          key={`${objId}-extra-column-${extraColumnNumber++}`}
                          style={extraColumn.style || {}}
                        >
                          {extraColumn.build(obj) || '-'}
                        </td>
                      ))
                    }
                  </tr>
                )
              })
            )
          )
        }
      </tbody>
    </table>
  )
}

export default TableForObjects
