import Icon from '../../components/Icon'
import Header from '../../components/Header'
import Error403 from './403';
import { useLocation } from "react-router-dom";

const Error404 = ({user, httpCode}) => {
  const location = useLocation();

  if (location.pathname === '/acesso-negado')
    return <Error403 user={user}/>

  return [
    <Header
      key='error-404-header-key'
      user={ user }
    />,

    <div
      key='error-404-key'
      className='http-error-wrapper'
    >
      <div className='http-error-description'>
        <div>
          <p>
            Página não encontrada
          </p>
          <p style={{ fontSize: '0.9em' }}>
            Confira o endereço no seu navegador
          </p>
        </div>
      </div>

      <div className='http-error'>
        <Icon
          name={ 'warning' }
          style={{ fontSize: '3rem', color: '#f16600 !important' }}
        />
        404
      </div>
    </div>
  ]
}

export default Error404
