import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from '../../components/Header'
import CourseHeader from '../../components/CourseHeader';
import Card from '../../components/Card'
import Button from '../../components/Button'
import useCourseState from "../../hooks/useCourseState";
import useTopicState from "../../hooks/useTopicState";
import AppHelpers from '../../AppHelpers'
import { assessmentService } from "../../services/assessmentService";

const UserAssessments = ({loadUser, getResponseData}) => {
  const { courseId, topicId } = useParams();
  const navigate = useNavigate();
  const user = loadUser()
  const [course] = useCourseState(user, courseId, getResponseData);
  const [topic] = useTopicState(user, courseId, topicId, getResponseData);
  const [assessments, setAssessments] = useState(null);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);

  useEffect(() => {
    // const load = async () => {
    //   if (user) {
    //     const assessmentsRes = await assessmentService.common.getAssessments(user, courseId)
    //     const data = getResponseData(assessmentsRes)
    //     if (data) {
    //       setAssessments(data)
    //       if (topicId)
    //         setSelectedSubjectIds([topicId])
    //       else
    //         setSelectedSubjectIds(data?.map(ass => ass.topic?.id).filter(subId => subId))
    //     } else {
    //       setAssessments([])
    //     }
    //   }
    // }

    // load()
  }, [])

  const isAssessmentAvailable = (ass) => {
    const now = new Date()
    const start = new Date(ass.startDate)
    const end = new Date(ass.endDate)
    return (now > start && now < end)
  }

  const isAssessmentFinished = (ass) => {
    const allowedTries = ass.allowedTries || 2
    return ass.attempts.filter(att => att.endDate).length >= allowedTries
  }

  const subjects = assessments?.map(ass => ass.topic).filter(sub => sub) || []
  const assessmentsToShow = assessments?.filter(ass => (
    ass.topic && selectedSubjectIds.indexOf(ass.topic.id) >= 0
  )) || []

  return [
    <Header key='assessments-index-header-key' user={ user } course={ course } subject={topic}/>,
    <CourseHeader key='assessments-index-course-header-key' title="Avaliações" user={user} course={course} subject={topic}/>,
    user && (
      <div key='assessment-attempt-key' className='app-body'>
        <h2>Avaliações</h2>

        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1.5rem', flexWrap: 'wrap' }}>
          {
            subjects.map(sub => (
              <div
                key={ `subject-filter-${ sub.id }` }
                className='small-text'
                onClick={ () => {
                  if (selectedSubjectIds.indexOf(sub.id) >= 0)
                    setSelectedSubjectIds(selectedSubjectIds.filter(subId => sub.id != subId))
                  else
                    setSelectedSubjectIds([sub.id].concat(selectedSubjectIds))
                }}
                style={{
                  backgroundColor: selectedSubjectIds.indexOf(sub.id) >= 0 ? 'rgb(38, 126, 186)' : 'white',
                  display: 'inline-block',
                  padding: '0.5rem',
                  cursor: 'pointer',
                  color: selectedSubjectIds.indexOf(sub.id) >= 0 ? 'white !important' : '#333 !important',
                  border: 'solid 1px #ccc'
                }}
              >
                { sub.name }
              </div>
            ))
          }
        </div>

        {
          assessmentsToShow.length === 0 && (
            <div>
              Nenhuma avaliação <b>{ topicId ? 'nesta disciplina' : 'neste curso' }</b> para mostrar.
            </div>
          )
        }

        <div style={{ display: 'flex', gap: '2rem', alignItems: 'flex-start', flexDirection: 'column' }}>
          {
            assessmentsToShow.map(assessment => {
              const qPlural = assessment?.questions?.length === 1 ? 'ão' : 'ões'
              const attemptNumber = (assessment.attempts?.length || 0) + 1
              const tries = [...Array(assessment.allowedTries).keys()]
              const finishedTries = assessment?.attempts?.filter(att => att.endDate).length || 0
              const noMoreAttempts = finishedTries >= tries.length
              const bestScore = Math.max.apply(Math, assessment.attempts?.map(att => att.score) || []) || null
              let scoreToShow = '-'
              if (bestScore && isAssessmentFinished(assessment) || bestScore === assessment.maxScore)
                scoreToShow = bestScore / 10

              return (
                <Card
                  key={ `assessment-${ assessment.id }` }
                  style={{ width: '100%' }}
                  hideContent
                  title={
                    <h4 style={{
                      marginTop: 0,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      position: 'relative',
                      padding: '0 1rem',
                      width: 'calc(100% - 3rem)'
                    }}>
                        <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                          <div>
                            { assessment.name }
                          </div>
                          <div style={{
                            border: 'solid 1px lightblue',
                            display: 'flex',
                            alignItems: 'flex-end',
                            padding: '0.5rem',
                            gap: '0.5rem'
                          }}>
                            <div>
                              { attemptNumber - 1 } de { tries.length }
                            </div>
                            <div className='small-text'>
                              tentativas realizadas
                            </div>
                          </div>

                          {
                            isAssessmentFinished(assessment) || bestScore === assessment.maxScore ? (
                              <div style={{ color: 'darkRed !important', fontWeight: 'bold' }}>
                                Avaliação já realizada
                              </div>
                            ) : (
                              isAssessmentAvailable(assessment) ? (
                                <Button
                                  onClick={ (e) => {
                                    e.stopPropagation()
                                    navigate(`/cursos/${ courseId }/avaliacoes/${ assessment.id }`)
                                  }}
                                  leftIcon='edit'
                                >
                                  { assessment?.attempts.some(att => !att.endDate) ? 'Continuar' : 'Fazer' }
                                  &nbsp; a avaliação
                                  { assessment?.attempts.some(att => att.endDate) && ' novamente' }
                                </Button>
                              ) : (
                                <Button
                                  leftIcon='close'
                                  disabled
                                >
                                  Avaliação não disponível
                                </Button>
                              )
                            )
                          }
                        </div>
                        <div>
                          <div style={{ marginTop: '1rem' }} className='small-text light-text'>
                            Disponível entre
                          </div>
                          <div>
                            <b>{ AppHelpers.date.format(assessment.startDate) }</b>
                            &nbsp;&nbsp;e&nbsp;&nbsp;
                            <b>{ AppHelpers.date.format(assessment.endDate) }</b>
                          </div>

                          <div style={{ marginTop: '1rem' }} className='small-text light-text'>
                            Disciplina
                          </div>
                          <div>
                            { assessment.topic?.name || '' }
                          </div>
                        </div>
                      <div>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: '0.5rem',
                          backgroundColor: '#eaeaea',
                          border: 'solid 1px #ccc',
                          padding: '1rem',
                          marginBottom: '0.5rem',
                          borderRadius: '0.2rem'
                        }}>
                          <div className='small-text light-text'>
                            Sua nota
                          </div>

                          <div style={{ fontSize: '2rem' }}>
                            { scoreToShow }
                          </div>

                          <div className='small-text light-text' style={{
                            textAlign: 'center',
                            fontWeight: 'normal',
                            borderTop: 'solid 1px #fafafa',
                            paddingTop: '0.5rem'
                          }}>
                            { assessment.maxScore / 10 } pontos possíveis
                          </div>
                        </div>
                      </div>
                    </h4>
                  }
                >
                  <div>

                    <div style={{ display: 'flex', gap: '2.5rem', }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.15rem',
                          marginTop: '0.15rem',
                          fontWeight: 'normal'
                        }}
                      >
                        <div style={{ marginTop: '1rem' }} className='light-text'>
                          Curso
                        </div>
                        <div>
                          { assessment.course?.name || '' }
                        </div>

                        <div style={{ marginTop: '1rem' }} className='light-text'>
                          Disciplina
                        </div>
                        <div>
                          { assessment.topic?.name || '' }
                        </div>

                        <div style={{ marginTop: '1rem' }} className='light-text'>
                          Tempo de resolução
                        </div>
                        <div>
                          { assessment.durationInMinutes } minutos
                        </div>

                        <div style={{ marginTop: '1rem' }} className='light-text'>
                          Quantidade de questões
                        </div>
                        <div>
                          { assessment.questions.length } quest{ qPlural }
                        </div>
                      </div>

                      <table style={{ flex: 1 }}>
                        <tbody>
                          <tr>
                            <th style={{ textAlign: 'center', border: 'none', width: '2rem' }}>
                              Tentativas
                            </th>
                            <th style={{ textAlign: 'center', border: 'none' }}>
                              Iniciada em
                            </th>
                            <th style={{ textAlign: 'center', border: 'none' }}>
                              Finalizada em
                            </th>
                            <th style={{ textAlign: 'center', border: 'none' }}>
                              Você acertou
                            </th>
                          </tr>

                          {
                            tries.map(tryNumber => {
                              const attempt = assessment.attempts && assessment.attempts[tryNumber]
                              const emptyCell = (
                                <div style={{ padding: '0.5rem', color: 'transparent !important' }}>
                                  empty
                                </div>
                              )

                              return (
                                <tr key={ `assessment-${ assessment?.id }-attempt-${ attempt?.id || tryNumber }` }>
                                  <th style={{ textAlign: 'center' }}>
                                    { tryNumber + 1 }
                                  </th>
                                  <td style={{ textAlign: 'center' }}>{ AppHelpers.date.format(attempt?.startDate) || emptyCell }</td>
                                  <td style={{ textAlign: 'center' }}>{ AppHelpers.date.format(attempt?.endDate) || emptyCell }</td>
                                  <td style={{ textAlign: 'center' }}>
                                    {
                                      attempt?.endDate && (
                                        <div>
                                          <div>
                                            { attempt?.numberOfCorrectAnswers }
                                            &nbsp;de { assessment.questions.length } quest{ qPlural }
                                          </div>
                                          <div style={{ display: 'none' }}>
                                            nota { attempt.score / 10 }
                                          </div>
                                        </div>
                                      )
                                    }
                                  </td>
                                  {
                                    isAssessmentFinished(assessment) && (
                                      <td style={{ border: 'none' }}>
                                        <Button
                                          onClick={ () => navigate(`${ assessment.id }/tentativas/${ attempt.id }`) }
                                          leftIcon='chevron-right'
                                          style={{ padding: '0.5rem' }}
                                        />
                                      </td>
                                    )
                                  }
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card>
              )
            })
          }
        </div>
      </div>
    )
  ]
}

export default UserAssessments
