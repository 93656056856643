import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import CreateTaskCard from '../../../components/CreateTaskCard'
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { courseService } from '../../../services/courseService';
import { taskService } from '../../../services/taskService';

const AdminEditTask = ({ loadUser, getResponseData }) => {
  const user = loadUser()
  const { taskId } = useParams()

  const [task, setTask] = useState(null)
  const [courses, setCourses] = useState(null)

  useEffect(() => {
    const load = async () => {
      if (user) {
        const coursesRes = await courseService.common.getCourses(user)
        const coursesDatavalues = getResponseData(coursesRes)
        if (coursesDatavalues) {
          setCourses(coursesDatavalues)
        }
      }
    }

    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      if (user) {
        const taskRes = await taskService.admin.find(user, taskId)
        const taskDatavalues = getResponseData(taskRes)
        if (taskDatavalues) {
          setTask(taskDatavalues)
        }
      }
    }

    load()
  }, [])

  return [
    <Header
      key='admin-tasks-edit-index-header-key'
      admin="Tarefas"
      user={user}
    />,
    user && task && courses? (
      <div
        key='admin-tasks-index-body-key'
        style={{ width: '100%', maxWidth: 1224, margin: '1rem 0.5rem 0.5rem 0.5rem' }}
      >
        <CreateTaskCard
          loadUser={loadUser}
          getResponseData={getResponseData}
          task={task}
          courses={courses}
        />
      </div>
    ) : (<Loading key="loading-admin-tasks" type='elements-with-cards' />)
  ]
}

export default AdminEditTask
