import { useState } from 'react';
import { Link } from "react-router-dom";
import Icon from './Icon'

const AdminHeader = ({ user, title }) => {
  const [showMenu, setShowMenu] = useState(false)
  
  const toggleMenu = async (e, value) => {
    e.stopPropagation()
    setShowMenu(value)
  }

  const adminOptions = [
    {name: "Cursos", path: "/admin/cursos"},
    {name: "Categorias de Curso", path: "/admin/categorias-de-curso"},
    {name: "Usuários", path: "/admin/usuarios"},
    {name: "Arquivos", path: "/admin/arquivos"},
    {name: "Calendário", path: "/admin/calendario"},
    {name: "Avaliações", path: "/admin/avaliacoes"},
    {name: "Tarefas", path: "/admin/tarefas"},
    {name: "Configurações", path: "/admin/configuracoes"}
  ]

  return (
    <div>
      <div
        onClick={ (e) => toggleMenu(e, !showMenu) }
        onMouseOver={ (e) => toggleMenu(e, true) }
        onMouseOut={ (e) => toggleMenu(e, false) }
        style={{
          backgroundColor: '#333',
          color: 'white !important',
          display: 'flex',
          fontSize: '0.85rem',
          alignItems: 'center',
          minHeight: '3.25rem',
          cursor: 'pointer',
          position: 'relative'
        }}
      >
        <div
          key='mobile-icon-children'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            padding: `0.15rem ${ showMenu ? '8rem' : '0.5rem' } 0.15rem 0.5rem`,
          }}
        >
          <Icon
            name={ showMenu ? 'menu-open' : 'menu-open-right' }
          />
          <div>
            {title}
          </div>
        </div>
        {
          showMenu && (
            <div
              style={{
                backgroundColor: '#333',
                color: 'white !important',
                position: 'absolute',
                top: '0rem',
                left: '0rem',
                zIndex: 99,
                minWidth: '20rem',
                width: '20rem',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem 0.5rem',
                overflowY: 'auto',
                maxHeight: '78vh'
              }}
            >
              {
                adminOptions.map(option => (
                  <Link
                    key={`menu-option-${option.name}`}
                    to={ option.path }
                    className='link-breadcrumb-item'
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      padding: '0.5rem',
                      margin: '0.5rem 0'
                    }}
                  >
                    <div>
                      {option.name}
                    </div>
                    <Icon name="arrow-forward"/>
                  </Link>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  )

}

export default AdminHeader
