import { useState, useEffect, React, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import TableForObjects from '../../../components/TableForObjects'
import Input from '../../../components/Input'
import Icon from '../../../components/Icon'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import { Table, Dropdown, Form, ModalBody } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { topicService } from '../../../services/topicService';
import { assessmentService } from '../../../services/assessmentService';
import gradesService from '../../../services/gradesService';
import { courseService } from '../../../services/courseService';
import { userService } from '../../../services/userService';

import { Assessment, ContactMailOutlined, Topic } from '@mui/icons-material';


const CourseAssessments = ({ user, getResponseData, course }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true)
  const [parent, setParent] = useState(null)
  const [savingContent, setSavingContent] = useState(null)
  const [topics, setTopics] = useState(null)
  const [assessment, setAssessment] = useState(null)
  const [assessmentName, setAssessmentName] = useState('')
  const [weightAssessment, setWeightAssessment] = useState('')
  const [topicId, setTopicId] = useState('')
  const [courseId, setCourseId] = useState('')
  const [usersIds, setUsersIds] = useState([])
  const [disciplinaName, setDisciplinaName] = useState('Disciplina')
  const [disciplinaId, setDisciplinaId] = useState('')
  const [tableBodyAssessment, setTableBodyAssessment] = useState(null)
  const [currentAssessmentName, setCurrentAssessmentName] = useState('')
  const [currentAssessmentId, setCurrentAssessmentId] = useState('')
  const [currentAssessmentWeight, setcurrentAssessmentWeight] = useState('')
  const [idAssessmentCadastrarNota, setIdAssessmentCadastrarNota] = useState('')
  const [notaValue, setNotaValue] = useState('')
  const [weightValue, setWeightValue] = useState('')

  const elementRef = useRef([]);
  const weightRef = useRef([]);
  const nameRef = useRef([]);

  const [showSpinner, setShowSpinner] = useState(true)

  const [usersDataModal, setUsersDataModal] = useState([])
  const [userUuid, setUserUuid] = useState({})
  const [assessmentsNameModal, setAssessmentsNameModal] = useState([])

  const [visualizarModal, visualizarNotasModal] = useState(false)
  const [cadastrarModal, cadastrarAssessmentModal] = useState(false)
  const [cadastrarNotaModal, cadastrarNotaAssessmentModal] = useState(false)
  const [editarNotaModal, editarNotaAssessmentModal] = useState(false)
  const [deletarModal, deletarAssessmentModal] = useState(false)

  const hideVisualizarNotasModal = () => visualizarNotasModal(false)
  const hideCadastrarAssessmentModal = () => cadastrarAssessmentModal(false)
  const hideCadastrarNotaModal = () => cadastrarNotaAssessmentModal(false)
  const hideEditarNotaModal = () => editarNotaAssessmentModal(false)
  const hideDeletarModal = () => deletarAssessmentModal(false)
  useEffect(() => {
    const load = async () => {
      if (user) {
        setLoading(true)

        const courseId = course?.id || null


        const topicRes = await topicService.admin.getSubjects(
          user,
          courseId
        );
        const topicData = getResponseData(topicRes);

        if (topicData) {
          setTopics(topicData)
          const courseRes = await courseService.admin.show(user, courseId)
          const courseData = getResponseData(courseRes)
          setUsersIds([])
          courseData.enrollments.forEach(element => {
            if(element.active === true){
              usersIds.push(element.userUid)
            }
          });
          
          loadUsersAssessments()
        }



        setLoading(false)

      }

    }

    load()
  }, [location])


  const loadUsersAssessments = async () => {
    const users = await userService.admin.showIn(user, usersIds)
    const usersData = getResponseData(users, "Erro ao buscar Usuários")

    if (usersData) {
      const usersDataSort = usersData.sort((a,b) => a.name - b.name)
      setUsersDataModal(usersDataSort)
    }
  }

  const createAssessment = async () => {
    try {
      if (!assessmentName || assessmentName.length === 0)
        return
      const createRes = await assessmentService.admin.create(
        user,
        { courseId: course.id, topicId: topicId, childrenId: disciplinaId, weight: parseFloat(weightAssessment), name: assessmentName }
      )
      const newAssessment = getResponseData(createRes, "Erro ao criar Avaliação")
      setSavingContent(false)

      if (newAssessment) {
        setAssessment([newAssessment].concat(assessment || []))
        setAssessmentName('')
      }
      hideCadastrarAssessmentModal()
      loadAssessments(disciplinaName, topicId, courseId)
    } catch (e) {
      console.log(e)
      alert('Erro ao criar Avaliação', e.message)
    }
  }

  const loadAssessments = async (name = '', id = '', courseId = '') => {
    const topicBody = await assessmentService.admin.index(user, { topicId: id })
    const topicsData = getResponseData(topicBody)

    if (topicsData) {
      setAssessmentsNameModal(topicsData)
      if (name !== '') {
        setDisciplinaName(name)
        setTopicId(id)
        setCourseId(courseId)
        gradeSearchUsersByTopic(courseId)
      }
      weightValueTable(topicsData)
      setParent(null)
      setTableBodyAssessment(topicsData)
      setShowSpinner(false)
    }
  }

  const deletarAvaliacao = async () => {
    const request = await assessmentService.admin.deleteAssessment(user, { assessmentId: currentAssessmentId })
    const response = getResponseData(request)
    if (response) {
      loadAssessments(disciplinaName, topicId, courseId)
      hideDeletarModal()
    }
  }

  const modalDelete = async (id, name) => {
    setCurrentAssessmentId(id)
    setCurrentAssessmentName(name)
    deletarAssessmentModal(true)
  }

  const modalCadastrarNotas = async (name) => {
    setCurrentAssessmentName(name)
    cadastrarNotaAssessmentModal(true)
  }

  const saveNotasModal = async () => {
    try {
      if (!idAssessmentCadastrarNota || idAssessmentCadastrarNota.length === 0)
        return
      let requestBody = [{ assessmentId: idAssessmentCadastrarNota }]
      usersDataModal.map(({ name, firebaseUid }) => {
        let userGrade = { "userUuid": firebaseUid, "grade": parseFloat(elementRef.current[firebaseUid].value) }
        requestBody.push(userGrade)
      })
      const createGrades = await gradesService.admin.createGradesInBatch(
        user,
        requestBody
      )
      const newGrades = getResponseData(createGrades, "Erro ao salvar notas")
      setSavingContent(false)
      if (newGrades) {
        loadAssessments(disciplinaName, topicId, courseId)
      }
      hideCadastrarNotaModal()
    } catch (e) {
      console.log(e)
      alert('Erro ao salvar notas', e.message)
    }
  }
  const modalEditAvaliacao = async (id, name, weight) => {
    setCurrentAssessmentName(name)
    setCurrentAssessmentId(id)
    setcurrentAssessmentWeight(weight)
    editarNotaAssessmentModal(true)
  }

  const renderEditmodal = () => {
    return <div
      style={{
        display: "flex",
        height: "min-content",
      }}
      class='row mt-2'>
      <div class='col-6 '>
        <Form.Control
          type="text"
          placeholder={currentAssessmentName}
          defaultValue={currentAssessmentName}
          onChange={e => nameRef.current.value = e.target.value}
        />
      </div>
      <div class='col-6 '>
        <Form.Control
          type="text"
          placeholder={currentAssessmentWeight}
          defaultValue={currentAssessmentWeight}
          onChange={w => weightRef.current.value = w.target.value}
        />
      </div>
    </div>
  }

  const renderAssessments = () => {
    if (tableBodyAssessment && tableBodyAssessment.length > 0) {
      let n = 0
      return tableBodyAssessment.map(({ id, name, weight }) => {
        n++
        return <tr>
          <td >{n}</td>
          <td >{name}</td>
          <td >{weight}</td>
          <td >
            <div class='row' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', margin: '0' }}>
              <div class='col-4' >
                <Button
                  title='Adicionar Notas'
                  style={{ width: '15%' }}
                  onClick={async (e) => {
                    modalCadastrarNotas(name)
                    setNotaValue('')
                    setIdAssessmentCadastrarNota(id)
                  }}
                  leftIcon={'add'}
                />
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={cadastrarNotaModal}
                  onHide={hideCadastrarNotaModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Adicionar notas</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h3>
                      {currentAssessmentName}
                    </h3>
                    <hr></hr>
                    {renderUsers()}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={hideCadastrarNotaModal}>
                      Fechar
                    </Button>
                    <Button variant="primary" onClick={async (e) => {
                      saveNotasModal()
                    }}>
                      Salvar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div class='col-4' >
                <Button
                  title='Editar Avaliação'
                  style={{ width: '15%' }}
                  onClick={async (e) => {
                    modalEditAvaliacao(id, name, weight)

                  }
                  }
                  leftIcon={'edit'}
                />
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={editarNotaModal}
                  onHide={hideEditarNotaModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Editar Avaliação</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h3>
                      {currentAssessmentName}
                    </h3>
                    <hr></hr>
                    {renderEditmodal()}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={hideEditarNotaModal}>
                      Fechar
                    </Button>
                    <Button variant="primary" onClick={async (e) => {
                      saveNotasEditModal()
                    }}>
                      Salvar
                    </Button>
                  </Modal.Footer>
                </Modal>

              </div>
              <div class='col-4'  >
                <Button
                  title='Deletar Avaliação'
                  style={{ width: '15%' }}
                  onClick={async (e) => {
                    modalDelete(id, name)
                  }
                  }
                  leftIcon={'delete'}
                />
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={deletarModal}
                  onHide={hideDeletarModal}>
                  <Modal.Header closeButton>
                    <Modal.Title> Excluir {currentAssessmentName} ?</Modal.Title>
                  </Modal.Header>
                  <ModalBody>
                    <h6 style={{ fontWeight: "bold" }}>Atenção todas as notas relacionadas a essa prova serão tambem excluidas!

                    </h6>
                  </ModalBody>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={hideDeletarModal}>
                      Fechar
                    </Button>
                    <Button variant="primary" onClick={async (e) => {
                      deletarAvaliacao()
                    }}>
                      Confirmar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

            </div>

          </td>
        </tr>
      })
    } else if ((tableBodyAssessment && tableBodyAssessment.length === 0)) {
      return <tr>
        <td colSpan={4} > Não existe avaliação registrada. </td>

      </tr>
    } else {
      return <tr>
        <td colSpan={4} > Selecione uma Disciplina </td>

      </tr>
    }
  }

  const saveNotasEditModal = async () => {
    if (weightRef.current.value === NaN || weightRef.current.value == '' || weightRef.current.value == null || weightRef.current.value == undefined) {
      hideEditarNotaModal()
    } else {

      try {
        const editRes = await assessmentService.admin.updateAssessment(
          user,
          { assessmentId: currentAssessmentId, name: nameRef.current.value, weight: parseFloat(weightRef.current.value) }
        )
        const editAssessment = getResponseData(editRes, "Erro ao editar Avaliação")
        setSavingContent(false)

        if (editAssessment) {
          loadAssessments(disciplinaName, topicId, courseId)
          hideEditarNotaModal()
        }
      } catch (e) {
        console.log(e)
        alert('Erro ao criar Avaliação', e.message)
      }
    }
  }

  const renderUsers = () => {
    if (Object.keys(userUuid).length === 0) {
      return <div
        style={{
          display: "flex",
          height: "min-content",
        }}
        class='row'>
        <div class='col-12'>

          <Input
            label="Aluno"
            disabled
            value={('Não tem alunos Registrados')}
          />
        </div></div>
    } else if (topicId !== '' && assessmentsNameModal.length === Object.keys(userUuid[topicId] ?? []).length && usersDataModal && usersDataModal.length > 0) {
      return usersDataModal.map(({ name, firebaseUid }) => {
        return <div
          style={{
            display: "flex",
            height: "min-content",
          }}
          class='row mt-2'>
          <div class='col-6 '>
            <Form.Control
              type="text"
              placeholder={name}
              disabled
              value={name}
              readOnly
            />
          </div>
          <div class='col-6'>
            <Form.Control
              type="text"
              placeholder="Notas"
              defaultValue={userUuid[topicId] !== undefined && userUuid[topicId][currentAssessmentName] !== undefined && currentAssessmentName !== null && currentAssessmentName !== '' ? userUuid[topicId][currentAssessmentName][firebaseUid] : ''}
              onChange={e => elementRef.current[firebaseUid].value = e.target.value}
              ref={ref => {
                elementRef.current[firebaseUid] = ref
              }}
            />

          </div>

        </div>
      })
    } else {
      return <div
        style={{
          display: "flex",
          height: "min-content",
        }}
        class='row'>
        <div class='col-12' >
          <h6>Não tem alunos Registrados</h6>
        </div></div>
    }
  }

  const gradeSearchUsersByTopic = async (id) => {
    let userUuidArray = {}
    let defaultUsers = {}
    const gradesBody = await gradesService.admin.getAllAssessmentsAndGradesByCourse(user, id)
    const gradesData = getResponseData(gradesBody)
    if (gradesData) {
      usersDataModal.map(({ name, firebaseUid }) => {
        defaultUsers[firebaseUid] = '-'
      })
      gradesData.map((assessment) => {
        if (!userUuidArray.hasOwnProperty(assessment.assessment.topicId)) {
          userUuidArray[assessment.assessment.topicId] = {}
        }
        userUuidArray[assessment.assessment.topicId][assessment.assessment.name] = JSON.parse(JSON.stringify(defaultUsers));
        for (let i in assessment.assessment.grades) {
          userUuidArray[assessment.assessment.topicId][assessment.assessment.name][assessment.assessment.grades[i].userUid] = assessment.assessment.grades[i].grade
        }
      })
      setUserUuid(userUuidArray)
    }
  }

  const renderVisualizacaoTable = () => {
    if (Object.keys(userUuid).length === 0) {
      return <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Aluno</th>
            {assessmentsNameModal.map((assessment, index) => (
              <th key={assessment.name}>{assessment.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2 + assessmentsNameModal.length} > Não existem notas registradas. </td>
          </tr>
        </tbody>
      </Table>
    } else if (topicId !== '' && assessmentsNameModal.length === Object.keys(userUuid[topicId] ?? []).length) {
      return <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Aluno</th>
            {assessmentsNameModal.map((assessment, index) => (
              <th key={assessment.name}>{assessment.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usersDataModal.map((assessment, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{assessment.name}</td>
              {assessmentsNameModal.map((grades, index) => (
                <td>{userUuid[topicId][grades.name] !== undefined ? userUuid[topicId][grades.name][assessment.firebaseUid] : ''}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    } else {
      return <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Aluno</th>
            {assessmentsNameModal.map((assessment, index) => (
              <th key={assessment.name}>{assessment.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2 + assessmentsNameModal.length} > Carregando dados. </td>
          </tr>
        </tbody>
      </Table>
    }
  }


  const weightValueTable = (topicWeight) => {
    let w = 0
    if (topicWeight && topicWeight.length > 0) {
      topicWeight.map(({ weight }) => {
        w = (w + (weight))
      })
    }
    setWeightValue(w)
  }

  const weightValueMax = () => {
    if (weightValue === 0 || weightValue === null || weightValue === '' || weightValue === undefined) {
      return <div></div>
    } else if (weightValue > 1) {
      return <div style={{ display: 'flex', float: 'left', alignItems: 'flex-start', justifyContent: 'space-between', margin: '0' }}>
        <h6 style={{ fontWeight: "bold" }}>Atenção! A soma dos pesos das provas está ultrapassando o limite (o peso máximo somado deve ser 1)

        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          Peso total : <span style={{ color: 'red' }}>{weightValue}</span>
        </h6>
      </div>
    } else if (weightValue === 1) {
      return <div style={{ display: 'flex', float: 'left', alignItems: 'flex-start', justifyContent: 'end', margin: '0' }}
      >
        <h6 style={{ fontWeight: "bold" }}>
          <span style={{ justifyContent: "end" }}> Peso total : <span style={{ color: 'green' }}>{weightValue}</span>
          </span>
        </h6>

      </div>
    } else {
      return <div style={{ display: 'flex', float: 'left', alignItems: 'flex-start', justifyContent: 'space-between', margin: '0' }}
      >
        <h6 style={{ fontWeight: "bold" }}>Atenção! a soma dos pesos deve ser 1.

        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          <span style={{ justifyContent: "end" }}> Peso total : <span style={{ color: 'blue' }}>{weightValue}</span>
          </span>
        </h6>
      </div>
    }
  }




  return loading ? <Loading key="loading-admin-topics" type='elements-with-cards' /> : (

    <div
      key='admin-topics-index-body-key'
      style={{ width: '100%', maxWidth: 1224, margin: '1rem 0.5rem 0.5rem 0.5rem' }}
    >
      <Card title="Avaliações">
        <h3 >
          <div class='row'>
            <div class='col'>

              {parent ? 'Filhos ' : 'Avaliações Cadastradas'}
            </div>
            <div class='col' style={{ display: 'flex', float: 'left', alignItems: 'flex-start', justifyContent: 'start', margin: '0' }}>
              <Dropdown data-bs-theme="dark">
                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" name={{ disciplinaName }} style={{ backgroundColor: 'black' }}>
                  {disciplinaName}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ overflow: 'scroll', maxHeight: '250px' }}>
                  <div >
                    {topics.map((item) => {
                      if (item.isSubject === true) {
                        return (
                          <Dropdown.Item id={item.id} onClick={() => {
                            loadAssessments(item.name, item.id, item.courseId)
                          }}>{item.name}</Dropdown.Item>
                        )

                      }
                    })}

                  </div>
                </Dropdown.Menu>
              </Dropdown>

            </div>

            <div class='col' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'end' }}>
              <div style={{ marginRight: '3px' }}>
                <Button
                  title='Visualizar Notas'
                  onClick={
                    visualizarNotasModal
                  }
                  style={{ margin: '100px' }}
                  leftIcon={'menu-book'}
                />

                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={visualizarModal}
                  onHide={hideVisualizarNotasModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Visualizar Notas</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {renderVisualizacaoTable()}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={hideVisualizarNotasModal}>
                      Fechar
                    </Button>
                  </Modal.Footer>
                </Modal>


              </div>
              <div>

                <Button
                  title='Cadastrar Avaliação'
                  onClick={
                    cadastrarAssessmentModal
                  }

                  leftIcon={'assigment'}
                />
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={cadastrarModal}
                  onHide={hideCadastrarAssessmentModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Nova Avaliação</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {!topicId &&
                      <div
                        style={{
                          display: "flex",
                          height: "min-content",
                        }}
                        class='row'>

                        <div class='col-6'>
                          <h4>Selecione uma Disciplina</h4>
                        </div>
                      </div>

                    }{topicId &&
                      <div
                        style={{
                          display: "flex",
                          height: "min-content",
                        }}
                        class='row'>

                        <div class='col-6'>

                          <Input
                            label="Nome da Avaliação"
                            value={assessmentName}
                            onChange={event => setAssessmentName(event.target.value)}
                          />
                        </div>
                        <div class='col-6'>

                          <Input
                            label="Peso"
                            value={weightAssessment}
                            onChange={event => setWeightAssessment(event.target.value)}
                          />
                        </div>

                      </div>

                    }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={hideCadastrarAssessmentModal}>
                      Fechar
                    </Button>
                    <Button variant="primary" onClick={async (e) => {
                      createAssessment()
                    }}>
                      Salvar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>

          </div>
        </h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: '6%' }}>#</th>
              <th>Avaliação</th>
              <th>Peso</th>
              <th style={{ width: '18%' }}>Ações</th>

            </tr>
          </thead>
          <tbody>

            {renderAssessments()}

          </tbody>
        </Table>
        {weightValueMax()}
      </Card>
    </div>

  )
}

export default CourseAssessments