import { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import cookie from 'react-cookies'

import './App.css';
import Home from './pages/public/home';

import UserProfilePage from './pages/userProfilePage';
import UserAssessments from './pages/users/assessments';
import UserAssessment from './pages/users/assessment';
import UserScores from './pages/users/scores';
import UserFinalScores from './pages/users/finalScores';

import CoursesIndex from './pages/courses/index';
import CoursesShow from './pages/courses/show';
import CourseFoldersIndex from './pages/courseFolders/index';
import CourseCalendar from './pages/calendar';

import TopicsShow from './pages/topics/show';
import Error404 from './pages/errors/404';
import VideosIndex from './pages/videos';

import AdminCoursesIndex from './pages/admin/courses';
import AdminCoursesNew from './pages/admin/courses/new';
import AdminCoursesEdit from './pages/admin/courses/edit';
import AdminUsersIndex from './pages/admin/users';
import AdminUsersEdit from './pages/admin/users/edit';
import AdminFilesIndex from './pages/admin/files';
import AdminCalendarEntriesIndex from './pages/admin/calendarEntries';
import AdminTasksIndex from './pages/admin/tasks';
import AdminEditTask from './pages/admin/tasks/editTask';
import AdminAssessmentsIndex from './pages/admin/assessments';
import AdminAssessmentsNew from './pages/admin/assessments/new';
import AdminAssessmentsEdit from './pages/admin/assessments/edit';
import AdminConfigsIndex from './pages/admin/configs';
import VideoPlayer from './pages/video-player';

import useUserState from './hooks/useUserState'
import helpers from './AppHelpers'
import AdminEditCategory from './pages/admin/courseCategories/editCourseCategory';
import AdminCourseCategory from './pages/admin/courseCategories/showCourseCategories';
import { userService } from './services/userService';

const pages = {
  '/': (loadUser, getResponseData, goToLogin) => (
    <Home
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/sua-conta': (loadUser, getResponseData, goToLogin) => (
    <UserProfilePage
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),

  '/cursos': (loadUser, getResponseData, goToLogin) => (
    <CoursesIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId': (loadUser, getResponseData, goToLogin) => (
    <CoursesShow
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/aulas-gravadas': (loadUser, getResponseData, goToLogin) => (
    <VideosIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/material-de-apoio': (loadUser, getResponseData, goToLogin) => (
    <CourseFoldersIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/calendario': (loadUser, getResponseData, goToLogin) => (
    <CourseCalendar
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
     />
   ),
  '/cursos/:courseName/notas': (loadUser, getResponseData, goToLogin) => (
    <UserFinalScores
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/avaliacoes': (loadUser, getResponseData, goToLogin) => (
    <UserAssessments
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/avaliacoes/:assessmentId': (loadUser, getResponseData, goToLogin) => (
    <UserAssessment
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/avaliacoes/:assessmentId/tentativas/:attemptId': (loadUser, getResponseData, goToLogin) => (
    <UserAssessment
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),

  '/cursos/:courseId/topicos/:id': (loadUser, getResponseData, goToLogin) => (
    <TopicsShow
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/topicos/:id/aulas-gravadas': (loadUser, getResponseData, goToLogin) => (
    <VideosIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/topicos/:id/material-de-apoio': (loadUser, getResponseData, goToLogin) => (
    <CourseFoldersIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/cursos/:courseId/topicos/:id/calendario': (loadUser, getResponseData, goToLogin) => (
    <CourseCalendar
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
     />
   ),
  '/cursos/:courseId/topicos/:topicId/notas': (loadUser, getResponseData, goToLogin) => (
    <UserScores
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
     />
   ),
  '/cursos/:courseId/topicos/:topicId/avaliacoes': (loadUser, getResponseData, goToLogin) => (
    <UserAssessments
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
     />
   ),
  '/cursos/:courseId/topicos/:topicId/avaliacoes/:assessmentId': (loadUser, getResponseData, goToLogin) => (
    <UserAssessments
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  'cursos/:courseId/aulas-gravadas/:calendarEntryId': (loadUser, getResponseData, goToLogin) => (
    <VideoPlayer
      loadUser={loadUser}
      getResponseData={getResponseData}
      goToLogin={goToLogin}
    />
  ),
  'cursos/:courseId/topicos/:topicId/aulas-gravadas/:calendarEntryId': (loadUser, getResponseData, goToLogin) => (
    <VideoPlayer
      loadUser={loadUser}
      getResponseData={getResponseData}
      goToLogin={goToLogin}
    />
  ),

  '/admin': (loadUser, getResponseData, goToLogin) => (
    <AdminCoursesIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/cursos': (loadUser, getResponseData, goToLogin) => (
    <AdminCoursesIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/cursos/novo': (loadUser, getResponseData, goToLogin) => (
    <AdminCoursesNew
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/cursos/:courseId/editar': (loadUser, getResponseData, goToLogin) => (
    <AdminCoursesEdit
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/avaliacoes': (loadUser, getResponseData, goToLogin) => (
    <AdminAssessmentsIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/avaliacoes/nova': (loadUser, getResponseData, goToLogin) => (
    <AdminAssessmentsNew
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/avaliacoes/:assessmentId/editar': (loadUser, getResponseData, goToLogin) => (
    <AdminAssessmentsEdit
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/usuarios': (loadUser, getResponseData, goToLogin) => (
    <AdminUsersIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/usuarios/:userUid/editar': (loadUser, getResponseData, goToLogin) => (
    <AdminUsersEdit
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/arquivos': (loadUser, getResponseData, goToLogin) => (
    <AdminFilesIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  '/admin/calendario': (loadUser, getResponseData, goToLogin) => (
    <AdminCalendarEntriesIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  'admin/tarefas': (loadUser, getResponseData, goToLogin) => (
    <AdminTasksIndex
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  'admin/tarefas/editar/:taskId': (loadUser, getResponseData, goToLogin) => (
    <AdminEditTask
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  ),
  'admin/configuracoes': (loadUser, getResponseData, goToLogin) => (
    <AdminConfigsIndex
      loadUser={loadUser}
      getResponseData={getResponseData}
    />
  ),
  'admin/categorias-de-curso': (loadUser, getResponseData, goToLogin) => (
    <AdminCourseCategory
      loadUser={loadUser}
      getResponseData={getResponseData}
    />
  ),
  'admin/categorias-de-curso/editar/:courseCategoryID': (loadUser, getResponseData, goToLogin) => (
    <AdminEditCategory
      loadUser={loadUser}
      goToLogin={goToLogin}
      getResponseData={getResponseData}
    />
  )
}

function App() {
  const navigate = useNavigate()
  const [, , , , , , goToLogin] = useUserState();
  const location = useLocation()

  const getResponseData = (response, errorMsg) => (
    helpers.res.getResponseData({
      response,
      goToLogin,
      navigate,
      errorMsg
    })
  )

  const loadUser = () => cookie.load('tsatk') || null

  useEffect(() => {
    const user = loadUser()
    userService.common.createVisitedPage(loadUser(), location)
  }, [location])

  return (
    <div className='app'>
      <Routes>
        {
          Object.keys(pages).map(path => (
            <Route
              key={path}
              path={path}
              element={pages[path](
                loadUser,
                getResponseData,
                goToLogin
              )} />
          ))
        }
        <Route path="*" element={ <Error404/> } />
      </Routes>
    </div>
  );
}

export default App;