import { HOST, baseApiService } from "./baseApiService";

const configService = {
  host: HOST,
  paths: {
    admin: {
      configsPath: () => `/admin/configs`,
      configPath: (id) => `/admin/configs/${id}`,
    },
  },

  admin: {
    index: async (user, params) => {
      return await baseApiService.get(
        user,
        configService.paths.admin.configsPath(),
        params || {}
      );
    },

    update: async (user, configId, attrs) => {
      return await baseApiService.patch(
        user,
        configService.paths.admin.configPath(configId),
        attrs || {}
      );
    },
  },
};

export { configService };
