import { Link, useParams } from "react-router-dom";
import Header from '../../components/Header'
import SwapTopicEnding from '../../components/SwapTopicEnding'
import Progress from '../../components/Progress'
import Icon from '../../components/Icon'
import Loading from '../../components/Loading'
import useCourseState from "../../hooks/useCourseState";
import useTopicState from "../../hooks/useTopicState";
import BibliografyField from '../../components/BibliografyField';
import PageBoxLink from '../../components/PageBoxLink';
import CalendarCard from '../../components/CalendarCard';
import helpers from '../../AppHelpers';

const TopicsShow = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const { courseId, id } = useParams()

  const [course] = useCourseState(user, courseId, getResponseData)
  const [topic, , error, progress, toggleProgress] = useTopicState(
    user,
    courseId,
    id,
    getResponseData
  )

  return [
    <Header
      key='course-topic-header-key'
      user={user}
      course={course}
      topic={topic}
    />,
    !course || !topic ? (
      <Loading key="loading-topic" type='elements' />
    ) : (
      renderTopic(course, topic, progress, toggleProgress, user, getResponseData)
    ),
    error && (
      <div
        key='course-topic-error-key'
        className='http-error-wrapper'
      >
        <div className='http-error-description'>
          {
            error === 404 ? (
              <div>
                <p>
                  Conteúdo não encontrado na Tech School
                </p>
                <p style={{ fontSize: '0.9em' }}>
                  Confira o endereço no seu navegador
                </p>
              </div>
            ) : (
              'erro não mapeado'
            )
          }
        </div>

        <div className='http-error'>
          <Icon
            name={'warning'}
            style={{ fontSize: '3rem', color: '#f16600 !important' }}
          />
          {error}
        </div>
      </div>
    )
  ]
}

const renderTopic = (course, topic, progress, toggleProgress, user, getResponseData) => {
  if (!course || !topic)
    return null

  const { children, siblings } = topic
  const nextTopic = children?.length === 0 ? siblings.find(t => t.number === topic.number + 1) : null

  return topic?.isSubject ?
    (
      <div
        key="topicShow"
        className='app-body'
        style={{
          overflow: 'auto',
          marginBottom: '2rem',
        }}
      >
        <div style={{
          padding: '0.5rem 0',
          display: 'flex',
          gap: '0.5rem',
          minHeight: '3rem',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{
            color: '#777 !important',
            fontWeight: 'bold',
            fontSize: '1.4em',
            textDecoration: 'underline ',
            margin: '1rem'
          }}>
            {topic.name}
          </div>
        </div>
        <div
          style={{
            color: 'black !important',
            textDecoration: 'none',
            margin: '1rem'
          }}
        >
          <h2
            style={{
              color: 'black !important',
              textDecoration: 'none'
            }}
          >
            Ementa
          </h2>

          <div dangerouslySetInnerHTML={{ __html: topic?.content }} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            color: 'black !important',
            fontWeight: 'bold',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <hr
              className='custom-hr-line'
            />
            Equipe Docente
            <hr
              className='custom-hr-line'
            />
          </div>
          <BibliografyField
            user = {user}
            topic = {topic.id}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '2rem',
            flexWrap: 'wrap',
            padding: '0.5rem'
          }}
        >
          <PageBoxLink
            title='Aulas Gravadas'
            to="aulas-gravadas"
            iconName='video'
          />
          <PageBoxLink
            title='Material de Apoio'
            to="material-de-apoio"
            iconName='book'
          />
          <PageBoxLink
            title='Avaliações'
            to="avaliacoes"
            iconName='pending-actions'
          />
          <PageBoxLink
            title='Notas'
            to="notas"
            iconName='star'
          />
        </div>

        <div
          style={{
            padding: '0.5rem'
          }}
        >
          <CalendarCard
            user={user}
            topic={topic}
            course={course}
            criteria={{minDate: new Date()}}
            getResponseData={getResponseData}
          />
        </div>

      </div>
    ) : (
      <div key='course-topic-page-key' className='app-body'>
        <article style={styles.article}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            marginTop: '0.5rem',
            padding: '0 0.6rem',
            overflow: 'auto'
          }}>
            <div style={{
              padding: '0.5rem 0',
              display: 'flex',
              gap: '0.5rem',
              minHeight: '3rem',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: 'solid 1px black',
            }}>
              <div>
                <div style={{ color: '#777 !important', fontSize: '0.8em' }}>
                  {topic.hierarchySize === 1 ? 'Módulo ' : 'Tópico '}
                  {topic.numberPrefix ? `${topic.numberPrefix}.` : ''}{topic.number}
                </div>
                <div style={{
                  fontWeight: 'bold',
                }}>
                  {topic.name}
                </div>
              </div>

              {
                course.config().useProgress && (
                  <div>
                    <Progress
                      todo={progress?.todo || 0}
                      done={progress?.done || 0}
                    />
                  </div>
                )
              }
            </div>

            <section style={styles.section}>
              <div className='topic-content' dangerouslySetInnerHTML={{ __html: topic.content }} />
            </section>

            <section style={Object.assign({}, styles.section, {})}>
              <div style={{
                borderTop: 'solid 1px #DDD',
                width: '100%',
                marginBottom: '1rem'
              }}>
              </div>

              {
                topic.children.length === 0 ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div>
                        Você chegou ao fim do {topic.hierarchySize === 1 ? 'módulo ' : 'tópico '}
                        <b>{topic.name}</b>.
                      </div>
                      <SwapTopicEnding
                        topic={topic}
                        progress={progress}
                        toggleProgress={toggleProgress}
                      />
                    </div>
                    {
                      nextTopic && (
                        <p>
                          O próximo {nextTopic.hierarchySize === 1 ? 'módulo é ' : 'tópico é '}
                          <Link
                            className='link'
                            to={ helpers.path.student.topic(course.nameId, nextTopic.id) }
                          >
                            <b>{nextTopic.name}</b>
                          </Link>
                        </p>
                      )
                    }
                  </div>
                ) : (
                  <div>
                    <div>
                      O conteúdo deste {topic.hierarchySize === 1 ? 'módulo' : 'tópico'} está dividido em:
                    </div>

                    <div style={{
                      marginTop: '0.75rem',
                      border: 'solid 1px transparent',
                      display: 'inline-block',
                      borderRadius: '0.15rem'
                    }}>
                      {
                        topic.children.map((t, i) => (
                          <div
                            key={`child-${t.id}`}
                            style={{
                              fontSize: '1.1rem',
                              display: 'flex',
                              gap: '1rem',
                              borderTop: `solid 1px ${i === 0 ? 'transparent' : 'transparent'}`,
                              padding: '0.5rem',
                            }}
                          >
                            <div style={{
                              color: '#888 !important',
                              fontSize: '0.8em',
                              marginTop: '0.2rem'
                            }}>
                              {t.numberPrefix ? `${t.numberPrefix}.` : ''}{t.number}.
                            </div>
                            <Link
                              className='link'
                              to={ helpers.path.student.topic(course.nameId, t.id) }
                              style={{}}
                            >
                              {t.name}
                            </Link>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
            </section>
          </div>
        </article>
      </div>
    )
}

const styles = {
  article: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '2rem 0.6rem 0.6rem 0.6rem',
    overflowX: 'auto',
    maxWidth: '100%'
  },
  topicNav: (topic, options) => ({
    display: 'flex',
    flex: 1,
    gap: '1rem',
    alignItems: 'center',
    justifyContent: options?.alignRight ? 'flex-end' : 'flex-start',
    maxWidth: '13rem',
    color: topic ? '#000 !important' : '#444 !important',
    fontSize: '0.8rem',
    cursor: topic ? 'pointer' : 'default',
    textAlign: options?.alignRight ? 'right' : 'left',
    fontStyle: 'italic'
  })
}
export default TopicsShow
