import styled from 'styled-components';

const AlertList = styled.div`
    display: flex;
    flex-direction: column;
    width: 30vw;
    position: fixed;
    margin: 1rem;
    top: 0.5rem;
    right: 0.5rem;
    background: none;

    @media(max-width: 750px){
        width: 90vw;
        align-self: center;
    }
`;

const AlertBox = styled.div`
    background-color: ${(props) => props.background};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 100%;
    border-radius: 0.25rem;
    margin: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 1px 1px 5px 0px #444;
    gap: 0.5rem;
    opacity: 0.8;
    cursor: pointer;
`;

const AlertText = styled.p`
    color: #ffffff !important;
    height: bold;
    text-overflow: ellipsis;
`;

const AlertButton = styled.button`
    float: right;
    background: none;
    border: none;
    opacity: 0.8;
    cursor: pointer;
    color: #ffffff !important;
    height: bold;
    margin-right: 0.5rem;
`;

export { AlertList, AlertBox, AlertText, AlertButton }