import { HOST, baseApiService } from "./baseApiService";

const gradesService = {
  host: HOST,
  paths: {
    getAllGradesByTopicPath: (userUid, topicId) =>
      `/grades/${topicId}/${userUid}`,
    getAllFinalGradesPath: (courseId, userUid) =>
      `/courses/${courseId}/assessments/${userUid}`,
    admin: {
      gradesDefaultPath: () => "/admin/grade",
      gradesBatchPath: () => "/admin/grades",
      getAllGradesByAssessment: (assessmentId) =>
        `/admin/grade/${assessmentId}`,
      getOneGradeByAssessement: (assessmentId) =>
        `/admin/grade/${assessmentId}`,
      updateUserGrade: (gradeId) => `/admin/grade/${gradeId}`,
      getAllAssessmentsAndGradesByCourse: (courseId) => `/admin/${courseId}/grades`,
    },
  },

  admin: {
    //Cria uma nova nota
    createNewGrade: async (user, params) => {
      const path = gradesService.paths.admin.gradesDefaultPath();
      return await baseApiService.post(user, path, params || {});
    },
    //Cria grade em lote
    createGradesInBatch: async (user, params) => {
      const path = gradesService.paths.admin.gradesBatchPath();
      return await baseApiService.post(user, path, params || {});
    },

    //Pega todas as notas com base em uma assessment
    getAllGradesByAssessment: async (user, params) => {
      const path = gradesService.paths.admin.gradesDefaultPath();
      return await baseApiService.get(user, path, params || {});
    },

    //Pega a nota de um usuário específico dada determinada assessment
    getUserGradeByAssessment: async (user, assessmentId, params) => {
      const path =
        gradesService.paths.admin.getOneGradeByAssessement(assessmentId);
      return await baseApiService.get(user, path, params || {});
    },

    //Pega todas as notas de todas as assessments
    getAllAssessmentsAndGradesByCourse: async (user, courseId) => {
      const path =
        gradesService.paths.admin.getAllAssessmentsAndGradesByCourse(courseId);
      return await baseApiService.get(user, path);
    },

    //Atualiza uma nota
    updateGrade: async (user, gradeId, params) => {
      const path = gradesService.paths.admin.updateUserGrade(gradeId);
      return await baseApiService.patch(user, path, params || {});
    },

    //Deleta uma nota
    deleteGrade: async (user, params) => {
      const path = gradesService.paths.admin.gradesDefaultPath();
      return await baseApiService.delete(user, path, params || {});
    },
  },

  common: {
    getAllGradesByTopicAndUser: async (user, userUid, topicId) => {
      const path = gradesService.paths.getAllGradesByTopicPath(
        userUid,
        topicId
      );
      return await baseApiService.get(user, path);
    },

    getAllGradesByCourseAndUser: async (user, userUid, courseId) => {
      const path = gradesService.paths.getAllFinalGradesPath(courseId, userUid);
      return await baseApiService.get(user, path);
    },
  },
};

export default gradesService;
