import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../../components/Header'
import { courseService } from '../../../services/courseService';
import CreateOrUpdate from './CreateOrUpdate'

const AdminCoursesNew = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const navigate = useNavigate()
  const [savingCourse, setSavingCourse] = useState(false)

  const createCourse = async (courseAttrs) => {
    setSavingCourse(true)
    const createRes = await courseService.admin.create(user, courseAttrs)
    const data = getResponseData(createRes)
    setSavingCourse(false)
  }
  
  return [
    <Header
      key='admin-courses-index-header-key'
      admin="Avaliações"
      user={user}
    />,
    <div
      className='app-body'
      key='admin-courses-index-body-key'
    >
      <CreateOrUpdate
        user={ user }
        onFinish={ courseAttrs => {
          createCourse(courseAttrs)
          navigate('/admin/cursos')
        }}
        loading={ savingCourse }
        getResponseData={ getResponseData }
      />
    </div>
  ]
}

export default AdminCoursesNew
