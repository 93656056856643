import { useState, useEffect } from 'react';
import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import SelectOptions from '../../../components/SelectOptions'
import Button from '../../../components/Button'
import AdminCalendarEntries from '../../../components/AdminCalendarEntries';
import { courseService } from '../../../services/courseService';
import { calendarService } from '../../../services/calendarEntryService';

const computeDateCriteria = (date) => ({
  minDate: new Date(date.getFullYear(), date.getMonth(), 1, 0,  0,  0,  0),
  maxDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
})

const AdminCalendarEntriesIndex = ({loadUser, getResponseData, goToLogin}) => {
  const user = loadUser()
  const [courses, setCourses] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [entries, setEntries] = useState(null)
  const [dateCriteria, setDateCriteria] = useState(computeDateCriteria(new Date()))

  useEffect(() => {
    const load = async () => {
        const coursesRes = await courseService.common.getCourses(user)
        const data = getResponseData(coursesRes)
        if (data) {
          setCourses(coursesRes.data)
          setSelectedCourse(coursesRes.data[0])
        }
    }

    if (user)
      load()
    else
      goToLogin()
  }, [])

  useEffect(() => {
    const load = async () => {
      setEntries(null)
      const {minDate, maxDate} = dateCriteria
      const entriesRes = await calendarService.admin.index(
        user,
        Object.assign(
          selectedCourse ? {courseId: selectedCourse.id} : {},
          minDate ? {minDate} : {},
          maxDate ? {maxDate} : {}
        )
      )
      const data = getResponseData(
        entriesRes,
        "Erro ao carregar as entradas de calendário"
      )
      if (data)
        setEntries(data)
      else
        setEntries([])
    }

    if (selectedCourse)
      load()
  }, [dateCriteria, selectedCourse])

  const date = dateCriteria.minDate
  const subjectById = {}
  const courseById = {}
  const subjectWithEntriesById = {}

  courses?.forEach(course => {
    courseById[course.id] = course
    course.subjects?.map(subject => {
      subjectById[subject.id] = subject
    })
  })

  entries?.forEach(entry => {
    subjectWithEntriesById[entry.topicId] = subjectById[entry.topicId]
  })

  return [
    <Header
      key='admin-calendar-entries-index-header-key'
      admin="Calendário"
      user={user}
    />,
    user && courses && entries ? (
      <div
       className='app-body'
       key='admin-calendar-entries-index-body-key'
       style={{paddingBottom: '5rem',}}
      >
        <h2>
          Calendário
        </h2>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem',
          alignItems: 'center',
        }}>
          <Button
            onClick={ async () => {
              date.setMonth(date.getMonth() - 1)
              setDateCriteria(computeDateCriteria(date))
            }}
            leftIcon='double-arrow-left'
          />
          <h3 className='first-letter-up-case'>
            {
              date.toLocaleString(
                "pt-BR",
                {
                  month: 'long',
                  year: 'numeric',
                }
              )
            }
          </h3>
          <Button
            onClick={ async () => {
              date.setMonth(date.getMonth() + 1)
              setDateCriteria(computeDateCriteria(date))
            }}
            leftIcon='double-arrow-right'
          />
        </div>

        <div style={{margin: '1rem 0'}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '1rem',
              borderTop: 'solid 4px white',
              paddingTop: '1rem',
              marginBottom: '1.5rem'
            }}
          >
            <SelectOptions
              label='Curso'
              selected={ selectedCourse?.id }
              options={ courses?.map(c => ({
                label: c.name,
                value: c.id
              }))}
              onChange={
                (event) => {
                  setSelectedCourse(courseById[event.target.value])
                }
              }
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            />
          </div>

          <div>
            <div className='small-text' style={{marginBottom: '0.25rem'}}>
              Disciplinas do curso com entradas de calendário neste mês:
            </div>
            {
              Object.keys(subjectWithEntriesById).length === 0 ? (
                <div className='light-text'>
                  Nenhuma disciplina encontrada.
                </div>
              ) : (
                <div>
                  {
                    Object.entries(subjectWithEntriesById).map(([_, subject]) => (
                      <div
                        key={`subject-tag-${subject?.id || 'no-subject'}`}
                        style={{
                          display: 'inline-block',
                          padding: '0.25rem 0.5rem',
                          margin: '0.25rem 0.25rem 0.25rem 0',
                          border: 'solid 1px #DDD',
                          backgroundColor: '#FAFAFA'
                        }}
                      >
                        {subject?.name || 'sem disciplina'}
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
        </div>


        <AdminCalendarEntries
          user={user}
          getResponseData={getResponseData}
          date={date}
          startDate={dateCriteria.minDate}
          endDate={dateCriteria.maxDate}
          entries={entries}
          courses={courses}
          course={selectedCourse}
          courseById={courseById}
          subjectById={subjectById}
          onUpdateEntry={(updatedEntry) => {
            const isNewEntry = !entries.find(entry => entry.id === updatedEntry.id)
            setEntries(
              isNewEntry ? [updatedEntry].concat(entries) : entries.map(en => (
                updatedEntry.id === en.id ? updatedEntry : en
              ))
            )
          }}
        />
      </div>
    ) : <Loading key="loading-admin-calendar-entries" type='elements-with-cards'/>
  ]
}

export default AdminCalendarEntriesIndex
