import { Component } from 'react';
import Icon from './Icon';
import { Link } from "react-router-dom";

class HeaderMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  changeMenuVisibility(isVisible, event) {
    if (event)
      event.stopPropagation()
    this.setState({menuOpen: isVisible})
  }

  render() {
    const {
      icon,
      customIcon,
      title,
      options,
      position,
      navigate,
      user
    } = this.props
    const { menuOpen } = this.state
    return (
      <div style={{position: 'relative'}}>
        {
          icon ? (
            <Icon
              name={icon}
              onClick={(e) => this.changeMenuVisibility(true, e)}
            />
          ) : (
            customIcon
          )
        }
        { menuOpen && [
          <div
            onClick={(e) => this.changeMenuVisibility(false, e)}
            key={ `bg-with-opacity-${ icon }` }
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#111',
              opacity: 0.5,
              zIndex: 10000,
          }}></div>,
          <div
            key={ `bg-without-opacity-${ icon }` }
            className='box-shadow'
            style={
              Object.assign(
                {
                  padding: '0.75rem 1rem',
                  position: 'fixed',
                  backgroundColor: '#111',
                  width: '15rem',
                  maxWidth: '50vw',
                  height: '100%',
                  zIndex: 10001,
                  top: 0
                },
                position === 'right' ? (
                  { right: 0, borderLeft: 'solid 2px #333' }
                ) : (
                  { left: 0, borderRight: 'solid 2px #333' }
                )
              )
            }
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '0.5rem',
              borderBottom: 'solid 2px #333'
            }}>
              <b>{ title }</b>
              <Icon
                className='link-breadcrumb-item'
                name='close'
                style={{padding: '0.25rem', color: 'white !important'}}
                onClick={(e) => this.changeMenuVisibility(false, e)}
              />
            </div>

            <div style={{display: 'flex', flexDirection: 'column'}}>
              {
                options.map(option => (
                  <Link
                    key={ option.path }
                    to={ option.path }
                    className='link-breadcrumb-item'
                    onClick={async (e) => {
                      e.preventDefault()

                      if (option.action)
                        await option.action()

                      navigate(option.path)
                      this.changeMenuVisibility(false, e)
                    }}
                    style={styles.menuLink}
                  >
                    <Icon style={{flex: 1, color: '#bdf100 !important'}} name={ option.icon }/>
                    <div style={{flex: 3}}>{ option.name }</div>
                  </Link>

                ))
              }
            </div>

            {
              user?.activeRole === 'admin' && (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderTop: 'solid 2px #333'
                }}>
                  <Link
                    key={ 'link-admin' }
                    to={ '/admin' }
                    className='link-breadcrumb-item'
                    onClick={async (e) => {
                      e.preventDefault()
                      navigate('/admin')
                      this.changeMenuVisibility(false, e)
                    }}
                    style={styles.menuLink}
                  >
                    <Icon style={{flex: 1, color: '#bdf100 !important'}} name='security'/>
                    <div style={{flex: 3}}>Admin</div>
                  </Link>
                </div>
              )
            }
          </div>
        ] }
      </div>
    )
  }
}

const styles = {
  menuLink: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '0.5rem',
    padding: '1rem 0',
  }
}
export default HeaderMenu
