import { HOST, baseApiService } from "./baseApiService";

const assessmentService = {
  host: HOST,
  paths: {
    assessmentsPath: (courseId, topicId, assessmentId) => {
      const suffix = topicId ? `topics/${topicId}/assessments` : "assessments";
      const result = `/courses/${courseId}/${suffix}`;
      return assessmentId ? `${result}/${assessmentId}` : result;
    },
    admin: {
      assessmentsPath: () => `/admin/assessment`,
      assessmentPath: (id) => `/admin/assessment/${id}`,
    },
  },

  common: {
    AssessmentByCourse: async (user, courseId, topicId, params) => {
      return await baseApiService.get(
        user,
        assessmentService.paths.assessmentsPath(courseId),
        params
      );
    },
    getAssessment: async (user, courseId, topicId, assessmentId) => {
      return await baseApiService.get(
        user,
        assessmentService.paths.assessmentsPath(courseId, topicId, assessmentId)
      );
    },

  },

  admin: {
    index: async (user, params) => {
      return await baseApiService.get(
        user,
        assessmentService.paths.admin.assessmentsPath(),
        params || {}
      );
    },
    AssessmentByCourse: async (user, params) => {
      return await baseApiService.get(
        user,
        assessmentService.paths.admin.assessmentsPath(),
        params || {}
      );
    },
    deleteAssessment: async (user, params) => {
      return await baseApiService.delete(
        user,
        assessmentService.paths.admin.assessmentsPath(),
        params || {}
      );
    },
    create: async (user, params) => {
      return await baseApiService.post(
        user,
        assessmentService.paths.admin.assessmentsPath(),
        params || {}
      );
    },
    updateAssessment: async (user, params) => {
      return await baseApiService.patch(
        user,
        assessmentService.paths.admin.assessmentsPath(),
        params || {}
      );
    },
  },
};

export { assessmentService };
