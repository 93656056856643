import { HOST, baseApiService } from "./baseApiService";

const userService = {
  host: HOST,
  paths: {
    loginPath: () => `/users/login`,
    logoutPath: () => `/users/logout`,
    authorizePath: () => `/users/authorize`,
    forgotPasswordPath: () => `/users/forgot-password`,
    updatePasswordPath: () => `/users/update-password`,
    getprofessorsDatabyTopicPath: (courseId, topicId) => {
      return `/courses/${courseId}/topics/${topicId}/user-data`;
    },
    createVisitedPagePath: () => {
      return `/visited-pages/create`;
    },
    admin: {
      usersPath: (userUid) => `/admin/users${userUid ? `/${userUid}` : ""}`,
      usersIn: () => `/admin/users/in`,
      updateUserEnrollmentPath: (userUid) => `/admin/users/${userUid}/update-enrollment`,
      updateActiveRolePath: () => `/users/update-active-role`,
    },
  },

  common: {
    login: async (email, password) => {
      const path = userService.paths.loginPath();
      return await baseApiService.post(null, path, { email, password });
    },

    logout: async (user) => {
      const path = userService.paths.logoutPath();
      return await baseApiService.post(user, path);
    },

    authorize: async (user) => {
      return await baseApiService.get(user, baseApiService.paths.authorizePath());
    },

    forgotPassword: async (email) => {
      const path = userService.paths.forgotPasswordPath();
      return await baseApiService.post(null, path, { email });
    },

    updatePassword: async (user, newPassword) => {
      const path = userService.paths.updatePasswordPath();
      return await baseApiService.post(user, path, { newPassword });
    },

    createVisitedPage: async (user, location) => {
      const { pathname, search } = location;
      return await baseApiService.post(
        user,
        userService.paths.createVisitedPagePath(),
        {
          userEmail: user?.email,
          userUid: user?.firebaseUid,
          path: pathname,
          queryString: search.trim() === "" ? null : search,
          at: new Date(),
        }
      );
    },
  },

  temporary: {
    getProfessorsDataByTopic: async (user, courseId, topicId) => {
      const path = userService.paths.getprofessorsDatabyTopicPath(
        courseId,
        topicId
      );
      return await baseApiService.get(user, path);
    },
  },

  admin: {
    updateActiveRole: async (user, newRole) => {
      const updateRoleRes = await baseApiService.patch(
        user,
        userService.paths.updateActiveRolePath(),
        { newRole }
      );

      if (updateRoleRes.status !== 200)
        throw new Error(
          `[${updateRoleRes?.status}] ${updateRoleRes?.data?.message}`
        );

      return await updateRoleRes.data;
    },
    index: async (user, params) => {
      return await baseApiService.get(
        user,
        userService.paths.admin.usersPath(),
        params || {}
      )
    },
    show: async (user, userUid) => {
      return await baseApiService.get(
        user,
        userService.paths.admin.usersPath(userUid)
      )
    },
    showIn: async (user, userUid) => {
      return await baseApiService.post(
        user,
        userService.paths.admin.usersIn(),
        {ids:userUid}
      )
    },
    update: async (user, userUid, userAttrs) => {
      return await baseApiService.patch(
        user,
        userService.paths.admin.usersPath(userUid),
        userAttrs
      )
    },
    updateEnrollment: async (currentUser, { userUid, courseId, isEnrolled }) => {
      return await baseApiService.patch(
        currentUser,
        userService.paths.admin.updateUserEnrollmentPath(userUid),
        { courseId, isEnrolled }
      )
    },
  },
};

export {
  userService
}
