import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Icon from './Icon'
import Loading from './Loading'
import useTopicState from '../hooks/useTopicState'

const SwapTopicEnding = ({ topic, progress, toggleProgress, hideName }) => {
  return null
  const { courseId, id } = useParams()
  const checked = (progress?.done && progress.done === progress.todo)

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <div
        onClick={ () => toggleProgress() }
        style={{
          fontSize: '0.75rem',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          cursor: 'pointer',
          padding: '0.5rem'
        }}
      >
        {
          progress ? (
            progress.todo === 1 && (
              <Icon
                name={ checked ? 'check-box' : 'check-box-blank'  }
              />
            )
          ) : (
            <Loading/>
          )
        }

        { !hideName && 'Tópico finalizado' }
      </div>
    </div>
  )
}

export default SwapTopicEnding
