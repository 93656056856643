import { HOST, baseApiService, pathWithQueryString } from "./baseApiService";
const { ObjectId } = require("bson");

const fileRequests = {
  file: {
    commom: {
      getFiles: async (user, courseId, topicId, params) => {
        return await baseApiService.get(
          user,
          fileService.paths.filesPath(courseId, topicId),
          params
        );
      },
    },

    admin: {
      uploadChunk: async (user, blobChunk, params) => {
        return await baseApiService.post(
          user,
          fileService.paths.admin.uploadChunkPath(params),
          blobChunk,
          { "Content-Type": "application/octet-stream" }
        );
      },

      finishUpload: async (user, data) => {
        return await baseApiService.post(
          user,
          fileService.paths.admin.finishChunkedUploadPath(),
          data
        );
      },
      update: async (user, fileId, data) => {
        return await baseApiService.patch(
          user,
          fileService.paths.admin.updateFilePath(fileId),
          data
        );
      },
      delete: async (user, id) => {
        return await baseApiService.delete(
          user,
          fileService.paths.admin.filePath(id)
        );
      },
    },
  },

  folders: {
    admin: {
      index: async (user, parentId) => {
        return await baseApiService.get(
          user,
          fileService.paths.admin.foldersPath(),
          {
            parentId,
          }
        );
      },
      create: async (user, name, parentId) => {
        return await baseApiService.post(
          user,
          fileService.paths.admin.foldersPath(),
          { name, parentId }
        );
      },
      update: async (user, id, attrs) => {
        return await baseApiService.patch(
          user,
          fileService.paths.admin.folderPath(id),
          attrs
        );
      },
      delete: async (user, id) => {
        return await baseApiService.delete(
          user,
          fileService.paths.admin.folderPath(id)
        );
      }
    },
  },
};

const fileService = {
  host: HOST,
  paths: {
    filesPath: (courseId, topicId) => {
      const suffix = topicId ? `topics/${topicId}/files` : "files";
      return `/courses/${courseId}/${suffix}`;
    },
    admin: {
      fileUploadPath: (params) =>
        pathWithQueryString("/admin/files/upload", params),

      updateFilePath: (fileId, params) =>
        pathWithQueryString(`/admin/files/${fileId}/update`, params),

      filePath: (fileId, params) =>
        pathWithQueryString(`/admin/files/${fileId}`, params),

      uploadChunkPath: (params) =>
        pathWithQueryString("/admin/files/uploadChunk", params),

      finishChunkedUploadPath: (params) =>
        pathWithQueryString("/admin/files/finishChunkedUpload", params),

      foldersPath: () => `/admin/folders`,

      folderPath: (id) => `/admin/folders/${id}`,
    },
  },

  uploadFilesInChunks: async (user, files, folderId, onProgress, onFinish) => {
    files.forEach((file) => {
      fileService.uploadInChunks(user, file, folderId, onProgress, onFinish);
    });
  },

  uploadInChunks: async (user, file, folderId, onProgress, onFinish) => {
    const chunkSize = 2 * 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    const blockIds = [];
    const fileKey = ObjectId().toString();

    const readChunk = (chunkIndex, onDataReady) => {
      const from = chunkIndex * chunkSize;
      const to = Math.min(from + chunkSize, file.size);
      const reader = new FileReader();
      reader.onload = onDataReady;
      reader.readAsDataURL(file.slice(from, to));
    };

    const uploadChunk = (chunkIndex) =>
      new Promise((resolve, reject) => {
        readChunk(chunkIndex, async (readEvent) => {
          try {
            const uploadChunkRes = await fileRequests.file.admin.uploadChunk(
              user,
              readEvent.target.result,
              {
                fileKey,
                fileName: file.name,
                fileSize: file.size,
                chunkIndex,
                totalChunks,
              }
            );

            if (uploadChunkRes.status !== 200)
              throw new Error(
                uploadChunkRes?.data?.message ||
                  "No error info for uploadChunk error =("
              );

            const { blockId } = uploadChunkRes.data;
            blockIds.push(blockId);
            resolve({
              blockId,
              chunkIndex,
              totalChunks,
            });
          } catch (err) {
            reject(err);
          }
        });
      });

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      await uploadChunk(chunkIndex);
      const uploadedChunks = chunkIndex + 1;
      onProgress(file, {
        uploadedChunks,
        totalChunks,
        percentage:
          totalChunks > 0
            ? Math.round((100 * uploadedChunks) / totalChunks)
            : 0,
      });
    }

    const finishUploadRes = await fileRequests.file.admin.finishUpload(user, {
      fileName: file.name,
      folderId,
      fileSize: file.size,
      blockIds,
      contentType: file.type,
      fileKey,
    });
    if (finishUploadRes.status !== 200)
      throw new Error(
        finishUploadRes?.data?.message || "No error info for finishUploadRes =("
      );

    onProgress(file, {
      uploadedChunks: totalChunks,
      totalChunks,
      percentage: 100,
    });

    onFinish(finishUploadRes.data);
  },

  deleteFile: async (user, id) => {
    const deleteRes = await fileRequests.file.admin.delete(user, id);

    if (deleteRes.status !== 200)
      throw new Error(`[${deleteRes?.status}] ${deleteRes?.data?.message}`);

    return await deleteRes.data;
  },

  createFolder: async (user, folderName, parentFolder) => {
    const createRes = await fileRequests.folders.admin.create(
      user,
      folderName,
      parentFolder?.id || null
    );

    if (createRes.status !== 200)
      throw new Error(`[${createRes?.status}] ${createRes?.data?.message}`);

    return await createRes.data;
  },

  renameFolder: async (user, id, newName) => {
    const renameRes = await fileRequests.folders.admin.update(user, id, {
      name: newName,
    });

    if (renameRes.status !== 200)
      throw new Error(`[${renameRes?.status}] ${renameRes?.data?.message}`);

    return await renameRes.data;
  },

  deleteFolder: async (user, id) => {
    const deleteRes = await fileRequests.folders.admin.delete(user, id);

    if (deleteRes.status !== 200)
      throw new Error(`[${deleteRes?.status}] ${deleteRes?.data?.message}`);

    return await deleteRes.data;
  },
};

export { fileService, fileRequests };
