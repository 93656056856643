import { useState, useEffect } from 'react';
import Icon from '../../../components/Icon';
import EditableText from '../../../components/EditableText';
import SelectOptions from '../../../components/SelectOptions';
import InputDate from '../../../components/InputDate';
import Folders from '../../../components/Folders';
import { calendarService } from '../../../services/calendarEntryService';

const EditEntry = ({
  entry,
  courses,
  user,
  getResponseData,
  onUpdate,
  onClose,
  courseById,
  subjectById
}) => {
  const [updating, setUpdating] = useState(false)
  
  const updateEntry = async (newAttrs) => {
    try {
      setUpdating(true)

      const {
        fileId,
        courseId,
        topicId,
        category,
        startDate,
        endDate,
        name,
        description,
        videoCallLink,
        videoCallPass
      } = newAttrs

      const updateRes = await calendarService.admin.update(
        user,
        entry.id,
        {
          fileId: fileId === undefined ? entry.fileId : fileId,
          courseId: courseId === undefined ? entry.courseId : courseId,
          topicId: topicId === undefined ? entry.topicId : topicId,
          category: category === undefined ? entry.category : category,
          startDate: startDate === undefined ? entry.startDate : startDate,
          endDate: endDate === undefined ? entry.endDate : endDate,
          name: name === undefined ? entry.name : name,
          description: description === undefined ? entry.description : description,
          videoCallLink: videoCallLink === undefined ? entry.videoCallLink : videoCallLink,
          videoCallPass: videoCallPass === undefined ? entry.videoCallPass : videoCallPass,
        }
      )
      setUpdating(false)

      const newEntry = getResponseData(
        updateRes,
        "Erro ao atualizar entrada de calendário"
      )

      if (newEntry)
        onUpdate(newEntry)
    } catch (e) {
      alert('Erro ao atualizar entrada de calendário', e.message)
    }
  }

  return (
    <div
      onClick={(e) => {
        onClose()
        e.stopPropagation()
      }}
      style={{
        display: entry ? 'initial' : 'none',
        position: 'fixed',
        top: '3rem',
        left: 0,
        width: '100%',
        zIndex: 9999,
        height: 'calc(100% - 3rem)',
        backgroundColor: '#000000AA'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          maxHeight: '100vh',
          maxWidth: '100vw',
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className='apply-box-shadow'
          style={{
            backgroundColor: 'white',
            marginTop: '1.5rem',
            padding: '1.5rem 2.5rem 3.5rem 2.5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            minWidth: '30rem',
            maxHeight: '75vh',
            overflow: 'auto'
          }}
        >
          <h3 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            Editar dados da entrada de calendário
            <div
              onClick={() => onClose()}
              className='apply-hover'
              style={{padding: '0.5rem'}}
            >
              <Icon name='close'/>
            </div>
          </h3>
          <SelectOptions
            key={`file-${entry?.id}-course-association`}
            selected={ entry?.courseId }
            includeBlank='Sem curso associado...'
            options={ courses?.map(c => ({
              label: c.name,
              value: c.id
            }))}
            onChange={(event) => updateEntry({
              courseId: parseInt(event.target.value) || null,
            })}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          />
          <SelectOptions
            key={`entry-${entry?.id}-subject-association`}
            selected={ entry?.topicId }
            includeBlank='Sem disciplina associada...'
            options={ courseById[entry?.courseId]?.subjects?.map(subject => ({
              label: subject.name,
              value: subject.id
            })) || []}
            onChange={(event) => updateEntry({
              topicId: event.target.value,
            })}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          />

          <InputDate
            selected={entry?.startDate ? new Date(entry.startDate) : null}
            onChange={(startDate) => updateEntry({startDate})}
          />
          <EditableText
            label='Nome'
            value={entry?.name}
            onChange={name => updateEntry({name})}
          />
          <EditableText
            label='Descrição'
            textarea
            value={entry?.description}
            onChange={description => updateEntry({description})}
          />


          <div>
            <div className='small-text' style={{margin: '0.5rem 0'}}>
              Vídeo
            </div>
            <Folders
              user={user}
              selectOneFile
              selectedOneFile={entry?.file}
              onSelectFile={file => updateEntry({fileId: file?.id || null})}
            />
          </div>

          <EditableText
            label='Link'
            value={entry?.videoCallLink}
            onChange={videoCallLink => updateEntry({videoCallLink})}
          />
          <EditableText
            label='Senha'
            value={entry?.videoCallPass}
            onChange={videoCallPass => updateEntry({videoCallPass})}
          />
        </div>
      </div>
    </div>
  )
}

export default EditEntry
