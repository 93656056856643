import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from '../../components/Header'
import CourseHeader from '../../components/CourseHeader';
import Card from '../../components/Card'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import AssessmentAttempt from './AssessmentAttempt'
import useCourseState from "../../hooks/useCourseState";
import useTopicState from "../../hooks/useTopicState";
import { assessmentService } from "../../services/assessmentService"
import AppHelpers from '../../AppHelpers'

const UserAssessment = ({loadUser, getResponseData}) => {
  const { courseId, topicId, assessmentId } = useParams();
  const navigate = useNavigate();
  const user = loadUser()
  const [course] = useCourseState(user, courseId, getResponseData);
  const [topic] = useTopicState(user, courseId, topicId, getResponseData);
  const [assessment, setAssessment] = useState(null);

  useEffect(() => {
    const load = async () => {
      if (user) {
        const assessmentRes = await assessmentService.common.getAssessment(
          user,
          courseId,
          topicId,
          assessmentId
        )

        const data = getResponseData(assessmentRes)
        if (data)
          setAssessment(data)
        else
          setAssessment([])
      }
    }

    load()
  }, [])

  const attemptNumber = (assessment?.attempts?.length || 0) + 1

  return [
    <Header key='assessments-index-header-key' user={ user } course={ course } subject={topic}/>,
    <CourseHeader key='assessments-index-course-header-key' title="Avaliações" user={user} course={course} subject={topic}/>,
    <div key='assessment-page' className='app-body'>
      {
        user && assessment ? (
          <div>
            <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
              <Button
                onClick={ () => navigate(-1) }
                leftIcon='chevron-left'
              />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
                  <div>{ assessment.name }</div>
                  <div>{ assessment?.topic?.name }</div>
                </div>
                <div className='small-text light-text' style={{ fontWeight: 'normal' }}>
                  disponível entre
                  &nbsp;
                  { AppHelpers.date.format(assessment?.startDate) }
                  &nbsp;
                  e
                  &nbsp;
                  { AppHelpers.date.format(assessment?.endDate) }
                </div>
              </div>
            </h2>
            <Card>
              <AssessmentAttempt
                assessment={ assessment }
                user={ user }
                questions={ assessment.questions || [] }
                getResponseData={ getResponseData }
              />
            </Card>
          </div>
        ) : (
          <Loading type='elements'/>
        )
      }
    </div>
  ]
}

export default UserAssessment
