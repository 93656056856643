import { useLocation, useParams, Link } from "react-router-dom";
import Icon from './Icon'
import Loading from './Loading'
import HeaderBreadcrumbItem from './HeaderBreadcrumbItem'
import helpers from '../AppHelpers'

const HeaderBreadcrumb = ({ user, course, topic }) => {
  const {courseId} = useParams()
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)

  const defaultStyle = {
    backgroundColor: 'black',
    color: 'white !important',
  }

  const previousTopicId = searchParams.get('prevId') || findPreviousTopicId(topic)
  const nextTopic = findNextTopic(topic)
  const navLinksStyle = {
    display: 'flex',
    gap: '0.15rem',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.5rem 0'
  }

  return (
    <div>
      <div style={{
        backgroundColor: '#333',
        color: 'white !important',
        display: 'flex',
        fontSize: '0.85rem',
        alignItems: 'center',
        minHeight: '3.25rem',
        justifyContent: 'space-between',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          { !topic && <Loading/> }
          {
            topic && topic?.ancestors.map(t => [
              t.hierarchySize !== 1 && (
                <Icon
                  className='hide-small-screen'
                  key={ `separator-${ t.id }` }
                  name='chevron-right'
                />
              ),
              <HeaderBreadcrumbItem
                className='hide-small-screen'
                key={ `item-${ t.id }` }
                course={ course }
                topic={ t }
              />,
            ]).flat()
          }

          {
            topic && topic?.ancestors.length > 0 && (
              <Icon
                key={ `separator-${ topic.id }` }
                name='chevron-right'
                className='hide-small-screen'
              />
            )
          }

          {
            topic && <HeaderBreadcrumbItem
              key={ `item-${ topic.id }` }
              className='hide-small-screen'
              topic={ topic }
            />
          }

          {
            topic && <HeaderBreadcrumbItem
              key={ `item-mobile-${ topic.id }` }
              className='hide-big-screen'
              topic={ topic }
              openPath={ topic.hierarchyIds.slice(0, -1) }
              isMobile
            />
          }

          {
            topic && topic?.progress?.todo > 1 && (
              <HeaderBreadcrumbItem
                key={ `item-children` }
                className='hide-small-screen'
                topic={ null }
              />
            )
          }
        </div>
        <div style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          color: '#DDD !important',
          marginRight: '1.2rem'
        }}>
          {
            !topic ? (
              <Loading/>
            ) : [
              previousTopicId ? (
                <Link
                  key='link-nav-back'
                  style={ navLinksStyle }
                  to={ helpers.path.student.topic(courseId, previousTopicId) }
                >
                  <Icon
                    style={{ fontSize: '1.2rem' }}
                    name='double-arrow-left'
                  />
                  anterior
                </Link>
              ) : (
                <div
                  key='div-nav-back'
                  style={ Object.assign({}, navLinksStyle, {
                    cursor: 'default',
                    color: '#888 !important'
                  }) }
                >
                  <Icon
                    style={{ fontSize: '1.2rem' }}
                    name='double-arrow-left'
                  />
                  anterior
                </div>
              ),

              nextTopic ? (
                <Link
                  key='link-nav-forward'
                  style={ navLinksStyle }
                  to={helpers.path.student.topic(
                    courseId,
                    nextTopic.id,
                    topic.id
                  )}
                >
                  próximo
                  <Icon
                    style={{ fontSize: '1.2rem' }}
                    name='double-arrow-right'
                  />
                </Link>
              ) : (
                <div
                  key='div-nav-right'
                  style={ Object.assign({}, navLinksStyle, {
                    cursor: 'default',
                    color: '#888 !important'
                  }) }
                >
                  próximo
                  <Icon
                    style={{ fontSize: '1.2rem' }}
                    name='double-arrow-right'
                  />
                </div>
              )
            ]
          }
        </div>
      </div>
    </div>
  )
}

const findPreviousTopicId = (topic) => {
  if (!topic)
    return null

  const { siblings, ancestors } = topic || {}
  let previousTopic = siblings?.find(t => t.number === (topic.number - 1))
  if (!previousTopic) {
    if ((ancestors || []).length === 0)
      return null
  }

  previousTopic = (ancestors || []).length > 0 ? ancestors[ancestors.length - 1] : null

  return previousTopic?.id || null
}

const findNextTopic = (topic) => {
  if (!topic)
    return null

  let nextTopic = topic.children[0]

  if (!nextTopic)
    nextTopic = topic.siblings.find(t => t.number === (topic.number + 1))

  if (!nextTopic)
    for (let i = topic.ancestors.length - 1; i >= 0; i--) {
      if (!nextTopic) {
        nextTopic = topic.ancestors[i].siblings?.find(t => (
          t.number === (topic.ancestors[i].number + 1)
        ))
      }
    }

  return nextTopic
}

export default HeaderBreadcrumb
