import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../../components/Header'
import { courseService } from '../../../services/courseService';
import { assessmentService } from '../../../services/assessmentService';
import CreateOrUpdate from './CreateOrUpdate'

const AdminAssessmentsEdit = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const navigate = useNavigate()
  const { assessmentId } = useParams()
  const [courses, setCourses] = useState(null)
  const [assessment, setAssessment] = useState(null)
  const [savingAssessment, setSavingAssessment] = useState(false)

  useEffect(() => {
    const load = async () => {
      if (user) {
        const coursesRes = await courseService.common.getCourses(user)
        const data1 = getResponseData(coursesRes)
        if (data1)
          setCourses(data1)

        const assessmentRes = await assessmentService.admin.show(user, assessmentId)
        const data2 = getResponseData(assessmentRes)
        if (data2)
          setAssessment(data2)
      }
    }

    load()
  }, [])

  const updateAssessment = async (assessmentAttrs) => {
    setSavingAssessment(true)
    const createRes = await assessmentService.admin.update(user, assessmentAttrs)
    const data = getResponseData(createRes)
    setSavingAssessment(false)
  }
  
  return [
    <Header
      key='admin-assessments-edit-header-key'
      admin="Avaliações"
      user={user}
    />,
    <div
      className='app-body'
      key='admin-assessments-edit-body-key'
    >
      <CreateOrUpdate
        user={ user }
        courses={ courses }
        assessment={ assessment }
        onFinish={ assessmentAttrs => {
          updateAssessment(assessmentAttrs)
          navigate('/admin/avaliacoes')
        }}
        loading={ savingAssessment }
        getResponseData={ getResponseData }
      />
    </div>
  ]
}

export default AdminAssessmentsEdit
