import { HOST, baseApiService } from "./baseApiService";

const taskService = {
  host: HOST,
  paths: {
    getTaskByIdPath: (courseId, taskId) => {
      return `/courses/${courseId}/tasks/${taskId}`;
    },
    admin: {
      tasksPath: () => {
        return `/admin/tasks`;
      },
      createTaskPath: () => {
        return `/admin/tasks/create`;
      },
      updateTaskPath: (taskId) => {
        return `/admin/tasks/update/${taskId}`;
      },
      deleteTaskPath: (taskId) => {
        return `/admin/tasks/delete/${taskId}`;
      },
      findTask: (taskId) => {
        return `/admin/tasks/${taskId}`;
      },
    },
  },

  common: {
    getTaskById: async (user, courseId, taskId) => {
      const path = taskService.paths.getTaskByIdPath(courseId, taskId);
      return await taskService.get(user, path);
    },
  },

  admin: {
    index: async (user) => {
      const path = taskService.paths.admin.tasksPath();

      return await baseApiService.get(user, path);
    },
    find: async (user, taskId) => {
      const path = taskService.paths.admin.findTask(taskId);

      return await baseApiService.get(user, path);
    },
    create: async (
      user,
      name,
      description,
      fileId,
      courseId,
      topicId,
      startDate,
      endDate
    ) => {
      const path = taskService.paths.admin.createTaskPath();
      return await baseApiService.post(user, path, {
        name,
        description,
        fileId,
        courseId,
        topicId,
        startDate,
        endDate,
      });
    },
    update: async (
      user,
      name,
      description,
      fileId,
      courseId,
      topicId,
      startDate,
      endDate,
      taskId
    ) => {
      const path = taskService.paths.admin.updateTaskPath(taskId);
      return await baseApiService.put(user, path, {
        name,
        description,
        fileId,
        courseId,
        topicId,
        startDate,
        endDate,
      });
    },
    delete: async (user, taskId) => {
      const path = taskService.paths.admin.deleteTaskPath(taskId);

      return await baseApiService.delete(user, path);
    },
  },
};

export {
  taskService
}