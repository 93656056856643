import styled from 'styled-components';

export const Main = styled.div`
    display: flex;
    flex-direction: row;
    width: 60vw;
    height: 70vh;
    margin: 3rem;
    gap: 1%;

    @media(max-width: 750px) {
        width: 100vw;
    }

`;

export const ProfileNav = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;  
    background-color: white;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 5px 0px #444;

`;

export const DisplayName = styled.p`
    font-size: 1rem;
    font-weight: 900;
    padding: 0.2rem;

`;

export const ProfileContent = styled.div`
    display: ${(props) => props.show ? 'none' : 'flex'};
    flex-direction: column;
    height: 100%;
    width: 65%;  
    background-color: white;
    padding: 0 2% 0 2%;
    align-items: center;
    box-shadow: 1px 1px 5px 0px #444;

`;

export const InfoField = styled.div`
    display: flex;
    flex-direction: column;
    height: 15%;
    align-items: center;
    background-color: white;
    gap: 0.2rem;
    font-size: 1.2rem;
    color: #3D3D3D !important;
    width: 90%;

`;

export const Infotext = styled.p`
    color: black !important;
    font-size: 0.9rem;
    margin: 0;
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    height: 10%;
    width: 100%;  
    background-color: ${(props) => props.tabIndex === props.indexStatus ? '#D3D3D3' : 'white'};;
    justify-content: center;
    align-items: center;
    font-size: 0.80rem;
    font-size: 1rem;

    &:hover {
        background-color: #808080;
    }

`;