import { useEffect, useState } from "react"
import { calendarService } from "../services/calendarEntryService";
import { useParams} from "react-router-dom";
import Loading from "./Loading";
import Icon from "./Icon";
import TaskModal from "./TaskModal";

const CalendarCard = ({ user, minDate, maxDate, getResponseData }) => {
  const { courseId, id } = useParams();
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null)

  useEffect(() => {
    const loadCalendarEntries = async () => {
      setLoading(true)
      const entriesRes = await calendarService.common.getCalendarEntries(
        user,
        courseId,
        id,
        Object.assign(
          minDate ? { minDate } : {},
          maxDate ? { maxDate } : {}
        )
      )
      setLoading(false)

      const data = getResponseData(
        entriesRes,
        "Erro ao pegar entradas do calendário"
      )

      if (data)
        setEntries(data);
    }

    loadCalendarEntries()
  }, [minDate, maxDate])

  const openModal = (task) => {
    setSelectedTask(task);

    setShow(true);
  }

  const renderCalendarBoxLinks = (calendarEntry) => {

    return calendarEntry.map((entry, index) => {

      const weekdays = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
      const monthName = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
      const calendarDate = new Date(entry.startDate);
      const endCalendarDate = new Date(entry.endDate);
      const actualDate = new Date();

      function addZero(i) {
        if (i < 10) { i = "0" + i }
        return i;
      }

      const entryWeek = weekdays[calendarDate.getDay()];
      const entryMonth = monthName[calendarDate.getMonth()];
      const entryDay = calendarDate.getDate();
      const entryYear = calendarDate.getFullYear();
      const entryHour = calendarDate.getHours();
      const entryminute = addZero(calendarDate.getMinutes());

      const endEntryHour = endCalendarDate.getHours();
      const endEntryMinute = addZero(endCalendarDate.getMinutes());
      const endEntryMonth = monthName[endCalendarDate.getMonth()];
      const endEntryDay = endCalendarDate.getDate();
      const endEntryYear = endCalendarDate.getFullYear();

      return (
        (entry?.category === 'classroom') ? ( // CLASSROOM CARD
          <div
            key={index}
          >
            <div style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "#1D689F !important",
              margin: '2rem 0 0.25rem 0',
            }}
            >
              {entryDay}/{entryMonth}/{entryYear} - {entryWeek}
            </div>

            <div className='apply-flex-column-on-small-devices' style={{
              display: "flex",
              gap: '0.5rem',
            }}>
              <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: "row",
                alignSelf: 'stretch',
                padding: '0.5rem 2rem',
                minHeight: "5rem",
                background: "#D9D9D9",
                alignItems: 'center',
                justifyContent: 'stretch',
                gap: '0.5rem',
              }}>
                <Icon name='reader' style={{ fontSize: '4rem' }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className='small-text light-text'>
                    {entry.topic.name}
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {entry.name}
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch', gap: '0.5rem' }}>
                <div style={styles.smallBox}>
                  <div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
                    <Icon name='schedule' style={{ fontSize: '1.2rem' }} />
                    <div className='regular-text bold-text'>
                      {entryHour}:{entryminute}
                    </div>
                  </div>
                </div>

                <div style={styles.smallBox}>
                  <div>
                    <div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
                      <Icon name='link' style={{ fontSize: '1.5rem' }} />
                      {
                        entry.videoCallLink ? (
                          <a href={entry.videoCallLink} style={styles.textLink} target="_blank">
                            Aula no Zoom
                          </a>
                        ) : (
                          <div className='small-text light-text'>
                            link indisponível
                          </div>
                        )
                      }
                    </div>

                    <div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
                      <Icon name='key' style={{ fontSize: '1.5rem' }} />
                      {
                        entry.videoCallPass ? (
                          <div className='small-text'>
                            {entry.videoCallPass}
                          </div>
                        ) : (
                          <div className='small-text light-text'>
                            sem senha
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        ) : ( // ACTIVITY CARD
          <div
            key={index}
          >
            <div style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "#1D689F !important",
              margin: '2rem 0 0.25rem 0',
            }}
            >
              Atividade: De {entryDay}/{entryMonth}/{entryYear} - até {endEntryDay}/{endEntryMonth}/{endEntryYear}
            </div>

            <div className='apply-flex-column-on-small-devices' style={{
              display: "flex",
              gap: '0.5rem',
            }}>
              <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: "row",
                alignSelf: 'stretch',
                padding: '0.5rem 2rem',
                minHeight: "7rem",
                background: "#D9D9D9",
                alignItems: 'center',
                justifyContent: 'stretch',
                gap: '0.5rem',
              }}>
                <Icon name='assigment' style={{ fontSize: '4rem' }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className='small-text light-text'>
                    {entry.topic.name}
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {entry.name}
                  </div>
                </div>
                <div
                  style={{
                    display: `flex`,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: `${(actualDate < endCalendarDate) ? (((endCalendarDate.getTime() - actualDate.getTime()) < 86400000) ? '#d6c226' : '#75975e') : '#ff6c70'}`,
                    padding: '0.25rem',
                    borderRadius: '5px',
                    color: 'white !important',
                    justifySelf: 'flex-end',
                    marginLeft: '3rem'
                  }}
                >
                  {(actualDate < endCalendarDate) ? 'disponível' : 'indisponível'}
                </div>
              </div>
              <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch', gap: '0.5rem' }}>
                <div style={styles.smallBox}>
                  <div style={{ display: 'column', gap: '0.75rem', alignItems: 'center' }}>
                    <div style={{ marginBottom: '0.5rem' }}>
                      Disponível:
                    </div>
                    <div className='regular-text bold-text' style={{ display: 'flex', gap: '0.5rem' }}>
                      <Icon name='schedule' style={{ fontSize: '1.2rem' }} />
                      {entryHour}:{entryminute}
                    </div>
                  </div>
                </div>
                <div style={styles.smallBox}>
                  <div style={{ display: 'column', gap: '0.75rem', alignItems: 'center' }}>
                    <div style={{ marginBottom: '0.5rem' }}>
                      Finaliza:
                    </div>
                    <div className='regular-text bold-text' style={{ display: 'flex', gap: '0.5rem' }}>
                      <Icon name='schedule' style={{ fontSize: '1.2rem' }} />
                      {endEntryHour}:{endEntryMinute}
                    </div>
                  </div>
                </div>
                <div style={styles.smallBox}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '0.75rem',
                      alignItems: 'center',
                      padding: '1rem',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => { openModal(entry) }}
                  >
                    ver descrição completa
                  </div>
                </div>
              </div>
            </div>
          </div >
        )
      )
    })

  }

  return loading ? <Loading type='elements-with-cards' /> : (
    <div>
      {
        entries?.length === 0 ? (
          <div className='small-text light-text'>
            Nenhuma entrada de calendário encontrada
          </div>
        ) : (
          renderCalendarBoxLinks(entries)
        )
      }
      {show && <TaskModal
        task={selectedTask}
        onClose={() => setShow(false)}
        user={user}
      />}
    </div>
  )
}

const styles = {
  textLink: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    textAlign: "center",
    textDecorationLine: "underline",
    color: "blue !important",
    margin: "0",
    cursor: "pointer"
  },
  smallBox: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    minHeight: '5rem',
    alignItems: "center",
    padding: '0.5rem',
    background: "#D9D9D9",
  },
}

export default CalendarCard
