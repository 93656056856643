import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import gradesService from "../../services/gradesService";
import TableForFinalGrades from "../../components/TableForFinalGrades";

const UserFinalScores = ({ loadUser, getResponseData }) => {
  const user = loadUser();

  const { courseName } = useParams();

  const [assessment, setAssessment] = useState(null);

  const userUid = user.firebaseUid;

  useEffect(() => {
    const load = async () => {
      if (user) {
        const assessmentRes =
          await gradesService.common.getAllGradesByCourseAndUser(
            user,
            userUid,
            courseName
          );

        const data = getResponseData(assessmentRes);

        if (data) {
          setAssessment(data);
        } else {
          setAssessment([]);
        }
      }
    };
    load();
  }, []); 

  return [
    <Header key="scores-index-header-key" user={user} />,
    user && (
      <div key="scores-index-page-key" className="app-body">
        <div
          className="apply-box-shadow"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "2rem",
            margin: "2rem 0",
            textAlign: "center",
          }}
        >
          <div>
            <h1 className="mt-0 fs-4">Notas Finais</h1>
            {assessment ? <TableForFinalGrades course={assessment} /> : ""}
          </div>
        </div>
      </div>
    ),
  ];
};

export default UserFinalScores;
