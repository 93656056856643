import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR)

const InputDate = ({selected, onChange, style, label}) => {
  const [date, setDate] = useState(selected)

  useEffect(() => {
    setDate(selected)
  }, [selected])

  return (
    <div style={ style || {}}>
      {
        label && (
          <div className='small-text light-text' style={{ paddingBottom: '0.75rem' }}>
            { label }
          </div>
        )
      }
      <DatePicker
        selected={date}
        locale="pt-BR"
        showTimeSelect
        timeFormat="p"
        timeIntervals={15}
        dateFormat="Pp"
        onSelect={(e) => true}
        shouldCloseOnSelect={false}
        isClearable
        onChange={newDate => {
          setDate(newDate)
          onChange(newDate)
        }}
      />
    </div>
  )
}

export default InputDate