import { Link } from "react-router-dom";
import Icon from "./Icon";

const PageBoxLink = ({ title, iconName, to }) => {

    return (
        <Link to={to}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    cursor: "pointer",
                    margin: "0.5rem"
                }}
            >
                <div className="apply-box-shadow"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "10em",
                        height: "10em",
                        backgroundColor: "white",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Icon
                        name={iconName}
                        style={{
                            fontSize: "5rem"
                        }}
                    />
                    <p
                    style={{
                        color: "#808080 !important",
                        width: "80%",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        margin: "0"
                    }}
                >
                    {title}
                </p>
                </div >

            </div>
        </Link>
    )
}

export default PageBoxLink
