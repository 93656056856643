import axios from "axios";

const HOST =
  process.env.NODE_ENV === "production"
    ? "https://techschoolback.idp.edu.br"
    : "http://localhost:3000";

const pathWithQueryString = (path, params) => {
  const queryStr = params ? new URLSearchParams(params).toString() : "";
  return `${path}?${queryStr}`;
};

const performRequest = async ({ 
  user,
  method,
  path,
  data,
  params,
  headers,
}) => {
  if (!path.startsWith("/")) throw new Error("path must starts with '/'");

  const config = {
    method,
    url: `${HOST}${path}`,
    headers: Object.assign(
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      headers
    ),
  };
  if (data) config["data"] = data;

  if (params) config["params"] = params;

  if (user) {
    config.headers["Authorization"] = `Bearer ${user.firebaseAccessToken}`;
  }

  try {
    const result = await axios(config);
    return result;
  } catch (e) {
    return e.response;
  }
};

const baseApiService = {
  get: async (user, path, params) => await performRequest({
    method: 'get',
    user,
    path,
    params
  }),
  
  put: async (user, path, data) => await performRequest({
    method: 'put',
    user,
    path,
    data,
  }),
  
  patch: async (user, path, data) => await performRequest({
    method: 'patch',
    user,
    path,
    data,
  }),
  
  post: async (user, path, data, headers) => await performRequest({
    method: 'post',
    user,
    path,
    data,
    headers
  }),
  
  delete: async (user, path, data) => await performRequest({
    method: 'delete',
    user,
    path,
    data
  })
}

export { HOST, baseApiService, pathWithQueryString };
