import { Sync } from '@mui/icons-material';

const Loading = ({ type, style, message }) => {
  if (type === 'elements')
    return (
      <div key='loading-elements-key' className='app-body'>
        <div className='loading'>
          <p style={{ margin: '1rem 0', padding: '1rem' }}>loading...</p>

          <p style={{ margin: '1rem 25% 1rem 0', padding: '1rem' }}>loading...</p>
          <div style={{ margin: '0.5rem 50% 1rem 2rem' }}>loading...</div>
          <div style={{ margin: '0.5rem 45% 1rem 2rem' }}>loading...</div>
          <div style={{ margin: '0.5rem 55% 2rem 2rem' }}>loading...</div>

          <p style={{ margin: '1rem 35% 1rem 0', padding: '1rem' }}>loading...</p>
          <div style={{ margin: '0.5rem 30% 1rem 2rem' }}>loading...</div>
          <div style={{ margin: '0.5rem 60% 1rem 2rem' }}>loading...</div>
          <div style={{ margin: '0.5rem 40% 1rem 2rem' }}>loading...</div>
        </div>
      </div>
    )

  if (type === 'elements-with-cards')
    return (
      <div key='loading-elements-with-cards-key' className='app-body'>
        <div className='loading'>
          <p style={{ margin: '1rem 0', padding: '1rem' }}>loading...</p>

          <div style={{ margin: '2rem 35% 2rem 0rem' }}>loading...</div>
          <div style={{ padding: '10rem' }}>
          </div>

          <div style={{ margin: '5rem 35% 2rem 0rem' }}>loading...</div>
          <div style={{ padding: '10rem' }}>
          </div>
        </div>
      </div>
    )

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Sync
        className='rotating'
        style={style || {}}
      />
      {
        message && (
          <div className='small-text light-text'>
            {message}
          </div>
        )
      }
    </div>
  )
}

export default Loading
