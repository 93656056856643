import { useState} from 'react';
import { useNavigate } from "react-router-dom";
import SelectOptions from './SelectOptions';
import Loading from './Loading';
import SelectLinks from './SelectLinks';
import { fileService, fileRequests } from '../services/fileService';
import useUserState from '../hooks/useUserState'
import Icon from './Icon';
import Input from './Input';

const File = ({ file, courses, user, onDelete, selectOneFile, onSelectFile }) => {
  const navigate = useNavigate()
  const [, , , , , , goToLogin] = useUserState();
  const [updatingFile, setUpdatingFile] = useState(false);
  const [file_, setFile_] = useState(file);
  const [editingName, setEditingName] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);
  const [newName, setNewName] = useState(file.name);

  const deleteFile = async () => {
    try {
      await fileService.deleteFile(user, file.id)
    } catch (e) {
      alert('Erro ao deletar arquivo', e.message)
    }
  }

  const course = courses?.find(c => c.id === file_.courseId) || null
  const subject = course?.subjects?.find(sub => sub.id === file_.topicId) || null
  const fileCategories = {
    recordedClass: {
      value: 'recordedClass',
      ptBR: 'aula gravada'
    },
    supportMaterial: {
      value: 'supportMaterial',
      ptBR: 'material de apoio'
    }
  }

  const updateFile = async ({ courseId, topicId, category }) => {
    setUpdatingFile(true)
    try {
      const updateRes = await fileRequests.file.admin.update(user, file_.id, {
        courseId: courseId || null,
        topicId: topicId || null,
        category: category || null
      })
      if (updateRes.status === 200)
        setFile_(updateRes.data)
      else if (updateRes.status === 401)
        goToLogin()
      else if (updateRes.status === 403)
        navigate('/acesso-negado')
    } catch (e) {
      alert('Erro na atualização de arquivo', e.message)
    }
    setUpdatingFile(false)
  }

  return (
    <a
      href={file_.link}
      target="_blank"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.5rem',
        padding: '0.75rem',
        border: 'solid 1px #EEE',
        backgroundColor: '#f9f9f9'
      }}
      className='apply-hover'
      onClick={(e) => {
        if (selectOneFile) {
          e.preventDefault()
          onSelectFile(file)
        }
      }}
    >
      <div
        style={{
          gap: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon name='file' style={{ color: '#AAA !important' }}/>
        <div
          style={{
            gap: '0.25rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            {
              editingName ? (
                <div onClick={ event => event.preventDefault() }>
                  <Input
                    onEnter={(e) => {
                      e.preventDefault()
                      setEditingName(false)
                      updateFile()
                    }}
                    onEsc={() => setEditingName(false)}
                    onFocus={(event) => event.target.select()}
                    autoFocus
                    value={ newName }
                    onChange={ event => {
                      setNewName(event.target.value)
                    }}
                  />
                </div>
              ) : (
                newName
              )
            }
          </div>
          <div className='small-text light-text italic-text'>
            {file_.type}
          </div>
        </div>
      </div>

      <div
        style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
      >
        {
          !selectOneFile && courses && !editingName && !deletingFile && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              {
                updatingFile ? (
                  <Loading style={{ padding: '1rem' }}/>
                ) : [
                  <SelectOptions
                    key={`file-${file_.id}-course-association`}
                    selected={ course?.id }
                    includeBlank='Sem curso associado...'
                    options={ courses.map(c => ({
                      label: c.name,
                      value: c.id
                    }))}
                    onChange={(event) => {
                      updateFile({
                        courseId: parseInt(event.target.value) || null,
                        topicId: file_.topicId,
                        category: file_.category
                      })
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  />,
                  <SelectOptions
                    key={`file-${file_.id}-subject-association`}
                    selected={ subject?.id }
                    disabled={ !course }
                    includeBlank='Sem disciplina associada...'
                    options={ course?.subjects?.map(subject => ({
                      label: subject.name,
                      value: subject.id
                    })) || []}
                    onChange={(event) => {
                      updateFile({
                        courseId: file_.courseId,
                        topicId: event.target.value,
                        category: file_.category
                      })
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  />,
                  <SelectOptions
                    key={`file-${file_.id}-category`}
                    selected={ file_.category }
                    disabled={ !course }
                    includeBlank='Sem categoria definida...'
                    options={ Object.entries(fileCategories).map(([categorySym, categoryInfo]) => ({
                      label: categoryInfo.ptBR,
                      value: categoryInfo.value
                    })) || []}
                    onChange={async (event) => {
                      updateFile({
                        courseId: file_.courseId,
                        topicId: file_.topicId,
                        category: event.target.value
                      })
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  />
                ]
              }
            </div>
          )
        }

        {
          !selectOneFile && (
            <div
              style={{position: 'relative', display: 'flex', gap: '0.5rem'}}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {
                editingName && [
                  <div
                    key={`save-file-${file.id}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setEditingName(false)
                      updateFile()
                    }}
                    className='apply-box-shadow'
                    style={{
                      padding: '0.25rem 0.5rem',
                      cursor: 'pointer',
                      color: 'white !important',
                      backgroundColor: 'darkGreen',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Icon name='done'/>
                  </div>,

                  <div
                    key={`cancel-file-saving-${file.id}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setEditingName(false)
                    }}
                    className='apply-box-shadow'
                    style={{
                      padding: '0.25rem 0.5rem',
                      cursor: 'pointer',
                      color: 'white !important',
                      backgroundColor: 'darkRed',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Icon name='close'/>
                  </div>
                ]
              }
              <SelectLinks
                conditionToOpen={ () => !editingName }
                links={[
                  {
                    name: 'editar',
                    icon: { name: 'edit' },
                    onClick: (e) => { setEditingName(true) }
                  },
                  {
                    name: 'apagar',
                    icon: { name: 'close', color: 'darkRed !important' },
                    onClick: async (e) => {
                      setDeletingFile(true)
                      await deleteFile()
                      onDelete()
                      setDeletingFile(false)
                    }
                  }
                ]}
              />
            </div>
          )
        }
      </div>
    </a>
  )
}

export default File
