import { useState } from 'react';
import TableForObjects from '../../../components/TableForObjects'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import AppHelpers from '../../../AppHelpers'

const UsersList = ({ user, users, actions }) => {
  const [usersSearch, setUsersSearch] = useState('')
  const usersToShow = users?.filter(user => {
    const { withoutAccentsAndSpecialChars } = AppHelpers.str
    const userStr = withoutAccentsAndSpecialChars(`${ user.email }-${ user.name }-${ user.ra }`).trim()
    const search = withoutAccentsAndSpecialChars(usersSearch).trim()
    return userStr.indexOf(search) >= 0
  }) || []

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column', alignItems: 'stretch' }}>
        <Input
          label='Pesquise por nome, email ou matrícula (RA)'
          onEnter={ (event) => setUsersSearch(event.target.value) }
          onFocus={ (event) => event.target.select() }
          value={ usersSearch }
          onChange={ (event) => setUsersSearch(event.target.value) }
        />
      </div>

      <TableForObjects
        objects={ usersToShow }
        loading={ !(user && users) }
        columns={ [
          { key: 'ra', label: 'RA' },
          { key: 'name', label: 'Nome' },
          { key: 'email', label: 'Email' },
        ] }
        extraColumns={ [
          {
            label: '',
            style: { borderColor: 'transparent', width: '4rem' },
            build: (user) => (
              actions?.map(action => (
                <Button
                  key={ `question-${ user.id }-action-${action.icon}` }
                  disabled={ action.disabled }
                  leftIcon={ action.icon || 'add' }
                  onClick={() => action.onClick(user) }
                />
              ))
            )
          }
        ] }
      />
    </div>
  )
}

export default UsersList
