import { useState, useEffect } from 'react';
import TableForObjects from '../../../components/TableForObjects'
import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Button from '../../../components/Button';
import { useNavigate } from "react-router-dom";
import CreateTaskCard from '../../../components/CreateTaskCard';
import { taskService } from '../../../services/taskService';
import { courseService } from '../../../services/courseService';

const AdminTasksIndex = ({ loadUser, getResponseData, goToLogin }) => {
  const user = loadUser()
  const [tasks, setTasks] = useState(null)
  const [reload, setReload] = useState(false)
  const navigate = useNavigate()

  const [courses, setCourses] = useState(null)

  const deleteTask = async (taskId) => {
    await taskService.admin.delete(user, taskId);
    setReload(!reload);
  }

  useEffect(() => {
    const load = async () => {
      if (user) {
        const tasksRes = await taskService.admin.index(user)
        const tasksDatavalues = getResponseData(tasksRes)
        if (tasksDatavalues) {
          setTasks(tasksDatavalues)
        }
      }
    }

    load()
  }, [reload])

  useEffect(() => {
    const load = async () => {
      if (user) {
        const coursesRes = await courseService.common.getCourses(user)
        const coursesDatavalues = getResponseData(coursesRes)
        if (coursesDatavalues) {
          setCourses(coursesDatavalues)
        }
      }
    }

    load()
  }, [])

  return [
    <Header
      key='admin-tasks-index-header-key'
      admin="Tarefas"
      user={user}
    />,
    user && tasks && courses ? (
      <div
        key='admin-tasks-index-body-key'
        style={{ width: '100%', maxWidth: 1224, margin: '1rem 0.5rem 0.5rem 0.5rem' }}
      >
        <Card title="Tarefas">
          <TableForObjects
            objects={tasks}
            columns={[
              { key: 'name', label: 'Nome' },
            ]}
            extraColumns={
              [
                {
                  label: 'Tópico',
                  build: ( task => {
                    return  task?.topic?.name
                  })
                },
                {
                  label: 'Curso',
                  build: ( task => {
                    return  task?.course?.name
                  })
                },
                {
                  label: 'Data de início',
                  build: (task => {
                    return new Date(`${task.startDate}`).toLocaleDateString() + ' - ' + new Date(`${task.startDate}`).toLocaleTimeString()
                  })
                },
                {
                  label: 'Data de fim',
                  build: ( task => {
                    return new Date(`${task.endDate}`).toLocaleDateString() + ' - ' + new Date(`${task.endDate}`).toLocaleTimeString()
                  })
                },
                {
                  label: "ações",
                  build: (task) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          justifyContent: "space-evenly"
                        }}
                      >
                        <Button
                          title="Editar"
                          onClick={() => {
                            navigate(`/admin/tarefas/editar/${task.id}`, { state: { task, courses } })
                          }}
                          leftIcon="edit"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignitems: "center",
                            width: "5rem",
                            height: "1rem",
                            margin: "0.25rem"
                          }}
                        >
                          Editar
                        </Button>
                        <Button
                          title="Excluir"
                          onClick={() => {
                            deleteTask(task.id)
                          }}
                          leftIcon="delete"
                          color={{ color: 'red' }}
                          style={{
                            color: 'red !important',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignitems: "center",
                            width: "5rem",
                            height: "1rem",
                            margin: "0.25rem"
                          }}
                        >
                          Excluir
                        </Button>
                      </div>
                    )
                  }
                }
              ]
            }
          />
        </Card>
        <CreateTaskCard
          loadUser={loadUser}
          getResponseData={getResponseData}
          courses={courses}
        />
      </div>
    ) : <Loading key="loading-admin-tasks" type='elements-with-cards' />
  ]
}

export default AdminTasksIndex
