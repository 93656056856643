import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import helpers from "../../AppHelpers";
import { Carousel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import CourseCard from "../../components/CourseCard";

import { courseService } from "../../services/courseService";

const reduceRecipes = (acc, cur, index) => {
  var altura = window.innerHeight;
  var largura = window.innerWidth;
  if (altura <= 444 || largura <= 851) {
    const groupIndex = Math.floor(index / 1);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(cur);
    return acc;

  } else {
    const groupIndex = Math.floor(index / 3);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(cur);
    return acc;
  }
};



const CoursesIndex = ({ loadUser, getResponseData, goToLogin }) => {
  const [coursesByCategory, setCoursesByCategory] = useState(null);
  const user = loadUser();

  useEffect(() => {
    const load = async () => {
      const coursesByCategoryRes = await courseService.common.getCoursesByCategory(user);
      const data = getResponseData(coursesByCategoryRes);
      if (data) setCoursesByCategory(data);
    };

    if (user) load();
    else goToLogin();


    function handleResize() {
      document.location.reload(true)

    }

    window.addEventListener('resize', handleResize)
  }, []);

  return [
    <Header key="courses-index-header-key" user={user} />,
    user && (
      <div key="courses-index-page-key" className="app-body">
        {coursesByCategory ? (
          <article style={{ padding: "2.5rem 0" }}>
            <div>

              {coursesByCategory.map((categoriesWithCoursesData) => {
                return (

                  <div className="carousel-container  justify-content-center" id='myCarousel'>

                    <div>
                      <h2 style={{ margin: "20px" }}>{categoriesWithCoursesData.name}</h2>
                      <Carousel interval={null} >

                        {categoriesWithCoursesData?.courses
                          .sort((c1, c2) => c1.number > c2.number)
                          .reduce(reduceRecipes, [])
                          .map((item, index) => {
                            return (
                              <Carousel.Item key={index}>
                                <div className="d-flex justify-content-center">
                                  {item.map((item, index) => {
                                    return (
                                      <Link
                                        to={helpers.path.student.course(item.nameId)}
                                        key={`course#${item?.nameId}`}
                                      >

                                        <CourseCard
                                          className="apply-hover"
                                          noBorder
                                          style={{
                                            flex: 1,
                                            display: "flex",
                                            height: "100%",

                                          }}
                                        >

                                          <div

                                            style={{
                                              flex: 1,
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  gap: "0.5rem",
                                                  paddingTop: "50px",
                                                  height: "20px",
                                                }}
                                              >
                                                <div style={{ fontWeight: "bold", color: "#234286 !important" }}>
                                                  {item?.name}
                                                </div>
                                                <hr size="3" width="100%" max-width="95%" align="left" color="#11097e"></hr>
                                              </div>

                                              <div
                                                style={{
                                                  marginTop: "2.5rem",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  gap: "0.5rem",
                                                }}
                                              >
                                                <div className="small-text italic-text" style={{ fontWeight: "bold", marginTop: "20px" }}>
                                                  Módulos
                                                </div>
                                                <div className="card-course-buttons">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      gap: "0.5rem",
                                                    }}
                                                  >
                                                    {item.modules?.map((mod) => (

                                                      <button type="button" class="custom-btn btn-5"><Link
                                                        key={mod?.id}
                                                        to={helpers.path.student.topic(
                                                          item?.nameId,
                                                          mod?.id
                                                        )}
                                                      >
                                                        {mod?.name}
                                                      </Link></button>

                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="" style={{ height: '50px', marginTop: '10px', padding: '0px !important', width: '100% !important' }}>
                                            <img src="footerCard.png" alt="" />
                                          </div>
                                        </CourseCard>
                                      </Link>
                                    );
                                  })}
                                </div>
                              </Carousel.Item>

                            )
                          })
                        }

                      </Carousel>
                    </div>
                  </div>


                );
              })}
            </div>

          </article>

        ) : (
          <Loading key="loading-courses" type="elements-with-cards" />
        )}

      </div>
    ),

  ];
};


export default CoursesIndex;
