import Header from '../../components/Header';
import { Main, ProfileNav, ProfileContent, Tabs, DisplayName, InfoField, Infotext } from './Style';
import Icon from '../../components/Icon';
import { useState } from 'react';
import Button from '../../components/Button';
import TextField from '../../components/Input';
import { userService } from '../../services/userService';

const UserProfilePage = ({loadUser, getResponseData}) => {
  const [index, setIndex] = useState(0);
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const sendUpdatePassword = async () => {
    if(newPassword === confirmNewPassword ) {
      userService.common.updatePassword(user, newPassword);
      alert("Senha alterada com sucesso!");
      setConfirmNewPassword("");
      setNewPassword("");
    } else {
      alert("As senhas não são iguais.")
    }
  }

  
  const user = loadUser()
  
  return [
    <Header key='users-header-key' user={user}/>,
    user && (
      <Main>
        <ProfileNav>
          <a
            href='https://en.gravatar.com/'
            target='_blank'
            rel='noreferrer'
          >
            <Icon
              name='account-circle'
              style={{color: 'black !important', fontSize: '8em', cursor: 'pointer'}}
            />
          </a>
          <DisplayName>
            {user.name}
          </DisplayName>
          <Tabs
            tabIndex={0}
            indexStatus={index}
            onClick={() => setIndex(0)}
          >
            Perfil
          </Tabs>
          <Tabs
            style={{}}
            tabIndex={1}
            indexStatus={index}
            onClick={() => setIndex(1)}
          >
            Redefinir Senha
          </Tabs>
          <Tabs
            tabIndex={2}
            indexStatus={index}
            style={{marginTop: 'auto', display: 'none'}}
            onClick={() => setIndex(2)}
          >
            Configurações de Conta
          </Tabs>
        </ProfileNav>
        {/* TAB - User Data */}
        <ProfileContent
          show={index !== 0}
        >
          <p
            style={{fontSize: '1.8rem', fontWeight: 'bold', textAlign: 'center'}}
          >
            Dados pessoais
          </p>

          <InfoField>
            <div
              style={{alignSelf: 'flex-start', fontSize: '0.8rem'}}
            >
              Nome:
              <Infotext>
                {user.name}
              </Infotext>
            </div>
          </InfoField>

          <InfoField>
            <div
              style={{alignSelf: 'flex-start', fontSize: '0.8rem'}}
            >
              Email:
              <Infotext>
                {user.email}
              </Infotext>
            </div>
          </InfoField>

          <InfoField>
            <div
              style={{alignSelf: 'flex-start', fontSize: '0.8rem'}}
            >
              Matrícula:
              <Infotext>
                {user.ra}
              </Infotext>
            </div>
          </InfoField>

        </ProfileContent>
        {/* TAB - Reset Password */}
        <ProfileContent
          show={index !== 1}
        >
          <p
            style={{fontSize: '1.8rem', fontWeight: 'bold', textAlign: 'center'}}
          >
            Redefinir Senha
          </p>

          <InfoField>
              <TextField
                label='Nova senha'
                type="password"            
                value={newPassword}
                onChange={(event)=>setNewPassword(event.target.value?.trim() || '')}
              />
          </InfoField>

          <InfoField>
              <TextField
                label='Confirmar nova senha'
                type="password"            
                value={confirmNewPassword}
                onChange={(event)=>setConfirmNewPassword(event.target.value?.trim() || '')}
              />
          </InfoField>

          <Button
            onClick={()=>{sendUpdatePassword()}}
          >
            Alterar Senha
          </Button>
        </ProfileContent>
        {/* TAB - Account settings */}
        <ProfileContent
          show={index !== 2}
        >
          <p
            style={{fontSize: '1.8rem', fontWeight: 'bold', textAlign: 'center'}}
          >
            Account Settings
          </p>

        </ProfileContent>
      </Main>
    )
  ]
}

export default UserProfilePage
