import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TableForObjects from '../../../components/TableForObjects'
import Header from '../../../components/Header'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import AppHelpers from '../../../AppHelpers'
import { courseService } from '../../../services/courseService';
import { assessmentService } from '../../../services/assessmentService';

const AdminAssessmentsIndex = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const navigate = useNavigate()
  const [courses, setCourses] = useState(null)
  const [assessments, setAssessments] = useState(null)

  useEffect(() => {
    const load = async () => {
      if (user) {
        const coursesRes = await courseService.common.getCourses(user)
        const data1 = getResponseData(coursesRes)
        if (data1)
          setCourses(data1)
        else
          setAssessments([])

        const assessmentsRes = await assessmentService.admin.index(user)
        const data2 = getResponseData(assessmentsRes)
        if (data2)
          setAssessments(data2)
      }
    }

    load()
  }, [])

  const courseById = {}
  const subjectById = {}
  courses?.forEach(course => {
    courseById[course.id] = course
    course.subjects.forEach(sub => {
      subjectById[sub.id] = sub
    })
  })

  return [
    <Header
      key='admin-assessments-index-header-key'
      admin="Avaliações"
      user={user}
    />,
    <div
      className='app-body'
      key='admin-assessments-index-body-key'
    >
      <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem', justifyContent: 'space-between' }}>
        Avaliações
        <Button
          onClick={() => navigate('/admin/avaliacoes/nova')}
          leftIcon='add'
        />
      </h2>
      <Card>
        <TableForObjects
          objects={ assessments }
          loading={ !(user && assessments) }
          columns={ [
          ] }
          extraColumns={ [
            {
              label: 'Curso e Disciplina',
              build: (assessment) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <div>
                    { assessment.name || '-' }
                  </div>
                  <div className='small-text'>
                    { courseById[assessment.courseId]?.name || '-' }
                  </div>
                  <div className='small-text'>
                    { subjectById[assessment.topicId]?.name || '-' }
                  </div>
                </div>
              )
            },
            {
              label: 'Período de realização',
              build: (assessment) => (
                <table className='small-table no-border'>
                  <tbody>
                    <tr>
                      <th>data de publicação</th>
                      <td>{ AppHelpers.date.format(assessment.publishedAt) || '--/--/---- --:--' }</td>
                    </tr>
                    <tr>
                      <th>início</th>
                      <td>{ AppHelpers.date.format(assessment.startDate) || '--/--/---- --:--' }</td>
                    </tr>
                    <tr>
                      <th>fim</th>
                      <td>{ AppHelpers.date.format(assessment.endDate) || '--/--/---- --:--' }</td>
                    </tr>
                  </tbody>
                </table>
              )
            },
            {
              label: 'Configuração',
              build: (assessment) => (
                <table className='small-table no-border'>
                  <tbody>
                    <tr>
                      <th>tempo de resolução</th>
                      <td>
                        { assessment.durationInMinutes || '-' }
                        <span className='small-text light-text'> min</span>
                      </td>
                    </tr>
                    <tr>
                      <th>tentativas permitidas</th>
                      <td>{ assessment.allowedTries || '-' }</td>
                    </tr>
                  </tbody>
                </table>
              )
            },
            {
              label: '',
              build: (assessment) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => navigate(`/admin/avaliacoes/${ assessment.id }/editar`)}
                    leftIcon='edit'
                  />
                </div>
              )
            },
          ] }
        />
      </Card>
    </div>
  ]
}

export default AdminAssessmentsIndex
