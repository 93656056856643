import Table from "react-bootstrap/Table";

const TableForFinalGrades = ({ course }) => {
  return (
    <>
      {course.map((course) => (
        <div key={course.id} className="mb-4">
          <h3 className="mb-3 fs-5">Módulo: {course.name}</h3>
          <Table striped bordered hover responsive>
            <thead style={{ backgroundColor: "#333333" }}>
              <tr>
                <th
                  className="text-center fs-6 border-0 text-light"
                  style={{ width: "30rem" }}
                >
                  Matérias
                </th>
                <th
                  className="text-center fs-6 border-0 text-light"
                  style={{ width: "10rem" }}
                >
                  Nota Final
                </th>
              </tr>
            </thead>
            <tbody>
              {course.subjects.map((subject) => {
                const finalGrade = subject.grade;

                return (
                  <tr key={subject.id}>
                    <td>{subject.name}</td>
                    <td>{finalGrade > 0 ? finalGrade.toFixed(2) : "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ))}
    </>
  );
};

export default TableForFinalGrades;
