import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import Folders from '../../../components/Folders'
import { courseService } from '../../../services/courseService';

const AdminFilesIndex = ({loadUser, getResponseData, goToLogin}) => {
  const user = loadUser()
  const [courses, setCourses] = useState(null)

  useEffect(() => {
    const load = async () => {
      if (user) {
        const coursesRes = await courseService.common.getCourses(user)
        const data = getResponseData(coursesRes)
        if (data)
          setCourses(data)
      }
    }

    if (user)
      load()
    else
      goToLogin()
  }, [])


  return [
    <Header
      key='admin-courses-index-header-key'
      admin="Arquivos"
      user={user}
    />,
    user && courses ? (
      <div
        key='admin-courses-index-body-key'
        className='app-body'
      >
        <h2>Arquivos</h2>
        { user && <Folders user={user} courses={courses}/> }
      </div>
    ) : <Loading key="loading-admin-files" type='elements-with-cards'/>
  ]
}

export default AdminFilesIndex
