import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Card from "../../../components/Card";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { courseCategoryService } from "../../../services/courseCategoryService";

const AdminEditCategory = ({ loadUser, getResponseData }) => {
    const user = loadUser();
    const navigate = useNavigate();
    const location = useLocation();
    const category = location.state

    const [categoryName, setCategoryName] = useState(category?.name);
    const [categoryDescription, setCategoryDescription] = useState(category?.description);
    const [categoryDisplayPositon, setCategoryDisplayPosition] = useState(category?.displayPosition);


    const editCategory = async () => {
        if (
            categoryName === null ||
            categoryName === ''
        ) {
            alert("O nome da categoria deve ser preenchido");
            return;
        }
        if (
            categoryDisplayPositon === ''
        ) {
            alert("A posição de exibição deve ser preenchida");
            return;
        }

        await courseCategoryService.admin.update(user, category?.id, categoryName, categoryDescription, categoryDisplayPositon)
        navigate(`/admin/categorias-de-curso`);
    }

    useEffect(() => {
        const load = async () => {

        }

        load()
    }, [])

    return [
        <Header
            key='admin-tasks-edit-index-header-key'
            admin="Categorias de Curso"
            user={user}
        />,
        <div
            key="admin-tasks-index-body-key"
            style={{
                width: "100%",
                maxWidth: 1224,
                margin: "1rem 0.5rem 0.5rem 0.5rem",
            }}
        >
            <Card title={"Editar categoria de curso"}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "min-content",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "2rem"
                        }}
                    >
                        <Input
                            type="text"
                            label="Nome da categoria"
                            onFocus={(event) => event.target.select()}
                            value={categoryName || ""}
                            onChange={(event) => setCategoryName(event.target.value)}
                            containerStyle={{
                                width: "50%"
                            }}
                            
                        />
                        <Input
                            type="number"
                            label="posição de exibição"
                            onFocus={(event) => event.target.select()}
                            onChange={(event) => setCategoryDisplayPosition(event.target.value)}
                            value={categoryDisplayPositon || ""}
                        >
                        </Input>
                    </div>
                    <Input
                        label="Descrição da categoria"
                        textarea="Descrição"
                        onFocus={(event) => event.target.select()}
                        value={categoryDescription || ""}
                        onChange={(event) => setCategoryDescription(event.target.value)}
                        style={{
                            width: "100%",
                            height: "5rem",
                            backgroundColor: "#e4e4e4"
                        }}
                    />
                    <Button
                        title="Criar"
                        onClick={() => {
                            editCategory()
                        }}
                        leftIcon="dns"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignitems: "center",
                            width: "15rem",
                            height: "1rem",
                            marginTop: "0.5rem"
                        }}
                    >
                        Editar Categoria de curso
                    </Button>
                </div>
            </Card>
        </div>
    ]
}

export default AdminEditCategory
