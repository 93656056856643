import Input from '../../../components/Input';
import InputDate from '../../../components/InputDate';
import SelectOptions from '../../../components/SelectOptions';
import Card from '../../../components/Card'

const BasicData = ({ courseAttrs, courseCategories, onChange }) => {
  const categoriesOptions = courseCategories.map((category) => {
    return (
      {
        value: category.id,
        label: category.name
      }
    )
  })

  const columns = [
    [
      {
        label: 'Nome do Curso',
        key: 'name',
        type: 'text'
      },
      {
        label: 'Nome URL do Curso (nameId)',
        key: 'nameId',
        type: 'text'
      }
    ],
    [
      {
        label: 'Descrição',
        key: 'description',
        type: 'textarea'
      },
      {
        label: 'Categoria do curso',
        key: 'courseCategoryId',
        type: 'select',
        includeBlank: 'Selecione...',
        options: categoriesOptions

      }
    ],
    [
      {
        label: 'Tipo',
        key: 'type',
        type: 'select',
        includeBlank: 'Selecione...',
        options: [
          { value: 'default', label: 'Padrão' },
          { value: 'with-no-progress', label: 'Sem progresso' }
        ]
      }
    ],
    [
      {
        label: 'Início',
        key: 'startDate',
        type: 'date'
      },{
        label: 'Fim',
        key: 'endDate',
        type: 'date'
      },{
        label: 'Data de publicação',
        key: 'publishedAt',
        type: 'date'
      }
    ],
  ]

  const renderProperInput = (column) => {
    if (column.type === 'text' || column.type === 'textarea')
      return (
        <Input
          textarea={ column.type === 'textarea' }
          label={ column.label }
          onEnter={ (event) => {
            courseAttrs[column.key] = event.target.value
            onChange(courseAttrs)
          }}
          onFocus={ (event) => event.target.select() }
          autoFocus
          value={ courseAttrs[column.key] || '' }
          onChange={ (event) => {
            courseAttrs[column.key] = event.target.value
            onChange(courseAttrs)
          }}
          containerStyle={{
            width: '13rem'
          }}
        />
      )

    if (column.type === 'date')
      return (
        <InputDate
          label={ column.label }
          selected={
            courseAttrs[column.key] && new Date(courseAttrs[column.key]) || null
          }
          onChange={ (date) => {
            courseAttrs[column.key] = date
            onChange(courseAttrs)
          }}
          style={{
            width: '12rem',
            marginRight: '1rem'
          }}
        />
      )

    if (column.type === 'select')
      return (
        <SelectOptions
          label={ column.label }
          selected={
            courseAttrs[column.key] || null
          }
          includeBlank={ column.includeBlank || false }
          options={ column.options }
          onChange={ (event) => {
            courseAttrs[column.key] = event.target.value
            onChange(courseAttrs)
          }}
          style={{
            width: '13rem'
          }}
        />
      )
  }

  return (
    <Card
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
          Dados básicos
        </div>
      }
    >
      <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}>
        {
          columns.map((columnOrColumns, i) => (
            <div key={ `column-or-columns-${ i }` } style={{ display: 'flex', gap: '1rem' }}>
              {
                Array.isArray(columnOrColumns) ? (
                  columnOrColumns.map(column => (
                    <div
                      key={ `${ column.label }-${ column.key }` }
                      style={ {} }
                    >
                      { renderProperInput(column) }
                    </div>
                  ))
                ) : (
                  <div
                    key={ `${ columnOrColumns.label }-${ columnOrColumns.key }` }
                    style={ {} }
                  >
                    { renderProperInput(columnOrColumns) }
                  </div>
                )
              }
            </div>
          ))
        }
      </div>
    </Card>
  )
}

export default BasicData