import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import useUserState from '../hooks/useUserState'
import Button from './Button';
import Icon from './Icon';
import Folder from './Folder';
import File from './File';
import Loading from './Loading';
import {fileService, fileRequests} from '../services/fileService'

const Folders = ({ parentFolder, user, courses, selectOneFile, onSelectFile, selectedOneFile }) => {
  const inputRef = useRef(null)
  const navigate = useNavigate()
  const [parent, setParent] = useState(parentFolder);
  const [folders, setFolders] = useState(null);
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPath, setCurrentPath] = useState([]);
  const [startEditingNameFor, setStartEditingNameFor] = useState([]);
  const [filesBeingUploaded, setFilesBeingUploaded] = useState([])
  const [, , , , , , goToLogin] = useUserState();
  const [_selectedOneFile, setSelectedOneFile] = useState(selectedOneFile || null)
  const [openModalSelectFile, setOpenModalSelectFile] = useState(false)

  const loadFolders = async () => {
    if ((!user || selectOneFile) && !openModalSelectFile)
      return

    setLoading(true)

    const foldersRes = await fileRequests.folders.admin.index(user, parent?.id || null)
    if (foldersRes.status === 200) {
      setFolders(foldersRes.data.folders)
      setFiles(foldersRes.data.files)
    } else {
      if (foldersRes.status === 401)
        goToLogin()
      else if (foldersRes.status === 403)
        navigate('/acesso-negado')
      else
        alert(`[${ foldersRes.status }] Erro ao carregar os diretórios: ${ foldersRes.message }`)
    }

    setLoading(false)
  }

  useEffect(() => {
    loadFolders()
  }, [parent])

  useEffect(() => {
    if (!folders && selectOneFile && openModalSelectFile)
      loadFolders()
  }, [openModalSelectFile])

  const numberOfItens = (folders?.length || 0) + (files?.length || 0)

  if (selectOneFile && !openModalSelectFile)
    return (
      <div
        style={{
          color: '#777 !important'
        }}
      >
      Selecione um arquivo:
      <div
        style={{padding: '0.5rem', marginTop: '0.5rem', marginBottom: '1rem'}}
        className='apply-box-shadow apply-hover small-text light-text'
        onClick={e => setOpenModalSelectFile(true)}
      >
        {_selectedOneFile ? _selectedOneFile.name : "nenhum arquivo selecionado"}
      </div>
      </div>
    )

  const someUploadInProgress = () => {
    return filesBeingUploaded
      .filter(fUpload => fUpload.progress.percentage !== 100)
      .length > 0
  }

  const noUploadInProgress = () => {
    return filesBeingUploaded
      .filter(fUpload => fUpload.progress.percentage !== 100)
      .length === 0
  }

  return (
    <div
      className={selectOneFile ? 'apply-box-shadow' : ''}
      style={Object.assign(
        {
          margin: '1rem',
          display: 'flex',
          flexDirection: 'column'
        },
        selectOneFile && openModalSelectFile ? {
          backgroundColor: '#EEE',
          padding: '1.5rem',
          border: 'solid 1px #CCC',
          minWidth: "25rem",
          maxWidth: "80vw"
        } : {}
      )}
    >
      {
        selectOneFile && (
          <div style={{
            paddingBottom: '1rem',
            color: '#777 !important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div>
              Selecione um arquivo
            </div>
            <div
              onClick={() => setOpenModalSelectFile(false)}
              className='apply-hover'
              style={{padding: '0.5rem', marginRight: '-0.5rem'}}
            >
              <Icon style={{}} name='close'/>
            </div>
          </div>
        )
      }

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          gap: '0.5rem',
          marginBottom: '1rem'
        }}
      >
        <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
          <span
            className='apply-hover folder-path-part apply-box-shadow'
            onClick={() => {
              setCurrentPath([])
              setParent(null)
            }}
          >/</span>
          {
            currentPath.map(folder => (
              <span
                key={`folder-breadcrumb-${folder.id}`}
                className='apply-hover folder-path-part apply-box-shadow'
                onClick={() => {
                  setCurrentPath(currentPath.filter(f => folder.hierarchyIds.indexOf(f.id) >= 0))
                  setParent(folder)
                }}
              >{folder.name}</span>
            ))
          }
        </div>

        <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
          <div style={{display: 'none'}}>
            <input ref={inputRef} type='file' multiple onChange={async (e) => {
              const filesToUpload = Array.from(e.target.files)
              setFilesBeingUploaded(filesToUpload.map(file => (
                { file, progress: { percentage: 0 } }
              )))

              try {
                fileService.uploadFilesInChunks(
                  user,
                  filesToUpload,
                  parent?.id || null,
                  (file, progressInfo) => {
                    setFilesBeingUploaded((filesBeingUploaded_) => (
                      filesBeingUploaded_.map(fileUpload => {
                        if (fileUpload.file.name === file.name)
                          return Object.assign(
                            fileUpload,
                            { progress: progressInfo }
                          )
                        else
                          return fileUpload
                      })
                    ))
                  },
                  (uploadedFile) => {
                    setFiles((files_) => [uploadedFile, ...files_])
                    setSelectedOneFile(uploadedFile)
                    setOpenModalSelectFile(false)
                  }
                )
              } catch (e) {
                alert('Erro no upload', e.message)
                console.log('Erro no upload', e.message)
                setFilesBeingUploaded([])
              }
            }}/>
          </div>

          {
            filesBeingUploaded.length > 0 && (
              <div>
                {
                  filesBeingUploaded.map(fileUpload => {
                    const name = fileUpload.file.name
                    return fileUpload.progress?.percentage === 100 ? (
                      <div
                        className='small-text'
                        style={{
                          display: 'flex',
                          gap: '0.5rem',
                          alignItems: 'center',
                        }}>
                        <Icon
                          style={{
                            padding: '0.25rem 0',
                            color: 'green !important'
                          }}
                          name='done'/>
                        <div>
                          {name}
                        </div>
                      </div>
                    ) : (
                      <Loading
                        message={`${name}: ${fileUpload.progress?.percentage || 0}%`}
                      />
                    )
                  })
                }
              </div>
            )
          }

          {
            (
              <Button
                title='Fazer upload de arquivo'
                onClick={async (e) => {
                  if (loading || someUploadInProgress())
                    return

                  inputRef.current.click()
                }}
                leftIcon={ !loading && noUploadInProgress() && 'file-upload' }
              >
                { (loading || someUploadInProgress()) && '...' }
              </Button>
            )
          }

          {
            !selectOneFile && (
              <Button
                title='Criar nova pasta'
                onClick={async (e) => {
                  if (loading)
                    return

                  setLoading(true)

                  const newFolder = await fileService.createFolder(
                    user,
                    'Nova Pasta',
                    parent
                  )
                  setStartEditingNameFor(newFolder.id)
                  setFolders([newFolder].concat(folders))

                  setLoading(false)
                }}
                leftIcon={ !loading && 'folder-create' }
              >
                { loading && '...' }
              </Button>
            )
          }
        </div>
      </div>

      <div
        className='apply-white-box-shadow'
        style={{
          backgroundColor: '#f5f5f5',
          height: '25rem',
          overflowY: 'auto',
          padding: '0.5rem'
        }}
      >
        {
          loading ? (
            <div style={{ margin: '1.5rem' }}>
              <Loading message='aguarde...'/>
            </div>
          ) : (
            numberOfItens === 0 ? (
              <div className='light-text italic-text' style={{ margin: '1.5rem' }}>
                Esta pasta está vazia
              </div>
            ) : (
              <div>
                <div className='folders-room'>
                  {
                    folders?.map(folder => (
                      <Folder
                        key={`folder-${folder.id}`}
                        selectOneFile={selectOneFile}
                        startEditingName={folder.id === startEditingNameFor}
                        folder={folder}
                        user={user}
                        onNameChange={(name) => {
                          setFolders(folders.map(f => (
                            f.id !== folder.id ? f : Object.assign(folder, { name })
                          )))
                        }}
                        onDelete={() => setFolders(folders.filter(f => f.id !== folder.id))}
                        onClick={async () => {
                          setStartEditingNameFor(null)
                          setCurrentPath(currentPath.concat(folder))
                          setParent(folder)
                        }}
                      />
                    ))
                  }
                </div>

                <div className='files-room'>
                  {
                    files?.map((file, i) => (
                      <File
                        key={`file-${file.id}-${i}`}
                        selectOneFile={selectOneFile}
                        onSelectFile={(f) => {
                          setOpenModalSelectFile(false)
                          setSelectedOneFile(f)
                          onSelectFile(f)
                        }}
                        onDelete={() => setFiles(files.filter(f => f.id !== file.id))}
                        file={file}
                        user={user}
                        courses={courses}
                      />
                    ))
                  }
                </div>
              </div>
            )
          )
        }
      </div>

      {
        selectOneFile && (
          <div
            onClick={() => {
              setOpenModalSelectFile(false)
              onSelectFile(null)
            }}
            className='apply-hover'
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              color: '#777 !important',
              marginTop: '1rem',
              padding: '0.5rem',
            }}
          >
            Limpar
          </div>
        )
      }
    </div>
  )
}

export default Folders
