import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TableForObjects from '../../../components/TableForObjects'
import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import AppHelpers from '../../../AppHelpers'
import { courseService } from '../../../services/courseService';

const AdminCoursesIndex = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const navigate = useNavigate()
  const [courses, setCourses] = useState(null)

  useEffect(() => {
    const load = async () => {
      if (user) {
        const coursesRes = await courseService.admin.index(user)
        const data = getResponseData(coursesRes)
        if (data)
          setCourses(data)
      }
    }

    load()
  }, [])


  return [
    <Header
      key='admin-courses-index-header-key'
      admin="Cursos"
      user={user}
    />,
    user && courses ? (
      <div
        className='app-body'
        key='admin-courses-index-body-key'
      >
        <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem', justifyContent: 'space-between' }}>
          Cursos
          <Button
            onClick={() => navigate('/admin/cursos/novo')}
            leftIcon='add'
          />
        </h2>

        <Card>
          <TableForObjects
            objects={ courses }
            loading={ !(user && courses) }
            columns={ [
            ] }
            extraColumns={ [
              {
                label: 'Nome e Id da URL',
                build: (course) => (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <div>
                      { course.name || '-' }
                    </div>
                    <div className='small-text'>
                      { course.nameId || '-' }
                    </div>
                  </div>
                )
              },
              {
                label: 'Datas',
                build: (course) => (
                  <table className='small-table no-border'>
                    <tbody>
                      <tr>
                        <th>data de publicação</th>
                        <td>{ AppHelpers.date.format(course.publishedAt) || '--/--/---- --:--' }</td>
                      </tr>
                      <tr>
                        <th>início</th>
                        <td>{ AppHelpers.date.format(course.startDate) || '--/--/---- --:--' }</td>
                      </tr>
                      <tr>
                        <th>fim</th>
                        <td>{ AppHelpers.date.format(course.endDate) || '--/--/---- --:--' }</td>
                      </tr>
                    </tbody>
                  </table>
                )
              },
              {
                label: '',
                style: { borderColor: 'transparent' },
                build: (course) => (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={() => navigate(`/admin/cursos/${ course.id }/editar`)}
                      leftIcon='chevron-right'
                    />
                  </div>
                )
              },
            ] }
          />
        </Card>
      </div>
    ) : <Loading key="loading-admin-courses" type='elements-with-cards'/>
  ]
}

export default AdminCoursesIndex
