import React, { useEffect, useState } from "react";
import useCourseState from "../../hooks/useCourseState";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import CourseHeader from "../../components/CourseHeader";
import Logo from "../../assets/logo-tech-school-v.png";
import useTopicState from "../../hooks/useTopicState";
import { calendarService } from "../../services/calendarEntryService";

import Vimeo from '@u-wave/react-vimeo';

const VideoPlayer = ({ loadUser, getResponseData }) => {
  const user = loadUser();
  const { courseId } = useParams();
  const { topicId } = useTopicState();
  const [course] = useCourseState(user, courseId, getResponseData);
  const navigate = useNavigate();

  const { calendarEntryId } = useParams();
  const [entry, setEntry] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const load = async () => {
      if (user) {
        const entryRes = await calendarService.common.getCalendarEntry(
          user,
          courseId,
          calendarEntryId
        );
        const entryDatavalues = getResponseData(entryRes);
        if (entryDatavalues) {
          setEntry(entryDatavalues);
        }
      }
    };

    load();
  }, []);

  return entry
    ? [
      <Header key="video-player-header-key" user={user} course={course} />,
      <CourseHeader title={entry.name} user={user} course={course} />,
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "max-content",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "1rem",
            width: "70%",
            padding: "1rem",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              textAlign: "center",
              textDecorationLine: "underline",
              color: "blue !important",
              margin: "0",
              cursor: "pointer",
            }}
            onClick={() => {
              topicId
                ? navigate(
                  `/cursos/${courseId}/topicos/${topicId}/aulas-gravadas`
                )
                : navigate(`/cursos/${courseId}/aulas-gravadas`);
            }}
          >
            voltar
          </p>
        </div>
        <div
          style={{
            display: load ? "flex" : "none",
            width: "70%",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "15%",
            }}
          >
            <img
              alt="idp-logo"
              style={{
                opacity: "0.5",
              }}
              width="100%"
              src={Logo}
            />
          </div>
         
        </div>
        <div
          style={{
            display: load ? "none" : "flex",
            width: "70%",
            position: "relative",
            justifyContent: "center",
          }}
        >
          <Vimeo
            width="1000%"
            video={entry.videoCallLink}
          />

        </div>
      </div>,
    ]
    : null;
};

export default VideoPlayer;
