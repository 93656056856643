import { HOST, baseApiService } from "./baseApiService";

const courseCategoryService = {
  host: HOST,
  paths: {
    admin: {
      courseCategoriesPath: () => {
        return `/admin/course-categories`;
      },
      findCourseCategoryPath: (courseCategoryId) => {
        return `/admin/course-categories/${courseCategoryId}`;
      },
      createCourseCategoryPath: () => {
        return `/admin/course-categories/create`;
      },
      updateCourseCategoryPath: (courseCategoryId) => {
        return `/admin/course-categories/update/${courseCategoryId}`;
      },
      deleteCourseCategoryPath: (courseCategoryId) => {
        return `/admin/course-categories/delete/${courseCategoryId}`;
      },
    },
  },

  admin: {
    index: async (user, params) => {
      return await baseApiService.get(
        user,
        courseCategoryService.paths.admin.courseCategoriesPath(),
        params || {}
      );
    },
    find: async (user, courseCategoryId) => {
      return await baseApiService.get(
        user,
        courseCategoryService.paths.admin.findCourseCategoryPath(courseCategoryId)
      );
    },
    create: async (
      user,
      categoryName,
      categoryDescription,
      categoryDisplayPositon
    ) => {
      return await baseApiService.post(
        user,
        courseCategoryService.paths.admin.createCourseCategoryPath(),
        {
          name: categoryName,
          description: categoryDescription,
          displayPosition: categoryDisplayPositon,
        }
      );
    },
    update: async (
      user,
      courseCategoryId,
      categoryName,
      categoryDescription,
      categoryDisplayPositon
    ) => {
      return await baseApiService.patch(
        user,
        courseCategoryService.paths.admin.updateCourseCategoryPath(courseCategoryId),
        {
          name: categoryName,
          description: categoryDescription,
          displayPosition: categoryDisplayPositon,
        }
      );
    },

    delete: async (user, courseCategoryId) => {
      return await baseApiService.delete(
        user,
        courseCategoryService.paths.admin.deleteCourseCategoryPath(courseCategoryId)
      );
    },
  },
};

export {
  courseCategoryService
}