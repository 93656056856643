import Loading from './Loading'
import HeaderBreadcrumbItemDropdownOption from './HeaderBreadcrumbItemDropdownOption'

const HeaderBreadcrumbItemDropdown = ({ topic, topics, style, className, isMobile, openPath }) => {

  let _topics;
  if (isMobile) {
    _topics = topic.ancestors[0]?.siblings || (!topic.parentId && topic.siblings) || []
  } else {
    _topics = topics
  }

  return (
    <div
      className={ className || '' }
      style={ Object.assign(
        {
          backgroundColor: '#333',
          color: 'white !important',
          position: 'absolute',
          top: '0.5rem',
          left: '-0.6rem',
          zIndex: 99,
          minWidth: '20rem',
          width: '20rem',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 0.5rem',
          overflowY: 'auto',
          maxHeight: '78vh'
        },
        style || {}
      )}
    >
      {
        _topics ? (
          _topics.map(t => {
            return (
              <HeaderBreadcrumbItemDropdownOption
                key={ `link-${ t.id }` }
                isMobile={ isMobile }
                openPath={ openPath }
                topic={ t }
                parentTopic={ topic }
              />
            )
          })
        ) : (
          <div
            className='disable-children-mouseover-events'
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '1rem'
            }}
          >
            <Loading/>
          </div>
        )
      }
    </div>
  )
}

export default HeaderBreadcrumbItemDropdown
