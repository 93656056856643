import { useState, useEffect, useRef } from 'react';
import Input from '../../../components/Input';
import InputDate from '../../../components/InputDate';
import SelectOptions from '../../../components/SelectOptions';
import Button from '../../../components/Button'
import { useNavigate } from "react-router-dom";
import Card from '../../../components/Card'
import Loading from '../../../components/Loading'
import Icon from '../../../components/Icon'

const Update = ({ currentUser, user, courses, getResponseData, onFinish, loading }) => {
  const navigate = useNavigate()
  const [userAttrs, setUserAttrs] = useState(user || {})

  useEffect(() => {
    if (user) {
      setUserAttrs(user)
    }
  }, [user])

  const renderProperInput = (column) => {
    if (column.type === 'text' || column.type === 'textarea')
      return (
        <Input
          textarea={ column.type === 'textarea' }
          label={ column.label }
          onEnter={ (event) => {
            setUserAttrs(
              (prevValue) => {
                prevValue[column.key] = event.target.value
                return Object.assign({}, prevValue)
              }
            )
          }}
          onFocus={ (event) => event.target.select() }
          autoFocus
          value={ userAttrs[column.key] || '' }
          onChange={ (event) => {
            setUserAttrs(
              (prevValue) => {
                prevValue[column.key] = event.target.value
                return Object.assign({}, prevValue)
              }
            )
          }}
          containerStyle={{
            width: '13rem'
          }}
        />
      )

    if (column.type === 'date')
      return (
        <InputDate
          label={ column.label }
          selected={
            userAttrs[column.key] && new Date(userAttrs[column.key]) || null
          }
          onChange={ (date) => {
            setUserAttrs(
              (prevValue) => {
                prevValue[column.key] = date
                return Object.assign({}, prevValue)
              }
            )
          }}
          style={{
            width: '12rem',
            marginRight: '1rem'
          }}
        />
      )

    if (column.type === 'select')
      return (
        <SelectOptions
          label={ column.label }
          selected={
            userAttrs[column.key] || null
          }
          includeBlank={ column.includeBlank || false }
          options={ column.options }
          onChange={ (event) => {
            setUserAttrs(
              (prevValue) => {
                prevValue[column.key] = event.target.value
                return Object.assign({}, prevValue)
              }
            )
          }}
          style={{
            width: '13rem'
          }}
        />
      )
  }

  const columns = [
    [
      { label: 'RA', key: 'ra', type: 'text' },
      { label: 'CPF', key: 'cpf', type: 'text' },
      { label: 'Nome', key: 'name', type: 'text' },
      { label: 'apelido', key: 'nickname', type: 'text' },
    ],[
      { label: 'Nome social', key: 'socialName', type: 'text' },
      { label: 'URL do avatar', key: 'avatarUrl', type: 'text' },
      { label: 'Data de nascimento', key: 'birthDate', type: 'text' },
      { label: 'Gênero', key: 'gender', type: 'text' },
    ],[
      { label: 'Telefone 1', key: 'phone1', type: 'text' },
      { label: 'Telefone 2', key: 'phone2', type: 'text' },
      { label: 'Lugar de nascimento', key: 'birthState', type: 'text' },
      { label: 'Estado civil', key: 'maritalStatus', type: 'text' },
    ],[
      { label: 'Endereço', key: 'addressStreet', type: 'text' },
      { label: 'Complemento', key: 'addressMoreDetails', type: 'text' },
      { label: 'Número', key: 'addressNumber', type: 'text' },
      { label: 'CEP', key: 'addressZipCode', type: 'text' },
    ],[
      { label: 'Bairro', key: 'addressBurgh', type: 'text' },
      { label: 'Estado', key: 'addressState', type: 'text' },
      { label: 'Cidade', key: 'addressCity', type: 'text' },
      { label: 'País', key: 'addressCountry', type: 'text' },
    ],[
      { label: 'Perfil ativo agora', key: 'activeRole', type: 'text' },
      { label: 'Perfis possíveis', key: 'grantedRoles', type: 'text' },
    ],[
      { label: 'Bibliografia', key: 'bibliografy', type: 'textarea' },
    ],[
      { label: 'Token do firebase', key: 'firebaseAccessToken', type: 'textarea' },
      { label: 'Id no firebase', key: 'firebaseUid', type: 'text' },
      { label: 'Expiração token firebase', key: 'firebaseAccessTokenExp', type: 'date' },
      { label: 'Expiração para sync com RM', key: 'rmSyncExp', type: 'date' },
    ]
  ]

  return (
    <div>
      <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
        <Button
          leftIcon={ 'chevron-left' }
          onClick={() => navigate(-1) }
        />
        Atualizar usuário
      </h2>

      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
            Dados do usuário
          </div>
        }
      >
        <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}>
          {
            columns.map((columnOrColumns, i) => (
              <div key={ `column-or-columns-${ i }` } style={{ display: 'flex', gap: '1rem' }}>
                {
                  Array.isArray(columnOrColumns) ? (
                    columnOrColumns.map(column => (
                      <div
                        key={ `${ column.label }-${ column.key }` }
                        style={ {} }
                      >
                        { renderProperInput(column) }
                      </div>
                    ))
                  ) : (
                    <div
                      key={ `${ columnOrColumns.label }-${ columnOrColumns.key }` }
                      style={ {} }
                    >
                      { renderProperInput(columnOrColumns) }
                    </div>
                  )
                }
              </div>
            ))
          }
        </div>
      </Card>

      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          marginTop: '1.5rem',
          justifyContent: 'space-between'
        }}
      >
        <div
          onClick={(e) => {
            onFinish(userAttrs)
          }}
          className='apply-box-shadow'
          style={{
            padding: '0.5rem',
            usuarior: 'pointer',
            color: 'white',
            backgroundColor: 'darkGreen',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div className='small-text'>
            Atualizar usuario
          </div>
          {
            loading ? <Loading/> : <Icon name='done'/>
          }
        </div>

        <div
          onClick={(e) => {
            navigate('/admin/usuarios')
          }}
          className='apply-box-shadow'
          style={{
            padding: '0.5rem',
            usuarior: 'pointer',
            color: 'white',
            backgroundColor: 'darkRed',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div className='small-text'>
            cancelar
          </div>
          <Icon name='close'/>
        </div>
      </div>
    </div>
  )
}

export default Update;
