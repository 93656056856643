import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button'
import Input from '../../components/Input'
import ToastAlert from '../../components/ToastAlert'
import LogoSrc from '../../assets/logo-tech-school-v.png'
import SplashScreenSrc from '../../assets/splash-screen-tech-school2.gif'
import useUserState from '../../hooks/useUserState'

const Home = ({getResponseData}) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [loading, setLoading] = useState(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [
    user,
    error,
    success,
    login,
    ,
    ,
    ,
    forgotPassword
  ] = useUserState();
  const [loadingpassword, setLoadingpassword] = useState(false)
  const [list, setList] = useState([]);

  useEffect(() => {
    if (user)
      if (user.activeRole === 'student')
        navigate('/cursos')
      else if (user.activeRole === 'admin')
        navigate('/admin')
      else
        alert('Perfil de usuário desconhecido')

    if(error || success)
      setList([...list, buildToastObject(error || success)])
  }, [user, error, success])

  const buildToastObject = (codeStatus) => {
    const currentDate = new Date().getTime()
    switch (codeStatus) {
      case 200:
        return {
          id: currentDate,
          icon: 'check-circle-icon',
          description: `Solicitação enviada com sucesso para ${email}!`,
          backgroundColor: '#5cb85c'
        }
      case 400:
        return {
          id: currentDate,
          icon: 'dangerous-icon',
          description: `E-mail inválido!`,
          backgroundColor: '#d9534f'
        }
      case 422:
        return {
          id: currentDate,
          icon: 'dangerous-icon',
          description: `E-mail ou senha inválido!`,
          backgroundColor: '#d9534f'
        }
      case 429:
        return {
          id: currentDate,
          icon: 'dangerous-icon',
          description: `E-mail bloqueado devido a múltiplas tentativas de acesso não autorizado!`,
          backgroundColor: '#d9534f'
        }
      case 404:
        return {
          id: currentDate,
          icon: 'info-icon',
          description: `O ${email} não foi encontrado, entre em contato com o provedor para realizar o cadastro!`,
          backgroundColor: '#5bc0de'
        }
      default:
        return {
          id: currentDate,
          icon: 'info-icon',
          description: `Um erro inesperado ocorreu`,
          backgroundColor: '#f0ad4e'
        }
    }
  };

  return (
    <div key='public-home-page-key' className='app-body'>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        position: 'relative'
      }}>
        <div style={{
          marginTop: '10%',
          paddingBottom: '6rem',
          textAlign: 'center',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}>
            <img
              src={ SplashScreenSrc }
              className='splash-screen'
              style={{
                position: 'absolute',
                transition: 'opacity 1s, width 1.0s, margin-left 0.5s',
                opacity: showSplashScreen ? 1 : 0,
                width: showSplashScreen ? '20rem' : '0',
                marginLeft: showSplashScreen ? '0' : '-25rem',
              }}
              onLoad={ () => {
                setTimeout(
                  () => setShowSplashScreen(false),
                  3200
                )
              }}
            />
          </div>

          <div style={{
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            textAlign: 'left',
            gap: '3.5rem',
            transition: 'opacity 1.5s',
            opacity: showSplashScreen ? 0 : 1
          }}>
            <img className='hide-small-screen' src={LogoSrc} style={{
              flex: 1, width: '19rem',
            }}/>

            <div className='hide-small-screen' style={{
              borderRight: 'solid 1px #CCC',
              paddingTop: '18rem',
            }}>
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '1rem',
            }}>
              <img className='hide-big-screen' src={LogoSrc} style={{
                width: '18rem', marginBottom: '5rem'
              }}/>

              <div style={{ marginBottom: '1.5rem' }}>
                <Input
                  onEnter={async () => {
                    if (!loading) {
                      setLoading(true)
                      await login(email, pass)
                      setLoading(false)
                    }
                  }}
                  label="Email"
                  value={ email }
                  onChange={ event => setEmail(event.target.value?.trim() || '') }
                />
              </div>
              <div style={{ marginBottom: '1.5rem' }}>
                <Input
                  onEnter={async () => {
                    if (!loading) {
                      setLoading(true)
                      await login(email, pass)
                      setLoading(false)
                    }
                  }}
                  label="Senha"
                  type="password"
                  value={ pass }
                  onChange={ event => setPass(event.target.value?.trim() || '') }
                />
              </div>

              <div style={{ padding: '1rem', color: 'red !important' }}>
                {
                  error && (
                    error === 422 ? (
                      <div>Usuário e/ou senha inválidos</div>
                    ) : (
                      error === 429 ? (
                        <div>Usuário bloqueado devido a tentativas erradas de login</div>
                      ) : null
                    )
                  )
                }
              </div>

              <Button
                onClick={ async () => {
                  if (!loading) {
                    setLoading(true)
                    await login(email, pass)
                    setLoading(false)
                  }
                }}
                style={{ width: '80%', marginBottom: '2rem' }}
              >
                { loading ? 'aguarde...' : 'entrar' }
              </Button>
              <div
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  if (!loadingpassword) {
                    setLoadingpassword(true)
                    await forgotPassword(email)
                    setLoadingpassword(false)
                  }
                }}
              >
                {loadingpassword ? 'aguarde...' : 'esqueci a senha'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastAlert
        toastList={list}
        setList={setList}
      />
    </div>
  )
}

export default Home
