import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Header from '../../components/Header';
import CourseHeader from '../../components/CourseHeader';
import Card from '../../components/Card';
import Loading from '../../components/Loading';
import useCourseState from "../../hooks/useCourseState";
import useTopicState from "../../hooks/useTopicState";
import { fileRequests } from '../../services/fileService';

const CourseFoldersIndex = ({loadUser, getResponseData}) => {
  const user = loadUser()
  const {courseId, id} = useParams();
  const [course] = useCourseState(user, courseId, getResponseData);
  const [topic] = useTopicState(user, courseId, id, getResponseData);
  const [files, setFiles] = useState(null)

  useEffect(() => {
    const load = async () => {
      const filesRes = await fileRequests.file.commom.getFiles(
        user,
        courseId,
        id,
        { category: "supportMaterial" }
      )

      const data = getResponseData(filesRes)
      if (data)
        setFiles(data);
    }

    courseId && load();
  }, [courseId])

  return (
    <>
      <Header key='student-home-header-key' user={user} course={course} subject={topic} />
      <CourseHeader title="Material de Apoio" user={user} course={course} subject={topic}/>
      <div className='app-body'>
        <h2>
          Material de apoio
          { files && (topic ? ` da disciplina` : ' do curso') }
        </h2>

        {
          topic && (
            <h3>
              {topic.name}
            </h3>
          )
        }

        <Card style={{ width: '100%' }}>
          <table>
            <thead>
              <tr>
                <th>Disciplina</th>
                <th>Arquivo</th>
              </tr>
            </thead>

            <tbody>
              {
                files ? (
                  files.length > 0 ? (
                    files.map(file => (
                      <tr key={file.link}>
                        <td>
                          {file.topic?.name}
                        </td>

                        <td>
                          <a className='link' href={file.link}>
                            {file.name}
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan='2' className='small-text light-text'>
                        Nenhum arquivo encontrado
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colspan='2'>
                      <Loading/>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </Card>
      </div>
    </>
  )
}

export default CourseFoldersIndex;