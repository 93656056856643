import Icon from './Icon';

const Button = ({ children, onClick, style, disabled, leftIcon, title, color }) => {
  const defaultStyle = {
    backgroundColor: 'black',
    color: 'white',

  }

  return (

      <div
        title={title || ''}
        disabled={disabled}
        className='idp-button'
        onClick={(e) => !disabled && onClick(e)}
        style={Object.assign(
          {},
          defaultStyle,
          
        )}
      >
        {leftIcon && <Icon name={leftIcon} style={color} />}
        {children}
      </div>
  )
}

export default Button
