import { useState, useEffect } from 'react';
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useNavigate } from "react-router-dom";
import Card from '../../../components/Card'
import Loading from '../../../components/Loading'
import Icon from '../../../components/Icon'
import UsersList from './UsersList'
import BasicData from './BasicData'
import CourseTopics from './CourseTopics'
import CourseAssessments from './Assessments'
import { courseCategoryService } from '../../../services/courseCategoryService';
import { userService } from '../../../services/userService';

const CreateOrUpdate = ({ user, course, courses, getResponseData, onFinish, loading }) => {
  const navigate = useNavigate()

  const [users, setUsers] = useState(null)
  const [usersSearch, setUsersSearch] = useState('')
  const [courseAttrs, setCourseAttrs] = useState(course || {})
  const [includedUserIds, setIncludedUserIds] = useState([])
  const [emailsToSelect, setEmailsToSelect] = useState('')
  const [showInsertEmails, setShowInsertEmails] = useState(false)
  const [courseCategories, setCourseCategories] = useState([])

  const tabs = {
    data: { id: 'data', label: 'Dados' },
    users: { id: 'users', label: 'Usuários' },
    topics: { id: 'topics', label: 'Tópicos' },
    assesments: {id:'assessments', label:'Avaliações'}
  }

  const [openTab, setOpenTab] = useState(tabs.data)

  useEffect(() => {
    if (course) {
      setCourseAttrs(course)
      setIncludedUserIds(
        course
          ?.enrollments
          ?.filter(enrollment => enrollment.active)
          ?.map(enrollment => enrollment.userUid) || []
      )
    }
  }, [course])

  useEffect(() => {
    const load = async () => {
      if(user) {
        const CategoriesRes = await courseCategoryService.admin.index(user)

        const data = getResponseData(CategoriesRes)
        if(data) {
          setCourseCategories(data)
        }
      }
    }

    load();
  }, [user])

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchUsers(), 1000);
    return () => clearTimeout(timeOutId);
  }, [usersSearch])

  const isUpdate = !!courseAttrs?.id
  const isCreate = !isUpdate

  const fetchUsers = async () => {
    const usersRes = await userService.admin.index(
      user,
      { search: usersSearch || null }
    )
    const data = getResponseData(usersRes)
    if (data)
      setUsers(data)
  }

  const renderTabs = () => {

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem', marginBottom: '1.5rem' }}>
        {
          Object.entries(tabs).map(([tabId, tab]) => (
            <Button
              key={ tabId }
              style={ openTab.id === tab.id ? {} : { backgroundColor: '#999' }}
              onClick={ () => setOpenTab(tab) }
            >
              { tab.label }
            </Button>
          ))
        }
      </div>
    )
  }

  const renderContent = () => {
    if (openTab.id === tabs.data.id) 
      return (
        <BasicData
          courseAttrs={ courseAttrs || {} }
          courseCategories={courseCategories || []}
          onChange={ (newCoureAttrs) => {
            setCourseAttrs(prevValue => Object.assign(
              {},
              prevValue,
              newCoureAttrs
            ))
          }}
        />
      )

    if (openTab.id === tabs.users.id) 
      return (
        <div>
          <Card
            title={
              <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
                Usuários associados ao curso
              </div>
            }
          >
            <UsersList
              user={ user }
              users={ users?.filter(u => includedUserIds.indexOf(u.firebaseUid) >= 0) }
              course
              onChange={ users__ => {
                setCourseAttrs(prevValue => Object.assign(
                  {},
                  prevValue,
                  { users: users__ }
                ))
              }}
              actions={[
                {
                  icon: 'remove',
                  onClick: (user) => {
                    setIncludedUserIds(prevValue => prevValue.filter(uid => uid != user.firebaseUid))
                  }
                },
              ]}
            />
          </Card>

          <Card
            title={
              <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
                Incluir novos usuários ao curso
              </div>
            }
          >
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <Button
                  onClick={ () => setShowInsertEmails(true) }
                >
                  Incluir vários emails
                </Button>
                <div style={{
                  display: showInsertEmails ? '' : 'none',
                  position: 'fixed',
                  zIndex: 999999,
                  backgroundColor: '#333333ba',
                  width: 'calc(100% - 16rem)',
                  height: '100%',
                  top: 0,
                  left: 0,
                  padding: '10rem 8rem'
                }}>
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '2rem 3rem'
                    }}
                  >
                    <Input
                      label='Separe os emails por enter, vírgula ou espaço'
                      autoFocus={ true }
                      textarea
                      style={{ minHeight: '5rem' }}
                      value={ emailsToSelect }
                      onChange={ (e) => setEmailsToSelect(e.target.value) }
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button
                        style={{ backgroundColor: 'darkred' }}
                        onClick={ () => setShowInsertEmails(false) }
                      >
                        cancelar
                      </Button>

                      <Button
                        onClick={ () => {
                          setShowInsertEmails(false)
                          const newUserIds = users
                            ?.filter(u => includedUserIds.indexOf(u.firebaseUid) < 0 && emailsToSelect.indexOf(u.email) >= 0)
                            ?.map(u => u.firebaseUid) || []

                          setEmailsToSelect('')
                          setIncludedUserIds(prevValue => newUserIds.concat(prevValue))
                          // console.log('mandar essas coisas pra dentro do componente')
                        }}
                      >
                        Incluir emails
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <UsersList
              user={ user }
              users={ users?.filter(u => {
                return (includedUserIds.indexOf(u.firebaseUid) < 0)
              })}
              actions={[
                {
                  icon: 'add',
                  onClick: (user) => {
                    setIncludedUserIds(prevValue => [user.firebaseUid].concat(prevValue))
                  }
                }
              ]}
            />
          </Card>
        </div>
      )

    if (openTab.id === tabs.topics.id)
      return (
        <CourseTopics
          course={ course }
          user={ user }
          getResponseData={ getResponseData }
        />
      ) 
    if (openTab.id === tabs.assesments.id)
      return (
        <CourseAssessments
          course={ course }
          user={ user }
          getResponseData={ getResponseData }
        />
      ) 
  }

  return (
    <div>
      <h2 style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
        <Button
          leftIcon={ 'chevron-left' }
          onClick={() => navigate(-1) }
        />
        { course?.name || 'Criar novo curso' }
      </h2>

      { renderTabs() }
      { renderContent() }

      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          marginTop: '1.5rem',
          justifyContent: 'space-between'
        }}
      >
        <div
          onClick={(e) => {
            onFinish(
              Object.assign(
                {},
                courseAttrs,
                {
                  users: null,
                  userIds: includedUserIds,
                },
              )
            )
          }}
          className='apply-box-shadow'
          style={{
            padding: '0.5rem',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: 'darkGreen',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div className='small-text'>
            { isUpdate ? 'Atualizar curso' : 'Criar novo curso' }
          </div>
          {
            loading ? <Loading/> : <Icon name='done'/>
          }
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate;
