import { useState } from 'react';
import Icon from './Icon';

const Card = ({
  title,
  children,
  hideContent,
  loadContent,
  style,
  bodyStyle,
  onClick,
  className,
  bodyClassName,
  noBorder
}) => {
  const [showContent, setShowContent] = useState(!hideContent)
  const [loadingContent, setLoadingContent] = useState(false)
  const [loadedContent, setLoadedContent] = useState(null)

  return (
    <div onClick={ onClick } className={`card ${className || ''}  `} style={ style || {}}>
      {
        title ? (
          <header
            onClick={async () => {
              if (loadContent && !loadedContent && !loadingContent) {
                setLoadingContent(true)
                setLoadedContent(await loadContent())
              }

              setShowContent(!showContent)
            }}
          >
            {title}
            <Icon name={showContent ? 'expand_more' : 'chevron-right'}/>
          </header>
        ) : null
      }

      {
        showContent ? (
          <div
            className={`card-body ${bodyClassName || ''} `}
            style={ Object.assign(
              noBorder ? { borderTopWidth: 0 } : {},
              bodyStyle || {}
            ) }
          >
            { loadedContent || children }
          </div>
        ) : null
      }
    </div>
  )
}

export default Card