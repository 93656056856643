import { useState, useEffect } from "react";
import { topicService } from "../services/topicService";

const useTopicState = (user, courseId, id, getResponseData) => {
  const [topic, setTopic] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadTopic = async () => {
      if (loading)
        return

      setTopic(null)
      setLoading(true)
      try {
        const topicRes = await topicService.common.getTopic(user, courseId, id)
        const data = getResponseData(topicRes)
        if (data)
          setTopic(data)

        setLoading(false)
      } catch (e) {
        console.log('TopicState: error get Topic', e)
        setLoading(false)
        setTopic(null)
        setError(error)
      }

    }

    if (courseId && id)
      loadTopic()

  }, [id])

  const toggleProgress = async () => {
    setProgress(null)
    const progressRes = await topicService.common.toggleTopicProgress(user, courseId, id)
    const data = getResponseData(progressRes)
    if (data) {
      const { todo, done } = data
      setProgress({ todo, done })
    }
  }

  return [topic, loading, error, progress, toggleProgress]
};

export default useTopicState;
