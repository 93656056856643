import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import useUserState from '../hooks/useUserState'
import SwapTopicEnding from './SwapTopicEnding'
import Icon from './Icon'
import HeaderBreadcrumbItemDropdown from './HeaderBreadcrumbItemDropdown'
import useCourseState from "../hooks/useCourseState";
import helpers from "../AppHelpers";
import { topicService } from '../services/topicService';

const HeaderBreadcrumbItemDropdownOption = ({ topic, isMobile, openPath, parentTopic }) => {
  const { courseId, id } = useParams()
  const [course] = useCourseState(courseId);
  const [topicChildren, setTopicChildren] = useState(topic.children || null)
  const [showChildren, setShowChildren] = useState(!!topic.children && topic.id !== id)
  const [user, , , , , , goToLogin] = useUserState();

  useEffect(() => {
    if (openPath?.indexOf(topic.id) >= 0)
      loadChildren()
  })


  const loadChildren = async () => {
    if (showChildren) {
      setShowChildren(false)
      return
    }

    setShowChildren(true)

    if (!topicChildren) {
      const topicChildrenRes = await topicService.common.getTopicChildren(user, courseId, topic.id)
      if (topicChildrenRes.status !== 200) {
        if (topicChildrenRes.status === 401) {
          goToLogin()
          return
        }

        alert('Erro ao carregar tópicos filhos no breadcrumb')
        return
      }
      setTopicChildren(topicChildrenRes.data)
    }
  }

  const colors = colorsFor(topic)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.5rem'
        }}
      >
        <Link
          to={ helpers.path.student.topic(courseId, topic.id) }
          className='link-breadcrumb-item'
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '0.5rem',
            margin: '0.5rem 0'
          }}
        >
          <div
            style={{
              color: colors.label,
              fontSize: '0.8em',
              paddingBottom: '0.1rem'
            }}
          >
            { topic.isSubject ? 'Disciplina ' : topic.hierarchySize === 1 ? 'Módulo ' : 'Tópico ' }
            { topic.numberPrefix && `${ topic.numberPrefix }.` }
            { topic.number }
          </div>
          <div style={{
            color: topic.id === id || parentTopic.id === topic.id ? '#bdf100 !important' : colors.name
          }}>
            { topic.name }
          </div>
        </Link>

        {
          course?.config()?.useProgress && course?.topic?.progress?.todo === 1 && (
            <SwapTopicEnding
              hideName
              progress={ topic?.progress }
              topic={ topic }
            />
          )
        }

        {
          topic && !topic.isLeaf && (
            <Icon
              onClick={ (e) => {
                e.stopPropagation()
                loadChildren()
              }}
              name={ showChildren ? 'expand_more' : 'chevron-right' }
              className='link-breadcrumb-item'
              style={{ padding: '0.5rem' }}
            />
          )
        }
      </div>

      {
        showChildren && (
          <HeaderBreadcrumbItemDropdown
            topic={ topic }
            topics={ topicChildren }
            openPath={ openPath }
            style={{
              position: 'initial',
              top: 'auto',
              left: 'auto',
              backgroundColor: colors.background,
              padding: '0rem'
            }}
          />
        )
      }
    </div>
  )
}

const colorsFor = (topic) => {
  const n = topic?.hierarchySize || 1
  const bg = `#${ (n + 3).toString(16) }${ (n + 3).toString(16) }${ (n + 3).toString(16) }`
  const label = `#${ (n + 8).toString(16) }${ (n + 8).toString(16) }${ (n + 8).toString(16) }`
  return {
    background: bg,
    label: label,
    name: '#FFF'
  }
}


export default HeaderBreadcrumbItemDropdownOption
