import { HOST, baseApiService } from "./baseApiService";

const courseService = {
  host: HOST,
  paths: {
    coursesPath: () => `/courses`,
    coursePath: (courseId) => `/courses/${courseId}`,
    getCoursesByCategoryPath: () => {
      return `/courses-by-category`
    },
    admin: {
      coursesPath: () => (
        `/admin/courses`
      ),
      coursePath: (courseId) => (
        `/admin/courses/${courseId}`
      ),
    },
  },
  
  common: {
    getCourses: async (user) => {
      const path = courseService.paths.coursesPath();
      return await baseApiService.get(user, path);
    },
    
    getCourse: async (user, courseId) => {
      const path = courseService.paths.coursePath(courseId);
      return await baseApiService.get(user, path);
    },

    getCoursesByCategory: async (user) => {
      return await baseApiService.get(
        user,
        courseService.paths.getCoursesByCategoryPath()
      )
    },
  },

  admin: {
    index: async (user) => {
      return await baseApiService.get(user, courseService.paths.admin.coursesPath());
    },
    show: async (user, courseId) => {
      return await baseApiService.get(
        user,
        courseService.paths.admin.coursePath(courseId)
      );
    },
    update: async (user, courseId, dataToUpdate) => {
      return await baseApiService.patch(
        user,
        courseService.paths.admin.coursePath(courseId),
        dataToUpdate
      );
    },
    create: async (user, data) => {
      return await baseApiService.post(
        user,
        courseService.paths.admin.coursesPath(),
        data
      );
    }
},
};

export {
  courseService
}
