import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import AppHelpers from '../../AppHelpers'
import { assessmentService } from "../../services/assessmentService";

const letterForIndex = (index) => (
  ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'][index]
)

const AssessmentAttempt = ({ user, assessment, getResponseData }) => {
  const { courseId, assessmentId, attemptId } = useParams()
  const navigate = useNavigate()

  const [attempt, setAttempt] = useState(null)
  const [answerByQuestionId, setAnswerByQuestionId] = useState(null)
  const [remainingSecs, setRemainingSecs] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    if (assessment) {
      const attempts = assessment?.attempts || []
      const att = attemptId ? (
        attempts.find(att => att.id === attemptId)
      ) : (
        attempts.find(att => !att.endDate)
      )

      const entries = att?.answers?.map(ans => [ans.questionId, ans.answerItemId]) || []
      setAnswerByQuestionId(Object.fromEntries(entries))
      setAttempt(att || null)

      if (att?.startDate) {
        const startDate = new Date(att.startDate)
        const finalDate = new Date(startDate.getTime() + durationInMinutes * 60 * 1000)
        const endDate = att.endDate ? new Date(att.endDate) : new Date()
        const secs = Math.ceil((finalDate - endDate) / 1000)
        setRemainingSecs(secs)
      }
    }

  }, [assessment, attemptId])

  const status = () => {
    if (!attempt?.startDate)
      return 'não iniciada'
    else if (!attempt?.endDate)
      return 'em andamento'
    else
      return 'finalizada'
  }

  const beep = () => {
    const audio = new Audio('data:audio/wav;base64,UklGRl9vT19XQVZFZm10IBAAAAABAAEAQB8AAEAfAAABAAgAZGF0YU'+Array(1e3).join(123))
    audio.play()

    // const audioCtx = new AudioContext()
    // const oscillator = audioCtx.createOscillator()
    // oscillator.connect(audioCtx.destination)
    // setTimeout(() => oscillator.start(0), 600)
    // setTimeout(() => oscillator.stop(0), 1000)
  }

  const formatDuration = (durationInSecs) => {
    const hours = Math.floor(durationInSecs / 3600)
    const minutes = Math.floor(durationInSecs / 60) - hours * 60
    const seconds = durationInSecs % 60
    return `${ hours }:${ minutes < 10 ? '0' : '' }${ minutes }:${ seconds < 10 ? '0' : '' }${ seconds }`
  }

  const number = attempt?.number || ((assessment?.attempts?.length || 0) + 1)
  const allowedTries = assessment.allowedTries || 3
  const durationInMinutes = assessment.durationInMinutes || 120
  const finishedTries = assessment?.attempts?.filter(att => att.endDate).length || 0
  const noMoreAttempts = !attemptId && finishedTries >= allowedTries

  const now = new Date()
  const start = new Date(assessment.startDate)
  const end = new Date(assessment.endDate)
  let cannotResolveMsg = null
  if (now < start)
    cannotResolveMsg = `Esta avaliação ficará disponível para realização em ${ AppHelpers.date.format(start) }`
  else if (now > end && attempt?.endDate)
    cannotResolveMsg = `O período de realização desta avaliação está finalizado desde ${ AppHelpers.date.format(end) }`

  if (cannotResolveMsg)
    return (
      <div> 
        <h1 style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 0.5rem'
        }}>
          {cannotResolveMsg}
        </h1>
      </div>
    )
  if (remainingSecs === (assessment.durationInMinutes * 60 / 2) || remainingSecs === 600)
    beep()

  return (
    <div> 
      <h1 style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0.5rem'
      }}>
        <div style={{ flex: 2 }}>
          {
            noMoreAttempts ? (
              <div>
                Avaliação já realizada
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                <div>
                  Tentativa { number }
                  <div className='small-text light-text' style={{ marginLeft: '0.35rem' }}>
                    { status() }
                  </div>
                </div>

                <table style={{ fontSize: '1rem' }}>
                  <tbody>
                    <tr>
                      <th>iniciada em</th>
                      <td>{ AppHelpers.date.format(attempt?.startDate) || '--/--/---- --:--' }</td>
                    </tr>
                    <tr>
                      <th>tempo usado</th>
                      <td>
                        { remainingSecs === null ? '--:--' : formatDuration(durationInMinutes * 60 - remainingSecs) } 
                      </td>
                    </tr>
                    <tr>
                      <th>finalizada em</th>
                      <td>{ AppHelpers.date.format(attempt?.endDate) || '--/--/---- --:--' }</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
        </div>

        <div style={{ flex: 1 }}>
          {
            !noMoreAttempts && (
              <div style={{ fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem' }}>
                {
                  !attempt?.endDate && (
                    <CountdownCircleTimer
                      key={ `timer-${ attempt?.id }` }
                      duration={ durationInMinutes * 60 }
                      initialRemainingTime={ remainingSecs }
                      isPlaying={ attempt?.startDate && !attempt?.endDate }
                      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                      colorsTime={[10, 6, 3, 0]}
                      size={ 150 }
                      onUpdate={ (remainingTime) => setRemainingSecs(remainingTime) }
                      onComplete={ () => console.log('acabou') }
                    >
                      {
                        ({ remainingTime }) => {
                          if (remainingTime === 0) {
                            return (
                              <div style={{ color: 'darkRed !important', textAlign: 'center' }}>
                                Tempo<br/>esgotado!
                              </div>
                            )
                          }

                          return (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
                              <div className="small-text light-text" style={{ fontWeight: 'normal' }}>
                                Tempo<br/>restante
                              </div>
                              <div className="value">
                                { formatDuration(remainingTime) }
                              </div>
                            </div>
                          )
                        }
                      }
                    </CountdownCircleTimer>
                  )
                }

                <div style={{ display: 'inline-block', width: '10rem' }}>
                  {
                    !attempt?.startDate ? (
                      <Button
                        disabled={ loading }
                        title={ `começar a resolver a prova (tentativa ${ number })` }
                        onClick={async (e) => {
                          setLoading(true)
                          const attemptRes = await assessmentService.common.assessmentAttempts.startNew(
                            user,
                            courseId,
                            assessmentId
                          )
                          const data = getResponseData(attemptRes)
                          setAttempt(data || null)
                          setLoading(false)
                        }}
                        leftIcon='play'
                        style={{ fontSize: '1rem', fontWeight: 'normal'  }}
                      >
                        {
                          loading ? 'Aguarde...' : (
                            <div>
                              <div>Iniciar</div>
                              <div style={{ marginTop: '0.15rem' }}>avaliação</div>
                            </div>
                          )
                        }
                      </Button>
                    ) : !attempt?.endDate ? (
                      <Button
                        title={ `finalizar a tentativa ${ number }` }
                        onClick={async (e) => {
                          setLoading(true)
                          const attemptRes = await assessmentService.common.assessmentAttempts.finish(
                            user,
                            courseId,
                            assessmentId,
                            attempt?.id
                          )
                          const data = getResponseData(attemptRes)
                          setAttempt(data || null)
                          setLoading(false)
                        }}
                        leftIcon='stop'
                        style={{
                          fontWeight: 'normal',
                          backgroundColor: 'darkred'
                        }}
                      >
                        { loading ? 'Aguarde...' : 'Finalizar avaliação' }
                      </Button>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', color: 'darkred !important'  }}>
                        Tentativa { number } finalizada.
                        <Button
                          title={ `Tentativa ${ number } está finalizada` }
                          onClick={async (e) => {
                            navigate(-1)
                          }}
                          leftIcon='chevron-left'
                          style={{
                            fontWeight: 'normal',
                          }}
                        >
                          Voltar
                        </Button>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </h1>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          borderTop: 'solid 2px #888',
          paddingTop: '1rem',
        }}
      >
        {
          noMoreAttempts ? (
            <div>
              <p>
                Você já realizou a{ allowedTries != 1 && 's' } { allowedTries } tentativas possíveis para essa avaliação
              </p>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0rem' }}>
                {
                  assessment.attempts.map(att => (
                    <Link
                      key={`menu-option-${ att.id }`}
                      to={ `tentativas/${ att.id }` }
                      className='link'
                      style={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                        padding: '0.5rem',
                        margin: '0.5rem 0'
                      }}
                    >
                      Tentativa { att.number }
                    </Link>
                  ))
                }
              </div>
            </div>
          ) : !attempt ? (
            <div>
              <p>
                Existem { assessment?.questions.length } questões nesta prova.
              </p>
              <p>
                Clique em <b>"Iniciar a avaliação"</b> para vê-las.
              </p>
            </div>
          ) : (
            attempt.startDate && assessment.questions?.map((question, i) => (
              <div
                key={`questao-${ question.id }`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                  width: '100%'
                }}
              >
                {
                  attempt.endDate && (
                    <div>
                      Esta resolução está finalizada!
                    </div>
                  )
                }
                <div className='small-text' style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                  <b>{ question.suggestedTopicName }</b>
                  <b>{ question.origin } { question.year }</b>
                </div>
                <div
                  key={`questao-${ question.id }`}
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    border: 'solid 1px #dadada',
                    padding: '2rem',
                    borderRadius: '0.1rem',
                    backgroundColor: '#f7f7f7',
                  }}
                >
                  <div
                    style={{
                      marginTop: '-1rem',
                      fontSize: '2rem',
                      fontWeight: 'bold'
                    }}
                  >
                    { i + 1 }
                    <span className='small-text'>.</span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%'
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <div
                        className='topic-content'
                        dangerouslySetInnerHTML={{ __html: question.statement }}
                      />
                      <div className='small-text light-text'>
                        <b>{ question.points / 10 }</b>
                        &nbsp;
                        <span className='small-text'>ponto{ question.points != 10 && 's' }</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '1rem'
                      }}
                    >
                      {
                        question.items?.map((item, i) => {
                          const isAnswer = !!(answerByQuestionId && answerByQuestionId[question.id] === item.id)
                          return (
                            <div
                              key={`questao-${ question.id }-item-${ item.id }`}
                              className={ !attempt?.endDate && 'apply-light-gray-hover' || '' }
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                padding: '0.75rem 0.75rem',
                                marginLeft: '-1.50rem',
                                borderRadius: '0.25rem',
                                position: 'relative',
                                width: 'calc(100% - 1.5rem)',
                              }}
                              onClick={ async () => {
                                if (attempt?.endDate || remainingSecs <= 0)
                                  return

                                setLoading(true)
                                const attemptRes = await assessmentService.common.assessmentAttempts.answerQuestion(
                                  user,
                                  courseId,
                                  assessmentId,
                                  attempt?.id,
                                  question.id,
                                  item.id
                                )
                                const data = getResponseData(attemptRes)
                                setLoading(false)

                                setAnswerByQuestionId(state => {
                                  const nextState = {}
                                  if (data.answered)
                                    nextState[question.id] = item.id
                                  else if (data.cleared)
                                    nextState[question.id] = null

                                  return Object.assign(
                                    {},
                                    state,
                                    nextState
                                  )
                                })
                              }}
                            >
                              {
                                false && answerByQuestionId && item.id === answerByQuestionId[question.id] && (
                                  <Icon name='done'
                                    style={{
                                      color: 'green !important',
                                      position: 'absolute',
                                      left: '-2rem',
                                      fontSize: '1.5rem'
                                    }}
                                  />
                                )
                              }
                              <div
                                style={{
                                  backgroundColor: isAnswer ? '#004777' : 'transparent',
                                  color: isAnswer ? 'white !important' : '#333 !important',
                                  width: '2rem',
                                  height: '2rem',
                                  minWidth: '2rem',
                                  minHeight: '2rem',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontWeight: 'bold'
                                }}
                              >
                                { letterForIndex(i) }
                              </div>
                              <div
                                className='topic-content'
                                dangerouslySetInnerHTML={{ __html: item.statement }}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          ))
        }
      </div>
    </div>
  )

}

export default AssessmentAttempt