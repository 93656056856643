import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../../components/Header'
import { courseService } from '../../../services/courseService';
import CreateOrUpdate from './CreateOrUpdate'

const AdminCoursesEdit = ({ loadUser, getResponseData }) => {
  const user = loadUser()
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [course, setCourse] = useState(null)
  const [savingCourse, setSavingCourse] = useState(false)

  useEffect(() => {
    const load = async () => {
      if (user) {
        const courseRes = await courseService.admin.show(user, courseId)
        const data = getResponseData(courseRes)
        if (data)
          setCourse(data)
      }
    }

    load()
  }, [])

  const updateCourse = async (courseAttrs) => {
    setSavingCourse(true)
    const createRes = await courseService.admin.update(user, courseId, courseAttrs)
    const data = getResponseData(createRes)
    setSavingCourse(false)
  }
  
  return [
    <Header
      key='admin-courses-edit-header-key'
      admin="Cursos"
      user={user}
    />,
    <div
      className='app-body'
      key='admin-courses-edit-body-key'
    >
      <CreateOrUpdate
        user={ user }
        course={ course }
        onFinish={ courseAttrs => {
          updateCourse(courseAttrs)
          navigate('/admin/cursos')
        }}
        loading={ savingCourse }
        getResponseData={ getResponseData }
      />
    </div>
  ]
}

export default AdminCoursesEdit
