import { useState} from 'react';
import AppHelpers from '../AppHelpers'
import Card from './Card'
import EditEntry from '../pages/admin/calendarEntries/EditEntry'

const AdminCalendarEntries = ({
  user,
  getResponseData,
  startDate,
  endDate,
  entries,
  courses,
  course,
  courseById,
  subjectById,
  onUpdateEntry
}) => {
  const [editingEntry, setEditingEntry] = useState(false)

  const entriesByDay = {}
  entries.forEach(entry => {
    const dayNum = new Date(entry.startDate).getDate()
    if (entriesByDay[dayNum])
      entriesByDay[dayNum].push(entry)
    else
      entriesByDay[dayNum] = [entry]
  })

  const days = []
  let day = startDate
  while (day <= endDate) {
    const clonedDay = new Date(day.getTime());
    const dayNum = clonedDay.getDate()

    days.push({
      date: day,
      entries: entriesByDay[dayNum]
    })

    clonedDay.setDate(dayNum + 1)
    day = clonedDay
  }

  let offset = days[0].date.getDay()
  while (offset > 0) {
    days.unshift(null)
    offset -= 1
  }

  offset = days[days.length -1].date.getDay()
  while (offset < 6) {
    days.push(null)
    offset += 1
  }

  return (
    <Card>
      <EditEntry
        user={user}
        getResponseData={getResponseData}
        entry={editingEntry}
        courses={courses}
        courseById={courseById}
        subjectById={subjectById}
        onClose={() => setEditingEntry(null)}
        onUpdate={updatedEntry => {
          setEditingEntry(updatedEntry)
          onUpdateEntry(updatedEntry)
        }}
      />
      <table style={{width: '100%', marginTop: '1.5rem'}}>
        <thead>
          <tr>
            <th style={styles.dayOfWeek.th}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                Dom<span className='hide-small-screen'>ingo</span>
              </div>
            </th>
            <th style={styles.dayOfWeek.th}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                Seg<span className='hide-small-screen'>unda</span>
              </div>
            </th>
            <th style={styles.dayOfWeek.th}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                Ter<span className='hide-small-screen'>ça</span>
              </div>
            </th>
            <th style={styles.dayOfWeek.th}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                Qua<span className='hide-small-screen'>rta</span>
              </div>
            </th>
            <th style={styles.dayOfWeek.th}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                Qui<span className='hide-small-screen'>nta</span>
              </div>
            </th>
            <th style={styles.dayOfWeek.th}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                Sex<span className='hide-small-screen'>ta</span>
              </div>
            </th>
            <th style={styles.dayOfWeek.th}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                Sáb<span className='hide-small-screen'>ado</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            AppHelpers.arr(days).inGroupsOf(7).map(daysInWeek => {
              return (
                <tr key={`calendar-row-${daysInWeek[0]?.date?.getDate() || 1}`}>
                  {
                    daysInWeek.map((day, i) => (
                      <td
                        key={`calendar-cell-${day?.date?.getDate() || `null-day-${i}`}`}
                        style={{minHeight: '5rem', verticalAlign: 'top'}}
                        onDoubleClick={() => setEditingEntry({ startDate: day?.date, courseId: course.id })}
                      >
                        <div style={{
                          backgroundColor: day ? 'transparent' : '#EAEAEA',
                          height: '100%',
                          minHeight: '5rem',
                          display: 'flex',
                          gap: '1rem'
                        }}>
                          <div>
                            {day?.date?.getDate()}
                          </div>

                          <div style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '0.5rem'
                          }}>
                            {
                              day?.entries?.map(entry => (
                                <div
                                  key={ `entry-${ entry.id }` }
                                  style={styles.calendar.entry}
                                  className='small-text apply-hover'
                                  onClick={() => setEditingEntry(entry)}
                                >
                                  <div>
                                    <b>
                                      {
                                        new Date(entry.startDate).toLocaleString(
                                          "pt-BR",
                                          {
                                            hour: '2-digit',
                                            minute:'2-digit'
                                          }
                                        )
                                      }
                                    </b>
                                  </div>
                                  <div>
                                    <b>
                                      {entry.topic?.name || 'sem disciplina'}
                                    </b>
                                  </div>
                                  <div>
                                    <span className='light-text'>{entry.name}</span>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </td>
                    ))
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </Card>
  )
}

const styles = {
  entryCell: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },

  dayOfWeek: {
    th: {
      textAlign: 'center',
      width: '14.285%'
    }
  },
  calendar: {
    entry: {
      color: '#444 !important' ,
      border: 'solid 1px #206dd0',
      padding: '0.15rem 0.25rem',
      borderRadius: '0.1rem',
      width: '100%',
      cursor: 'pointer'
    }
  }
}

export default AdminCalendarEntries
