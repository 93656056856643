import Icon from '../Icon';
import { AlertList, AlertBox, AlertText } from './Style'
import { useCallback, useEffect } from 'react';

const ToastAlert = ({ toastList, setList }) => {

  const deleteToast = useCallback(id => {
    const toastListItem = toastList.filter(e => e.id !== id);
    setList(toastListItem);
  }, [toastList, setList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length) {
        deleteToast(toastList[0].id);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    }
  }, [toastList, deleteToast]);

  return (
    
    <AlertList>
      {
        toastList.map((toast, i) => (
          <AlertBox
            key={i}
            background={toast.backgroundColor}
            onClick={() => deleteToast(toast.id)}
            className="fade-in"
          >
            <Icon
              name={toast.icon}
              style={{color: '#ffffff !important'}}
            />
            <AlertText>{toast?.description}</AlertText>
          </AlertBox>
        ))
      }
    </AlertList>
  )
}

export default ToastAlert
