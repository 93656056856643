import { HOST, baseApiService } from "./baseApiService"

const calendarService = {
  host: HOST,
  paths: {
    getCalendarEntryPath: (courseId, calendarEntryId) => {
      return `/courses/${courseId}/calendar-entries/${calendarEntryId}`
    },
    calendarEntriesPath: (courseId, topicId) => {
      const suffix = topicId ? `topics/${topicId}/calendar-entries` : 'calendar-entries'
      return `/courses/${courseId}/${suffix}`
    },
    calendarEntriesByCoursePath: (courseId, topicId) => {
      return `/courses/${courseId}/calendar-entries`
    },
    calendarEntriesByTopicPath: (courseId, topicId) => {
      return `/courses/${courseId}/topics/${topicId}/calendar-entries`
    },
    admin: {
      calendarEntriesPath: () => (
        '/admin/calendar-entries'
      ),
      updateEntryPath: (entryId) => (
        `/admin/calendar-entries/${entryId}/update`
      ),
    }
  },

  common: {
    getCalendarEntry: async (user, courseId, calendarEntryId) => {
      const path = calendarService.paths.getCalendarEntryPath(courseId, calendarEntryId)
      return await baseApiService.get(user, path)
    },
  
    getCalendarEntries: async (user, courseId, topicId, query) => {
      const path = calendarService.paths.calendarEntriesPath(courseId, topicId)
      return await baseApiService.get(user, path, query)
    },
  
    getCalendarEntriesByTopic: async (user, courseId, topicId) => {
      const path = calendarService.paths.calendarEntriesByTopicPath(courseId, topicId)
      return await baseApiService.get(user, path)
    },
  },

  admin: {
    index: async (user, params) => {
      return await baseApiService.get(
        user,
        calendarService.paths.admin.calendarEntriesPath(),
        params || {}
      )
    },
    update: async (user, entryId, data) => {
      return await baseApiService.patch(
        user,
        calendarService.paths.admin.updateEntryPath(entryId),
        data
      )
    }
  }
}

export {
  calendarService
}