import { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import TableForObjects from '../../../components/TableForObjects'
import Input from '../../../components/Input'
import Icon from '../../../components/Icon'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import { topicService } from '../../../services/topicService';

const CourseTopics = ({ user, getResponseData, course }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(true)
  const [parent, setParent] = useState(null)
  const [editingContent, setEditingContent] = useState(null)
  const [savingContent, setSavingContent] = useState(null)
  const [savingSubjectStatus, setSavingSubjectStatus] = useState(null)
  const [newContent, setNewContent] = useState(null)
  const [topics, setTopics] = useState(null)
  const [showTopicCreation, setShowTopicCreation] = useState(false)
  const [topicNameToCreate, setTopicNameToCreate] = useState('')
  const [creatingTopic, setCreatingTopic] = useState(false)
  const [deletingTopicId, setDeletingTopicId] = useState(null)

  useEffect(() => {
    const load = async () => {
      if (user) {
        setLoading(true)

        const courseId = course?.id || null
        const parentId = params.get('parentId') || null
        if (parentId) {
          const parentRes = await topicService.admin.show(user, {
            topicId: parentId,
            courseId
          })

          const parentData = getResponseData(parentRes)
          if (parentData) {
            setParent(parentData)
            setTopics(parentData.children.map(child => (
              Object.assign(
                child,
                {course: parentData.course}
              )
            )))
          }
        } else {
          const topicsRes = await topicService.admin.index(user, { courseId })
          const topicsData = getResponseData(topicsRes)
          if (topicsData) {
            setParent(null)
            setTopics(topicsData)
          }
        }

        setLoading(false)
      }
    }

    load()
  }, [location])

  const updateSubjectStatus = async (topicId, isSubject) => {
    setSavingSubjectStatus(topicId)

    const updateRes = await topicService.admin.updateSubjectStatus(
      user,
      { topicId, isSubject }
    )

    const topicData = getResponseData(updateRes)
    if (topicData)
      setTopics(topics.map(t => t.id === topicId ? topicData : t))

    setSavingSubjectStatus(null)
  }

  const updateParent = async () => {
    // try {
      setEditingContent(false)
      if (newContent === null)
        return

      setSavingContent(true)
      const updateRes = await topicService.admin.update(
        user,
        parent.id,
        {
          content: newContent || null
        }
      )

      const newParent = getResponseData(
        updateRes,
        "Erro ao atualizar tópico"
      )

      setSavingContent(false)

      if (newParent) {
        setParent(newParent)
        setNewContent(null)
      }
    // } catch (e) {
    //   alert('Erro ao atualizar tópico', e.message)
    // }
  }

  const createTopic = async () => {
    try {
      if (!topicNameToCreate || topicNameToCreate.length === 0)
        return

      setCreatingTopic(true)
      const createRes = await topicService.admin.create(
        user,
        parent?.id,
        { name: topicNameToCreate, courseId: course.id }
        )
      const newTopic = getResponseData(createRes, "Erro ao criar tópico")
      setSavingContent(false)

      if (newTopic) {
        setTopics([newTopic].concat(topics || []))
        setTopicNameToCreate('')
      }
    } catch (e) {
      console.log(e)
      alert('Erro ao criar tópico', e.message)
    }
  }

  const deleteTopic = async (topicId) => {
    try {
      setDeletingTopicId(topicId)
      const deleteRes = await topicService.admin.delete(user, topicId)
      getResponseData(deleteRes, "Erro ao apagar tópico")
      setTopics([].concat(topics?.filter(t => t.id !== topicId) || []))
      setDeletingTopicId(null)
    } catch (e) {
      console.log(e)
      alert('Erro ao deletar tópico', e.message)
    }
  }

  return loading ? <Loading key="loading-admin-topics" type='elements-with-cards'/> : (
    <div
      key='admin-topics-index-body-key'
      style={{ width: '100%', maxWidth: 1224, margin: '1rem 0.5rem 0.5rem 0.5rem' }}
    >
      <Card title="Tópicos">
        <div style={{display: 'flex', marginBottom: '1rem', gap: '0.75rem'}}>
          <Link
            className='apply-box-shadow'
            style={{padding: '0.5rem', borderRadius: '0.25rem', cursor: 'pointer'}}
            to={ `?parentId=` }
          >
            /
          </Link>
          {
            parent?.hierarchy.map(child => {
              return (
                <Link
                  key={`breadcumb-admin-topic-${child.id}`}
                  className='apply-box-shadow'
                  style={{padding: '0.5rem', borderRadius: '0.25rem', cursor: 'pointer'}}
                  to={ `?parentId=${ child.id }` }
                >
                  {child.name}
                </Link>
              )
            })
          }
        </div>

        {
          parent && (
            <div>
              <h3 style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                paddingBottom: '0.5rem'
              }}>
                Conteúdo

                {
                  editingContent ? (
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <div
                        onClick={(e) => {
                          setEditingContent(false)
                          updateParent()
                        }}
                        className='apply-box-shadow'
                        style={{
                          padding: '0.5rem',
                          cursor: 'pointer',
                          color: 'white !important',
                          backgroundColor: 'darkGreen',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem'
                        }}
                      >
                        <div className='small-text'>
                          salvar
                        </div>
                        <Icon name='done'/>
                      </div>

                      <div
                        onClick={(e) => {
                          setEditingContent(false)
                        }}
                        className='apply-box-shadow'
                        style={{
                          padding: '0.5rem',
                          cursor: 'pointer',
                          color: 'white !important',
                          backgroundColor: 'darkRed',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Icon name='close'/>
                      </div>
                    </div>
                  ) : (
                    <Button
                      title='Editar conteúdo'
                      style={{padding: '0.5rem'}}
                      onClick={async (e) => {
                        if (savingContent)
                          return

                        setEditingContent(true)
                      }}
                      leftIcon={ 'edit' }
                    />
                  )
                }
              </h3>
              <div style={{
                minHeight: '20rem',
                backgroundColor: '#FAFAFA',
                border: 'solid 1px #CCC',
                padding: '1rem'
              }}>
                {
                  editingContent ? (
                    <Input
                      textarea
                      onEnter={() => {
                        setEditingContent(false)
                        updateParent()
                      }}
                      onEsc={() => {
                        setEditingContent(false)
                      }}
                      autoFocus
                      value={newContent || parent?.content || ''}
                      onChange={ event => setNewContent(event.target.value) }
                      style={{
                        width: '100%',
                        minHeight: '20rem',
                        margin: '-1rem',
                        padding: '1rem'
                      }}
                    />
                  ) : (
                    savingContent ? <Loading message="salvando conteúdo"/> : (
                      parent.content ? (
                        <div
                          className='topic-content'
                          dangerouslySetInnerHTML={{ __html: parent.content }}
                        />
                      ) : (
                        <div className='small-text light-text'>
                          Sem conteúdo registrado.
                        </div>
                      )
                    )
                  )
                }
              </div>
            </div>
          )
        }

        <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {parent ? 'Filhos ' : 'Tópicos raízes '}
          ({topics?.length || 0})
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
            {
              showTopicCreation && (
                <Input
                  label='Nome do novo tópico'
                  onEnter={() => {
                    createTopic()
                    setShowTopicCreation(false)
                  }}
                  onEsc={() => {
                    setShowTopicCreation(false)
                  }}
                  autoFocus
                  value={ topicNameToCreate }
                  onChange={ event => setTopicNameToCreate(event.target.value) }
                  style={{
                  }}
                />
              )
            }
            <Button
              title='Incluir novo tópico'
              onClick={async (e) => {
                setShowTopicCreation(!showTopicCreation)
              }}
              style={ showTopicCreation ? { backgroundColor: 'darkred' } : {} }
              leftIcon={ showTopicCreation ? 'close' : 'add' }
            />
          </div>
        </h3>

        <TableForObjects
          objects={ topics }
          extraColumns={[
            {
              label: 'Nome',
              build: (topic) => (
                <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                  <Link
                    className='link'
                    to={`?parentId=${ topic.id }` }
                  >
                    {topic.name}
                  </Link>
                </div>
              )
            },
            {
              label: 'Nível na árvore',
              build: (topic) => (
                <div className='small-text light-text'>
                  {topic.hierarchySize}
                </div>
              )
            },
            {
              label: 'Disciplina?',
              build: (topic) => savingSubjectStatus === topic.id ? <Loading/> : (
                <div
                  className='small-text light-text'
                  style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                  onClick={ (ev) => updateSubjectStatus(topic.id, !topic.isSubject) }
                >
                  <div style={{ fontWeight: topic.isSubject ? 'normal' : 'bold' }}>não</div>
                  <Icon
                    name={ topic.isSubject ? 'toggle-on' : 'toggle-off' }
                    style={{
                      fontSize: '2rem',
                      color: topic.isSubject ? 'blue !important' : '#888 !important'
                    }}
                  />
                  <div style={{
                    fontWeight: !topic.isSubject ? 'normal' : 'bold'
                  }}>
                    sim
                  </div>
                </div>
              )
            },
            {
              label: '',
              style: { borderColor: 'transparent', width: '3rem' },
              build: (topic) => (
                  <Button
                    title='Apagar tópico'
                    style={{ backgroundColor: 'darkRed' }}
                    onClick={async (e) => {
                      if (topic.children && topic.children.length > 0) {
                        alert('Apenas tópicos sem filhos podem ser apagados')
                        return
                      }

                      deleteTopic(topic.id)
                    }}
                    leftIcon={ 'delete' }
                  />
              )
            },
          ]}
        />
      </Card>
    </div>
  )
}

export default CourseTopics