import Icon from './Icon'
import HeaderBreadcrumbItemDropdown from './HeaderBreadcrumbItemDropdown'
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { topicService } from '../services/topicService';
import useUserState from '../hooks/useUserState'

const HeaderBreadcrumbItem = ({ topic, className, isMobile, openPath }) => {
  const { courseId, id } = useParams()
  const [showMenu, setShowMenu] = useState(false)
  const [topics, setTopics] = useState(null)
  const [user] = useUserState();

  const toggleMenu = async (e, value) => {
    e.stopPropagation()
    setShowMenu(value)
    if (topics === null)
      if (topic.siblings) {
        setTopics(topic.siblings)
      } else {
        const topicSiblingsRes = await topicService.common.getTopicSiblings(user, courseId, topic.id)
        if (topicSiblingsRes.status !== 200) {
          alert('Erro ao carregar tópicos no breadcrumb')
          return
        }
        setTopics(topicSiblingsRes.data)
      }
  }

  return topic && (
    <section
      key={ `topic-${ topic?.id || 'loading' }` }
      className={ `${ isMobile ? 'hide-big-screen' : '' } ${ className || '' }` }
      style={Object.assign(
        {
          padding: '0.6rem',
          cursor: (topics && topics.length > 0) ? 'pointer' : 'default',
        },
        isMobile ? {} : {
          display: 'flex',
          flexDirection: 'column',
        }
      )}
      onClick={ (e) => toggleMenu(e, !showMenu) }
      onMouseOver={ (e) => toggleMenu(e, true) }
      onMouseOut={ (e) => toggleMenu(e, false) }
    >
      {
        isMobile ? (
          <div
            key='mobile-icon-children'
            style={{
              flex: 1,
              padding: `0.15rem ${ showMenu ? '8rem' : '0.15rem' } 0.15rem 0.15rem`,
            }}
          >
            <Icon
              name={ showMenu ? 'menu-open' : 'menu-open-right' }
            />
          </div>
        ) : (
          <div className='disable-children-mouseover-events'>
            {
              topic && (
                <div
                  style={{
                    fontSize: '0.7rem',
                    color: '#AAA !important',
                    marginBottom: '0.1rem',
                  }}
                >
                  { topic?.isSubject ? 'Disciplina ' : topic?.hierarchySize === 1 ? 'Módulo ' : 'Tópico ' }
                  { topic?.numberPrefix && `${ topic.numberPrefix }.` }
                  { topic?.number || '-' }
                </div>
              )
            }

            <div
              style={{
                color: topic?.id === id ? '#bdf100 !important' : '#DDD !important',
              }}
            >
              { topic?.name || (
                <div
                  key='dropdown-box-children'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    color: '#DDD !important',
                    borderRadius: '2px',
                    border: `solid 1px ${ showMenu ? 'transparent' : '#717171' }`,
                    padding: '0.5rem  0.5rem 0.5rem 1rem',
                    marginLeft: '0.5rem',
                    backgroundColor: '#393939'
                  }}
                >
                  <span style={{ color: '#CCC !important' }}>selecione...</span>
                  <Icon key='dropdown-icon-children' name='expand_more'/>
                </div>
              ) }

            </div>
          </div>
        )
      }

      <div
        style={{
          position: 'relative',
        }}
      >
        {
          showMenu && (
            <HeaderBreadcrumbItemDropdown
              topic={ topic }
              topics={ topics }
              isMobile={ isMobile }
              openPath={ openPath }
            />
          )
        }
      </div>

    </section>
  )
}

export default HeaderBreadcrumbItem
