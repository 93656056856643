import { HOST, baseApiService } from "./baseApiService";

const questionService = {
  host: HOST,
  paths: {
    admin: {
      questionsPath: () => (
        `/admin/questions`
      )
    }
  },
  
  admin: {
    questions: {
      index: async (user, params) => {
        return await baseApiService.get(
          user,
          questionService.paths.admin.questionsPath(),
          params || {}
        )
      }
    },
  }
}

export {
  questionService
}