import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { userService } from '../services/userService';

const BibliografyField = ({ user, topic }) => {
  const { courseId } = useParams();
  const [professorList, setProfessorList] = useState([])

  useEffect(() => {
    const load = async () => {
      const list = await userService.temporary.getProfessorsDataByTopic(user, courseId, topic);
      setProfessorList(list.data);
    }

    load();
  })

  const renderProfessor = () => {

    return professorList?.map((professor, index) => {

      const userInitials = professor?.name?.split(' ').slice(0,2).map(part => part[0]).join('');

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: 'auto',
            padding: '1rem'
          }}
          key={index}
        >
          <div style={{
            border: '2px solid rgb(0, 0, 0)',
            backgroundColor: '#444',
            width: '8rem',
            height: '8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '3rem',
            fontWeight: 'bold',
            letterSpacing: '0.1rem',
            borderRadius: '50%',
            overflow: 'hidden',
            color: 'black !important',
            alignSelf: 'center'
          }}>
            {
              professor?.photo ? (
                <img
                  src={professor.photo}
                  style={{
                    width: '100%',
                    height:'100%'
                  }}
                />
              ) : (
                {userInitials}
              )
            }
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              width: 'auto',
              padding: '0.5rem',
              wordWrap: 'break-word',
            }}
          >
            <p
              style={{
                marginTop: '0',
                fontSize: '1.5rem'
              }}
            >
              {professor.name}
            </p>
            <p
              style={{
                fontWeight: 'lighter',
                color: '#808080 !important',
                fontSize: '0.8rem',
                textOverflow: '',
                margin: '0'
              }}
            >
              {professor.bio}
            </p>

          </div>

        </div>
      )
    })

  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start'
      }}
    >
      {renderProfessor()}
    </div>
    )
}

export default BibliografyField
