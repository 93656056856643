const obj = {
  merge: (obj1, obj2, obj3, obj4, obj5) => {
    const result = {};
    const objs = [obj1, obj2, obj3, obj4, obj5]
    objs.forEach(obj => {
      if (obj)
        obj.each(obj, (key, val) => result[key] = val)
    })
    return result
  },

  each: (obj, func) => {
    Object.entries(obj).forEach(([key, val]) => func(key, val))
  }
}

const str = {
  withoutAccentsAndSpecialChars: str => (
    str
      .toLowerCase()
      .replace(/[ÁÃÂÀáãâà]/,'a')
      .replace(/[ÉÊÈéêè]/,'e')
      .replace(/[ÍÌÎíìî]/,'i')
      .replace(/[ÔÓÕÒòôõóÖö]/,'o')
      .replace(/[ÚÙÛúûùúùû]/,'u')
      .replace(/[Çç]/,'c')
      .replace(/[º]/,'o')
      .replace(/[ª]/,'a')
      .replace(/["']/,'')
      .replace(/[\-\/]/,' ')
      .replace(/\s+/,' ')
  ),
  isString: possibleStr => (
    typeof possibleStr === 'string' || possibleStr instanceof String
  )
}

const res = {
  getResponseData: ({response, goToLogin, navigate, errorMsg}) => {
    const {status} = response

    if (status === 200 || status === 304)
      return response.data

    if (status === 401) {
      goToLogin()
      return
    }

    if (status === 403) {
      navigate('/acesso-negado')
      return
    }

    // TODO: use toast alert
    alert(`[${status}] ${errorMsg}: ${response.statusText}`)
  }
}

const buildCourseOrTopicPath = (pathPart, courseId, topicId) => {
  const suffix = topicId ? `topicos/${topicId}/${pathPart}` : pathPart
  return `/cursos/${courseId}/${suffix}`
}

const path = {
  student: {
    home: '/',
    account: '/sua-conta',
    courses: '/cursos',
    course: (cId) => `/cursos/${cId}`,
    topic: (cId, tId, prevId) => `/cursos/${cId}/topicos/${tId}${prevId ? `?prevId=${prevId}` : ''}`,
    recordedClasses: (cId, tId) => buildCourseOrTopicPath('aulas-gravadas', cId, tId),
    supportMaterial: (cId, tId) => buildCourseOrTopicPath('material-de-apoio', cId, tId),
    calendar: (cId, tId) => buildCourseOrTopicPath('calendario', cId, tId),
    scores: (cId, tId) => buildCourseOrTopicPath('notas', cId, tId),
    assessments: (cId, tId) => buildCourseOrTopicPath('avaliacoes', cId, tId)
  },
  admin: {
    home: '/admin',
    courses: '/admin/cursos',
    users: '/admin/usuarios',
    files: '/admin/arquivos',
    calendar: '/admin/calendario',
    topics: {
      index: parentId => `/admin/topicos${ parentId ? `?parentId=${parentId}` : ''}`,
      show: topicId => `/admin/topicos/${topicId}`
    }
  }
}

const date = {
  format: (d) => {
    const dTemp = str.isString(d) ? new Date(d) : d
    return dTemp?.toLocaleString(
      "pt-BR",
      {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute:'2-digit'
      }
    ) || ''
  }
}

const arr = (array) => ({
  inGroupsOf: (n) => {
    const result = []

    array.forEach(element => {
      const last = result[result.length - 1]
      if (!last || last.length === n)
        result.push([element])
      else
        last.push(element)
    })

    return result
  }
})

export default {
  obj,
  str,
  res,
  path,
  arr,
  date
}
