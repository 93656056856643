import { useState } from 'react';
import Icon from './Icon';

const SelectLinks = ({ links, style, conditionToOpen }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div style={{
      position: 'relative'
    }}>
      <div
        onClick={(e) => {
          if (conditionToOpen)
            setMenuOpen(conditionToOpen())
          else
            setMenuOpen(false)
        }}
        className='apply-box-shadow'
        style={{
          padding: '0.25rem 0.5rem',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Icon name='menu'/>
      </div>
      {
        menuOpen && (
          <div
            className='apply-box-shadow'
            style={{
              position: 'absolute',
              top: '-0.1rem',
              right: '-0.2rem',
              zIndex: 9,
              border: 'solid 2px #666',
              cursor: 'pointer',
              backgroundColor: 'white'
            }}
          >
            <div
              style={{padding: '0.5rem'}}
              onMouseOut={() => setMenuOpen(false)}
            >
              <div
                onMouseOut={(event) => event.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setMenuOpen(false)
                }}
                style={{
                  padding: '0.5rem 4rem 0.5rem 1rem',
                  backgroundColor: '#999',
                  color: 'white !important',
                  fontSize: '0.8em',
                  fontWeight: 'bold'
                }}
              >
                Opções
              </div>

              {
                links.map(link => (
                  <div
                    onMouseOut={(event) => event.stopPropagation()}
                    className='apply-hover'
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      link.onClick(e)
                      setMenuOpen(false)
                    }}
                    style={{
                      padding: '0.5rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      borderBottom: 'solid 1px #EEE'
                    }}
                  >
                    <Icon
                      style={{fontSize: '1rem', color: link.icon?.color || '#777 !important'}}
                      name={link.icon?.name || link.icon}
                    />
                    <span>{link.name}</span>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default SelectLinks
